<template>
  <v-app id="app_create_user_role">

    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  <v-container fluid>

    <v-row>
      <v-col class="mt-3" cols="7">
        <p class="custom_page_main_title">CREATE USER ROLES</p>
        <p class="custom_page_main_sub_title" style="opacity: 0.6">These created user roles help you grant permissions for functions.</p>
      </v-col>
      <v-col class="px-4 mt-7" cols="5">
        <v-row class="custom_tool_bar_style">
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>


    <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
    <template v-slot:title>
      <p class="custom_page_component_main_title pa-3">Create new user roles</p>
    </template>

    <v-divider thickness="2"></v-divider>
    
    <v-card-text>

      <v-form ref="form">

      </v-form>

      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">

          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-alert color="#F0F0F0"  prominent density="default" border="border-lg"
                text="These user roles help you grant permissions and assign related functions to users in the system. You can not create mobile user">
              <template v-slot:prepend>
                <v-icon color="orange" size="x-large">mdi-alert</v-icon>
              </template>
            </v-alert>
          </v-col>


     
        
              <v-text-field
                  v-model="selectedItem.user_role" label="Role type *" placeholder="Type User Role Here"
                  variant="outlined" @input="validateUserRole" density="comfortable"  color="#BB0000"
                  :rules="[v => !!v || 'Role is required', v => /^[a-zA-Z\s]+$/.test(v) || 'Only letters and spaces are allowed' ]"
              ></v-text-field>

            <v-textarea
                v-model="selectedItem.user_role_description" class="mt-2" label="Description *"
                placeholder="Type user description here, Word Count 300" required rows="6" variant="outlined"
                color="#BB0000"
                :rules="[v => !!v || 'Description is required', v => /^[a-zA-Z\s]+$/.test(v) || 'Only letters and spaces are allowed' ]"
            ></v-textarea>

            <!-- <v-row>
                  

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <p class="custom_page_main_sub_title">
                        <strong>
                            SELECT USER CATEGORY
                        </strong>
                      </p>
                    <v-divider class="mt-4"></v-divider>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox v-model="selectedUser" :value="'Web User'" color="#05146F"
                                hide-details label="WEB USER" rounded></v-checkbox>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox v-model="selectedUser" :value="'Mobile User'"
                                color="#05146F" hide-details label="MOBILE USER"
                    ></v-checkbox>
                  </v-col>
                </v-row> -->

                <!-- ----------------------------------- -->

                <!-- <v-row v-if="this.selectedUser == 'Mobile User'">
                  
                  <v-col cols="6">
                    <v-checkbox v-model="mobileUserType" :value="'area manager'" color="#05146F"
                                hide-details label="Area Manager" rounded></v-checkbox>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox v-model="mobileUserType" :value="'officer'"
                                color="#05146F" hide-details label="Officer"
                    ></v-checkbox>
                  </v-col>
                </v-row> -->

        </v-col>


        <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="this.selectedUser != 'Mobile User'">
          <h5 class="card-two-title px-3 mt-3 mb-4">Setup Permissions</h5>
         
            <v-row v-for="(item, index) in permissionsItems" :key="item.id" class="align-center no-gutters">
              <v-row class="ml-3">
                <v-col cols="auto" class="d-flex margin-up-down-two">
                  <v-switch
                      v-model="item.isOn"
                      :color="item.isOn ? 'red' : 'grey'"
                      class="mr-1"
                      @change="handleSwitchChange(item)"
                  ></v-switch>
                </v-col>
                <v-col cols="auto" class="d-flex">
                  <v-card-text :class="{ 'red-text': item.isOn }" class="ml-3 mr-1">{{ item.premission_dashboard }}</v-card-text>
                </v-col>
              </v-row>
              <v-col cols="12" v-if="item.isOn" class="pl-10">
                <v-row class="margin-up-down">
                  <v-col cols="auto" class="d-flex">
                    <v-switch
                        v-model="item.permissions.read"
                        color="red"
                        class="mr-1"
                        @change="handlePermissionChange(item, 'read')"
                    ></v-switch>
                    <v-card-text class="mx-1">Read</v-card-text>
                  </v-col>
                  <v-col cols="auto" class="d-flex">
                    <v-switch
                        v-model="item.permissions.write"
                        color="red"
                        class="mx-1"
                        @change="handlePermissionChange(item, 'write')"
                    ></v-switch>
                    <v-card-text class="mr-1">Write</v-card-text>
                  </v-col>
                  <v-col cols="auto" class="d-flex">
                    <v-switch
                        v-model="item.permissions.delete"
                        color="red"
                        class="mx-1"
                        @change="handlePermissionChange(item, 'delete')"
                    ></v-switch>
                    <v-card-text class="mr-1">Delete</v-card-text>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
            </v-row>
        </v-col>
      </v-row>

    </v-card-text>

    <v-divider thickness="2"></v-divider>


    
    <v-card-actions class="float-end">
          <v-btn size="x-large"  class="custom_btn_primary pl-5 pr-5" @click="createNewRole">Create User role</v-btn>
          <v-btn size="x-large" class="custom_btn_secondary" @click="backPageRoute()">Cancel</v-btn>
        </v-card-actions>

  </v-card>
  
    <!-- Create user role dialog -->
    <v-dialog v-model="userRoleDialog" max-width="500" max-height="500" persistent>
      <v-card class="pa-1">
        <v-row dense class="rowClr ma-1">
          <v-col cols="12" md="9">
            <v-card-title class="headline">CREATE USER ROLE</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="userRoleDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider thickness="2"></v-divider>

        <v-card-text>Create a new user role type as you prefer.</v-card-text>
        <v-card-text class="textspace">
          <v-text-field
              label="Role Type *"
              variant="outlined"
              placeholder="|Type user role type here."
              v-model="userRoleType"
              required
          ></v-text-field>
        </v-card-text>
        <v-divider class="mb-3 textspace"></v-divider>

        <v-divider thickness="2"></v-divider>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="createRole()" variant="outlined" class="bg-red-lighten-4">CREATE</v-btn>&nbsp; &nbsp;
          <v-btn @click="userRoleDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>

  </v-container>
 </v-app>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CreateUserRole',
  data() {
    return {
      permissionsItems: [],
      userTypes: [],
      selectedUser:"",
      mobileUserType:'',
      centerLoading:false,
      userRoleDialog: false,

      userRoleType: '', // This will hold the role type from the dialog
      userRoleDescription: '', // This will hold the description (for now, this will be an empty string)

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      selectedItem: {
        user_role: null,
        user_role_description: '',
      },

      roleTypeRules: [
        v => !!v || 'Role type is required',
      ],
      descriptionRules: [
        v => !!v || 'Description is required',
      ],
    };
  },
  mounted() {
    this.getAllUserRoles();
    this.getDashboardPermissions();
  },
  methods: {
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },
    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: { read: false, write: false, delete: false },
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },
    handleSwitchChange(item) {
      if (!item.isOn) {
        // Reset the permissions when the switch is turned off
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },
    handlePermissionChange(item) {

      if (!item.permissions.read && !item.permissions.write && !item.permissions.delete ) {
        item.permissions.delete = false;
      }
    },

   
    createNewRole() {
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const token = localStorage.getItem('token');

      if (!userId || !token) {
        this.showSnackbar('User authentication failed. Please log in again.', 'error');
        return;
      }

      if (!this.selectedItem.user_role || !/^[a-zA-Z\s]+$/.test(this.selectedItem.user_role)) {
        this.showSnackbar('Role is required and should contain only letters and spaces.', 'error');
        return false;
      }

      if (!this.selectedItem.user_role_description || !/^[a-zA-Z\s]+$/.test(this.selectedItem.user_role_description)) {
        this.showSnackbar('Description is required and should contain only letters and spaces.', 'error');
        return false;
      }

      if (this.selectedItem.user_role_description.length > 300) {
        this.showSnackbar('The description exceeds 300 characters. Please provide a shorter description.', 'error');
        return;
      }


      // if (!this.selectedUser) {
      //   this.showSnackbar('Please select a user category.', 'error');
      //   return;
      // }

      // if(this.selectedUser == 'Mobile User'){
      //   if (!this.mobileUserType) {
      //   this.showSnackbar('Please select a moblie user type.', 'error');
      //   return;
      // }
      // }

      // ** Validate Dashboard Permissions **
      const selectedDashboards = this.permissionsItems.filter(item => item.isOn);

          if(this.selectedUser == 'Web User'){
            if (selectedDashboards.length === 0) {
            this.showSnackbar('At least one dashboard must be selected.', 'error');
            return;
          }

          const invalidDashboard = selectedDashboards.find(item => 
            !item.permissions.read && !item.permissions.write && !item.permissions.delete
          );

          if (invalidDashboard) {
            this.showSnackbar(`Please select at least one permission for the dashboard: ${invalidDashboard.name}`, 'error');
            return;
          }
      }

      

      this.centerLoading =true;
      // Prepare the payload
      const dashboards = selectedDashboards.map(item => ({
        dashboardId: item.id,
        readPremission: item.permissions.read,
        writePremission: item.permissions.write,
        deletePremission: item.permissions.delete,
      }));

      const payload = {
        userId,
        userRoleType: this.selectedItem.user_role,
        userRoleDescription: this.selectedItem.user_role_description,
        userRoleCategory: "Web User",
        // mobileUserType: this.mobileUserType || '',
        accessDashboard: dashboards,
      };

      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

      axios.post('/api/createNewUserPrermission', payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {

          if (response.status === 201){
          this.centerLoading =false;  
          this.showSnackbar('User role created successfully!', 'success');
          this.selectedItem.user_role = null;
          this.selectedItem.user_role_description = '';
          this.selectedUser = '';
          this.permissionsItems.forEach(item => {
            item.isOn = false;
            item.permissions.read = false;
            item.permissions.write = false;
            item.permissions.delete = false;
          });

          this.backPageRoute();
          }
          
        })
        .catch(error => {
          if (error.response) {
                if (error.response.status === 400 && error.response.data.error === 'User Role Already exists') {
                  this.showSnackbar('User Role already exists. Please use a different user role.', "error");
                } else {
                  console.error('Error:', error.response.data);
                  this.showSnackbar("Failed to register User Role. Please check User Role", "error");
                }
              } else if (error.request) {
                console.error('Error request data:', error.request);
              } else {
                console.error('Error message:', error.message);
              }
              this.centerLoading = false;
        });
    },




    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => ({
              id: item.id,
              name: item.role_type
            }));
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },
    backPageRoute(){
      this.$router.back();
    },
    async createRole() {

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');


      const roleData = {
        userId,
        userRoleType: this.userRoleType,
        userRoleDescription: this.userRoleDescription
      };

      try {

        const response = await axios.post('/api/createUserRole', roleData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 201) {
          this.showSnackbar("User role created successfully!", "success");
          this.getAllUserRoles();

        } else {
          this.showSnackbar("Unexpected response status.", "warning");
        }
        console.log('Role created successfully:', response.data);


        this.userRoleDialog = false;
        this.userRoleType = '';



      } catch (error) {
        console.error('Error creating role:', error);

      }
    },
    cancel() {
      this.userRoleType = '';
      this.userRoleDialog = false;
    },

  }
};
</script>

<style>
  @import "../../styles/user_management/create_user_role.scss";
</style>
    
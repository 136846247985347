<!-- Top 6 cards of main dashboard - main dashboard first component -->

<template> 

    <v-row class="mt-3">
      <!-- Total Officers -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height d-flex flex-column justify-space-between">
          <div class="d-flex align-start">
            <h4 class="card-h4-title font-weight-bold text-wrap" style="word-break: break-word; max-width: 75%;">
              TOTAL OFFICERS
            </h4>
            <v-img src="@/WEB/ICONS/total_officers.png" width="32" height="32" class="ml-auto"></v-img>
          </div>
          <h4 class="text-red-darken-4 font-weight-bold mt-2">{{ dashboard.totalOfficer }}</h4>
        </v-card>
      </v-col>

      <!-- Visited Itineraries -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height d-flex flex-column justify-space-between">
          <div class="d-flex align-start">
            <h4 class="card-h4-title font-weight-bold text-wrap" style="word-break: break-word; max-width: 75%;">
              VISITED ITINERARIES
            </h4>
            <v-img src="@/WEB/ICONS/visited_itineraries.png" width="32" height="32" class="ml-auto"></v-img>
          </div>
          <h4 class="text-red-darken-4 font-weight-bold mt-2">{{ dashboard.visitedCount }}</h4>
        </v-card>
      </v-col>

      <!-- Incomplete Itineraries -->
      <v-col cols="12" md="4">
        <v-card class="pa-3 fixed-height bg-red-lighten-5 d-flex flex-column justify-space-between">
          <div class="d-flex align-start">
            <h4 class="card-h4-title font-weight-bold text-red-darken-4 text-wrap" 
                style="word-break: break-word; max-width: 75%;">
              INCOMPLETE ITINERARIES (NOT VISITED & PENDING)
            </h4>
            <v-img src="@/WEB/ICONS/not_visited_itineraries.png" width="32" height="32" class="ml-auto"></v-img>
          </div>
          <h4 class="text-black font-weight-bold mt-2">{{ dashboard.notVisitedCount }}</h4>
        </v-card>
      </v-col>
    </v-row>


    
    
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ReportCard',
    data() {
        return {
          // dashboardData:"",
        };
    },
    computed: {
    ...mapGetters({
      dashboard: 'getDashboardDetails'
     })
    },
    created(){
      
    },
   
    methods: {
   
    
    }

}
</script>

<style>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 50px;
  width: 50px;
}

.headline {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-red {
  color: #BB0000;
}
.text-success {
  color: green;
}
.pa-3 {
  padding: 1.5rem;
}

.gray-text{
    color: #757575 !important;
}
.bottom{
    margin-top: -15px;
    margin-bottom: 15px;
}

.fixed-height {
  height: 110px;
}
</style>
<!-- Sales and collections analysis component -->

<template>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3">
          <v-card-title class="card-h4-title">ITINERARY ANALYSIS
            <div class="tool-bar-style">
              <v-btn class="red-light-text" variant="outlined" @click="generatePdf">
                <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
              </v-btn>
            </div>
          </v-card-title>
          
          <v-divider class="my-3"></v-divider>

          <v-row>
            <v-col cols="12">
              <v-card flat>
                <div ref="contentToExport">
                <LineChartDashboardComponentThree/>
                </div>
              </v-card>
            </v-col>
            
          </v-row>
          </v-card>
    </v-col>
  </v-row>
  <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
import LineChartDashboardComponentThree from "@/components/CHART/dashboardLineChartComponentThree.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
    name: 'salesAndCollectionAnalysis',
    components: {
      LineChartDashboardComponentThree
    },

    data() {
      return {
          overlay:false,
      };
    },
    
    methods: {
      async generatePdf() {
        this.overlay = true;
        const element = this.$refs.contentToExport;

        if (!element) {
          console.error("Reference to contentToExport not found.");
          return;
        }

        try {
          // Convert content to canvas with higher scale
          const canvas = await html2canvas(element, {
            scale: 3, // Higher scale for better resolution
            useCORS: true, // Handles cross-origin issues
          });

          // Get canvas dimensions
          const imgData = canvas.toDataURL("image/png");
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          // Create PDF with same dimensions as canvas
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [canvasWidth, canvasHeight],
          });

          pdf.addImage(imgData, "PNG", 0, 0, canvasWidth, canvasHeight);

          // Save the PDF
          pdf.save("ITINERARIES_Analysis.pdf");
          // Log the document download
          const fileName = 'ITINERARIES_Analysis.pdf';
          this.logDocumentDownload(fileName);
          this.overlay = false;
        } catch (error) {
          this.overlay = false;
          console.error("Error generating PDF:", error);
        }
      },
    }
};
</script>

<style scoped>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.tool-bar-style {
  display: flex;
  justify-content: space-between;
}

.red-text {
  color: #b00d0d;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

</style>
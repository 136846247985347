<template>
  <v-app id="app_user_list">

    <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col cols="6" class="mt-3">
          <h3 class="page-title">REGISTERED USER LIST</h3>
          <h5 class="light-gray-text">Analyse already registered user data.</h5>
          <v-card-title>

          </v-card-title>
        </v-col>
        <v-col cols="6" class="px-4 mt-5">
          <v-row class="tool-bar-style">
            <!-- <v-btn size="x-large" class="red-text" variant="text"  @click="createUserRole()" :disabled="true">
              <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
            </v-btn>
            <v-divider vertical :thickness="3" class="divider-vertical"></v-divider> -->
            <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <v-card-text class="custom_tool_bar_style">
          <v-btn class="custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-select
                label="User Role"
                variant="outlined"
                :items="userTypes"
                v-model="userType"
                item-title="name"
                item-value="name"
                multiple
                clearable
            ></v-select>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field
                v-model="search"
                append-inner-icon="mdi-magnify"
                variant="outlined"
                placeholder="Search"
                label="Search"
                hide-details
                clearable
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">

            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table
                :headers="headers"
                :items="filteredItems"
                class="data-table elevation-1 dataTable"
                :items-per-page="itemsPerPage"
                v-model:page="page"
                :loading="tableLoading"
                @page-count="totalPages = $event"
                width="100%"
            >
              <!-- Template for mobile_number -->
              <template v-slot:[`item.mobile_number`]="{ item }">
                <div>
                  <!-- <v-chip
                    v-for="(mobile, index) in item.mobile_number"
                    :key="index"
                    :size="x-small"
                    color="#CBAB04"
                    outlined
                    label
                    class="mp-0 my-1"
                  >
                    {{ mobile.mobile_number }}
                  </v-chip> -->
                  <v-col
                      v-for="(mobile, index) in item.mobile_number"
                      :key="index"
                      cols="12"
                  >
                    <v-chip
                        :size="x-small"
                        label
                        class="px-1"
                        variant="text"
                    >
                      {{ mobile.mobile_number }}
                    </v-chip>
                  </v-col>
                </div>
              </template>

              <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_user_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item  @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item  @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item  @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>

                  <!-- <v-list-item  @click="deactivateUser(item)">
                    <v-list-item-title
                        class="custom_font_color_activate_user">
                      {{ item.status === 'Activate' ? 'DEACTIVATE USER' : 'ACTIVATE USER' }}
                    </v-list-item-title>
                  </v-list-item> -->

                  <v-list-item @click="lockItem(item)">
                    <v-list-item-title class="custom_font_color_password_change">CHANGE PASSWORD</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            </v-data-table>
        </v-card-text>

      </v-card>
      
      
       
        <!-- Delete user confirmation dialog -->
        <v-dialog v-model="delete_user_dialog" max-width="500" max-height="500" persistent>
          <v-card class="pa-1">
            <v-row dense class="rowClr ma-1">
              <v-col cols="12" md="9">
                <v-card-title class="headline">DELETE USER DATA</v-card-title>
              </v-col>
              <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
                <v-btn icon size="x-small" @click="delete_user_dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="mt-3"></v-divider>

            <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this user ?</v-card-text>
            <v-card-text class="textspace">
              This user will be deleted immediately. Once deleted, it can no longer be used in any purpose.
            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp;
              <v-btn @click="delete_user_dialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- VIEW USER DIALOG -->
        <v-dialog v-model="view_user_dialog" fullscreen id="app_user_list">
          <v-card flat>

            <v-toolbar dense color="white" class="pr-5 mb-5">
              <v-toolbar-title>
                <img @click="homeReload()" :src="require('@/assets/app_bar_logo.png')"/>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="#b00d0d" variant="elevated" dark @click="view_user_dialog = false">
                <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
              </v-btn>
            </v-toolbar>

           
            <v-divider thickness="2"></v-divider>
            
            <v-row class="mt-0">

               <v-col cols="12" md="12">

               <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">USER DETAILS</v-card-title>
                </template>
              </v-card>

                <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                  <v-row class="ma-0">
                    <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                      <v-row>
                        <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                        <v-img :src="`${imageURL}/${user.user_image}`" aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>

                      <v-col cols="12" xs="12" sm="9" md="10" lg="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Full name: &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #05146F !important;">{{ user.name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                            <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                            USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{
                              user.user_type
                            }}</span>
                          </v-chip>
                        </div>
                      </v-col>

                      <!-- ----------------------------------------------- -->
                     
                      
                    </v-row>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn
                         class="mr-3 custom_edit_btn"
                          icon rounded size="small"
                          variant="outlined" @click="editItem(user)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <v-tooltip activator="parent" location="top">Edit</v-tooltip>
                      </v-btn>

                      <v-btn
                           class="custom_delete_btn" icon rounded
                          size="small" variant="outlined" @click="confirmDelete(user)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>

                  </v-row>

                  <v-row class="pt-6">
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-text-field
                          class="px-3 custom_input_field_class_view custom_input_field_color"
                          label="Display Name"
                          v-model="user.user_name"
                          readonly
                          variant="outlined"
                          density="comfortable"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 mt-2 custom_input_field_class_view"
                          label="User Type"
                          v-model="user.user_type"
                          readonly
                          variant="outlined"
                          density="comfortable"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="Province"
                          v-model="user.province"
                          readonly
                          variant="outlined"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="District"
                          v-model="user.district"
                          readonly
                          variant="outlined"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="DSD"
                          v-model="user.dsd"
                          readonly
                          variant="outlined"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="GND"
                          v-model="user.gnd"
                          readonly
                          variant="outlined"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 mt-2 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="Email Address"
                          v-model="user.email"
                          readonly
                          variant="outlined"
                      ></v-text-field>
                      <!-- </v-card> -->
                    </v-col>
                    <v-col cols="6" class="px-6">
                      <!-- <v-card flat class="pt-2 card-hight-fixed"> -->
                      <v-text-field
                          class="px-3 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="NIC"
                          v-model="user.nic"
                          readonly
                          variant="outlined"
                      ></v-text-field>

                      <v-text-field
                          class="px-3 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="Registration Number"
                          v-model="user.registration_num"
                          readonly
                          variant="outlined"
                      ></v-text-field>


                      <div v-for="(mobile, index) in user.mobile_number" :key="index">
                        <!-- <v-col cols="12"> -->
                        <v-text-field
                            :label="'Mobile Number ' + (index + 1)"
                            v-model="mobile.mobile_number"
                            readonly
                            class="px-3 custom_input_field_class_view custom_input_field_color"
                            density="comfortable"
                            variant="outlined"
                        ></v-text-field>
                        <!-- </v-col> -->
                      </div>

                      <v-text-field
                          class="px-3 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="Gender"
                          v-model="user.gender"
                          readonly
                          variant="outlined"
                      ></v-text-field>

                      <v-textarea
                          class="px-3 custom_input_field_class_view custom_input_field_color"
                          density="comfortable"
                          label="Address"
                          v-model="user.address"
                          readonly
                          rows="3"
                          variant="outlined"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <!-- EDIT USER DIALOG -->
        <v-dialog v-model="edit_user_dialog" fullscreen id="app_user_list">
          <v-card flat>
            <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/assets/app_bar_logo.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="edit_user_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>

            <v-divider class="divider-margin-dv"></v-divider>
            
            <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
              <v-col cols="12" md="12">
                <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">EDIT USER DETAILS
                  </v-card-title>
                </template>
              </v-card>

              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>

                      <v-col cols="12" xs="12" sm="4" md="2" lg="2">
                        <div class="image card-h4-title" style="position: relative;">
                          <img
                              :src="user.user_image ? `${imageURL}/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                              class="user_image_view"/>

                          <v-btn class="custom_user_image_upload_btn custom_btn_primary" icon rounded size="small"
                                 @click="dialog_image_upload=true">
                            <v-icon>mdi-upload</v-icon>
                            <v-tooltip activator="parent" location="top">Upload User Image</v-tooltip>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="8" md="10" lg="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Full name: &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #05146F !important;">{{ user.name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                            <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                            USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{
                              user.user_type
                            }}</span>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                    </v-col>

                    <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateUser">UPDATE
                        USER
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>

                  </v-row>

                  <v-row class="pt-6">
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-col cols="11">

                          <v-text-field
                              class="px-3 custom_input_field_color"
                              density="comfortable"
                              label="Display Name *"
                              v-model="user.user_name"
                              variant="outlined"
                              required
                          ></v-text-field>

                          <v-select
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="User Type *"
                              variant="outlined"
                              :items="userTypes"
                              v-model="user.user_type"
                              item-title="name"
                              item-value="name"
                              :rules="[v => !!v || 'User role is required']"
                              required
                              placeholder="Select User Type Here"
                          ></v-select>

                          <v-select
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="Province *"
                              variant="outlined"
                              placeholder="Select province"
                              v-model="user.province"
                              :items="provinces"
                              clearable
                              @update:modelValue="(value) => {
                                user.province = value;
                                if (!value) {
                                  user.district = null;
                                  user.dsd = null;
                                  user.gnd = null;
                                  districts = [];
                                  dsdList = [];
                                  gndList = [];
                                } else {
                                  getAllDistrictAccProv(value);
                                }
                              }"
                              item-text="label"
                              item-value="value"
                              :loading="mDistrictLoading"
                          ></v-select>

                          <v-select
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="District *"
                              variant="outlined"
                              placeholder="Select district"
                              v-model="user.district"
                              :items="districts"
                              clearable
                              @update:modelValue="(value) => {
                                user.district = value;
                                if (!value) {
                                  user.dsd = null;
                                  user.gnd = null;
                                  dsdList = [];
                                  gndList = [];
                                } else {
                                  getAllDsd(value);
                                }
                              }"
                              item-text="label"
                              item-value="value"
                              :loading="mDistrictLoading"
                          ></v-select>

                          <v-select
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="DSD"
                              variant="outlined"
                              placeholder="Select divisional"
                              v-model="user.dsd"
                              :items="dsdList"
                              clearable
                              item-text="label"
                              item-value="value"
                              @update:modelValue="(value) => {
                                user.dsd = value;
                                if (!value) {
                                  user.gnd = null;
                                  gndList = [];
                                } else {
                                  getAllGnd(value);
                                }
                              }"
                              :loading="mDSDLoading"
                          ></v-select>

                          <v-select
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="GND"
                              variant="outlined"
                              clearable
                              :items="gndList"
                              v-model="user.gnd"
                              required
                              :loading="mGNDLoading"
                              placeholder="Select GND"
                          ></v-select>

                          <v-text-field
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="Email Address *"
                              v-model="user.email"
                              variant="outlined"
                              required
                              placeholder="Type Email Here"
                          ></v-text-field>
                        </v-col>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-col cols="11">

                          <v-text-field
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="Registration Number *"
                              v-model="user.registration_num"
                              variant="outlined"
                              placeholder="Type Mobile Number Here"
                              :disabled="true"
                          ></v-text-field>

                          <div v-for="(phoneNo, index) in user.mobile_number" :key="index">
                            <v-row>
                              <v-col cols="8">
                                <v-text-field
                                    :label="'Mobile Number ' + (index + 1)"
                                    v-model="user.mobile_number[index].mobile_number"
                                    variant="outlined"
                                    class="px-3 mt-2 custom_input_field_color"
                                    density="comfortable"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="auto" class="align-center justify-center">
                                <v-btn
                                    density="default"
                                    class="green-text"
                                    variant="outlined"
                                    icon="mdi-plus-thick"
                                    @click="addPhoneNo"
                                ></v-btn>
                              </v-col>
                              <v-col cols="auto" class="align-center justify-center">
                                <v-btn
                                    density="default"
                                    class="red-text-btn"
                                    variant="outlined"
                                    icon="mdi-minus-thick"
                                    @click="removePhoneNo(index)"
                                    v-if="user.mobile_number.length > 1"
                                ></v-btn>
                              </v-col>
                            </v-row>
                          </div>

                          <v-text-field
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="NIC *"
                              v-model="user.nic"
                              variant="outlined"
                              required
                              placeholder="Type NIC"
                          ></v-text-field>

                          <v-select
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="Gender *"
                              variant="outlined"
                              :items="gender"
                              v-model="user.gender"
                              required
                              placeholder="Select Gender"
                          ></v-select>

                          <v-textarea
                              class="px-3 mt-2 custom_input_field_color"
                              density="comfortable"
                              label="Address *"
                              v-model="user.address"
                              variant="outlined"
                              rows="3"
                              required
                              placeholder="Type Address Here"
                          ></v-textarea>
                        </v-col>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

          </v-card>
        </v-dialog>

        <!-- User Image upload dialog -->
        <v-dialog v-model="dialog_image_upload" id="app_user_list" max-height="600" max-width="500" persistent>
        <v-card id="app_user_list" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">CHANGE USER IMAGE</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="dialog_image_upload = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <div class="drop-area" @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent>
                <input ref="fileInput" accept="image/*" style="display: none;" type="file" @change="handleImageUpload"/>
                <div v-if="previewImage" class="image-card-container">
                  <v-card class="image-card">
                    <v-img :alt="'Image preview'" :src="previewImage" class="image-preview"></v-img>
                    <v-btn class="remove-icon" icon size="x-small" @click.stop="removeImage">
                      <v-icon color="red" size="small">mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                </div>
                <div v-if="!previewImage" class="placeholder-container">
                  <v-icon size="36">mdi-cloud-upload</v-icon>
                  <span>Drag your photo here OR click to <span style="color:#156CF7;">Browse from device</span></span>
                </div>
              </div>


              <v-divider class="mt-4"></v-divider>

              <div class="float-end mt-4 ">
                <v-btn class="custom_btn_primary" @click="uploadImage">Upload</v-btn>
                <v-btn class="ml-3 custom_btn_secondary" @click="dialog_image_upload = false">CANCEL</v-btn>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-dialog>

        <!-- reset user password dialog -->
        <v-dialog v-model="reset_password_dialog" fullscreen id="app_user_list">
          <v-card flat>

            <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
              <v-toolbar-title>
                <v-img class="custom_company_logo_nav" :src="require('@/assets/app_bar_logo.png')"
                      @click="homeReload()"></v-img>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="custom_red_btn" variant="flat" @click="reset_password_dialog = false">
                <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
              </v-btn>
          </v-toolbar>

            

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">
              <v-col cols="12" md="12">
                
                <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">RESET PASSWORD</v-card-title>
                </template>
              </v-card>


                <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                  
                  <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                        <v-img :src="`${imageURL}/${user.user_image}`" aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="9" md="10" lg="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Full name: &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #05146F !important;">{{ user.name }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip class="text-body-1 mr-3 custom_green_chip" variant="outlined">
                            <v-icon color="#1B5E20">mdi-check-circle-outline</v-icon>&nbsp;
                            USER TYPE - &nbsp;<span style="text-transform: uppercase !important;">{{
                              user.user_type
                            }}</span>
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-tabs bg-color="#FFEBEE" v-model="tab" align-tabs="center"
                              next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
                              show-arrows>
                        <v-tab class="custom_page_main_sub_title" value="one">GENERATE RESET LINK</v-tab>
                        <v-tab class="custom_page_main_sub_title" value="two">AUTO GENERATE PASSWORD</v-tab>
                        <v-tab class="custom_page_main_sub_title" value="three">MANUAL PASSWORD CHANGE</v-tab>
                      </v-tabs>

                      <v-tabs-window v-model="tab">
                        <v-tabs-window-item class="mt-9" value="one">
                          <v-btn class="text-body-2 custom_btn_primary" size="x-large" variant="outlined"
                                 @click="generateResetLink(user)">
                            GENERATE & SEND RESET LINK
                          </v-btn>
                        </v-tabs-window-item>

                        <v-tabs-window-item class="mt-9" value="two">
                          <v-btn class="text-body-2 custom_btn_primary" size="x-large" variant="outlined"
                                 @click="generateAndSendPassword(user)">
                            GENERATE PASSWORD & SEND PASSWORD
                          </v-btn>
                        </v-tabs-window-item>

                        <v-tabs-window-item class="mt-9 px-1" value="three">
                          <v-text-field
                              v-model="manualPassword" :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              :rules="[
                                v => !!v || 'Password is required',
                                v => (v && v.length >= 8) || 'Password must be at least 8 characters',
                                v => /[A-Za-z]/.test(v) || 'Password must contain at least one letter',
                                v => /\d/.test(v) || 'Password must contain at least one number',
                              ]"
                              :type="showPassword ? 'text' : 'password'" autocomplete="new-password"
                              class="mt-2 custom_input_field_color" label="Password *" placeholder="Type password here"
                              required variant="outlined" @click:append-inner="togglePasswordVisibility"
                              density="comfortable"
                          ></v-text-field>

                         

                          <v-text-field v-model="manualConfirmPassword"
                                        :append-inner-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="showConfirmPassword ? 'text' : 'password'" autocomplete="new-password"
                                        class="mt-2 custom_input_field_color" label="Confirm Password *"
                                        placeholder="Confirm your password here" required variant="outlined"
                                        @click:append-inner="toggleConfirmPasswordVisibility" density="comfortable"
                          ></v-text-field>

                          <v-divider></v-divider>

                          <v-row class="pa-3 mb-2 mt-3">
                            <v-btn class="text-body-2 custom_btn_primary mr-3" size="large" variant="outlined"
                                   @click="updatePasswordRest(user)">
                              UPDATE
                            </v-btn>
                            <v-btn class="text-body-2 custom_btn_secondary" size="large" variant="outlined"
                                   @click="reset_password_dialog = false">
                              CANCEL
                            </v-btn>
                          </v-row>
                        </v-tabs-window-item>

                      </v-tabs-window>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                    </v-col>
                  </v-row>

                </v-card-text>
                  
                  
                </v-card>

              </v-col>
            </v-row>

          </v-card>
        </v-dialog>

        <!-- Agent Busy Warning Dialog Open -->
      <v-dialog v-model="agentBusyWarningDialog" id="app_user_list" max-height="500" max-width="500" persistent>
        <v-card id="app_user_list" rounded="md">
          <div class="pa-4">
            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #05146F" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Warning
                </v-card-title>

                <!-- Move the message here -->
                <v-card-text class="mt-2 text-body-2 text-warning">
                  This user is busy right now. You cannot edit or delete.
                </v-card-text>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4">
                  <v-btn class="ml-3 custom_btn_secondary" @click="agentBusyWarningDialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>


      
      <!-- Deactivate User confirmation dialog -->
      <v-dialog v-model="user_status_dialog" id="app_user_list" max-height="500" max-width="500" persistent>
        <v-card id="app_user_list" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">
                  {{ deactivateItemStatus === 'Activate' ? 'DEACTIVATE USER' : 'ACTIVATE USER' }}
                </v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="user_status_dialog = false" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title style="color: #05146F" class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Are you sure you want to
                  {{ deactivateItemStatus === 'Activate' ? 'deactivate' : 'activate' }} this user?
                </v-card-title>

                <p style="opacity: 0.7;" class="text-body-2 mt-4 text-justify">
                  This user will be {{ deactivateItemStatus === 'Activate' ? 'deactivated' : 'activated' }} immediately.
                  Once {{ deactivateItemStatus === 'Activate' ? 'deactivated' : 'activated' }}, it {{
                    deactivateItemStatus === 'Activate' ? 'can no longer be used in any purpose' : 'will be able to access the system again'
                  }}.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4">
                  <v-btn class="custom_btn_primary" @click="deactivateItem">
                    {{ deactivateItemStatus === 'Activate' ? 'DEACTIVATE' : 'ACTIVATE' }}
                  </v-btn>
                  <v-btn class="ml-3 custom_btn_secondary" @click="user_status_dialog = false">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <!-- Snackbar to show notifications -->
      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :top="snackAlert.snackBarTop"
          :right="snackAlert.snackBarRight"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>
    
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: 'UserRoleList',
  data() {
    return {

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      centerLoading: false,
      // Dialog state
      delete_user_dialog: false,
      manualPassword: "",
      manualConfirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      view_user_dialog: false,
      edit_user_dialog: false,
      reset_password_dialog: false,
      dialog_image_upload: false,
      previewImage: null,
      agentBusyWarningDialog: false,
      user_status_dialog: false,
      //reset password three options tabs
      tab: null,
      autoGenaratePassword: null,

      search: '',
      headers: [
        { text: 'REG: NUMBER', value: 'registration_num', title: 'REG: NUMBER', sortable: true  },
        { text: 'DISPLAY NAME', value: 'user_name', title: 'DISPLAY NAME', sortable: true  },
        { text: 'USER ROLE TYPE', value: 'user_type', title: 'USER ROLE TYPE', sortable: false  },
        { text: 'MOBILE', value: 'mobile_number', title: 'MOBILE', sortable: true  },
        { text: 'NIC', value: 'nic', title: 'NIC', sortable: true  },
        { text: 'ACTION', value: 'action', title: 'ACTION'}
      ],
      items: [],

      userTypes: [],
      gender: ['Male', 'Female'],

      itemsPerPage: 10,
      page: 1,
      totalPages: 1,

        userType: [],

      user: {
        name: '',
        user_name: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        email: '',
        nic: '',
        registration_num: '',
        mobile_number: [{ mobile_number: '' }],
        gender: '',
        address: '',
        password: '',
        confirmPassword: '',
        user_type: '',
        user_image: '',
      },

      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      imageBase64: null,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      
      // user.mobile_number: [""],
      // user: {},
      deleteItemId: null, // Store the ID of the item to be deleted
      baseUrl:'',
    };
  },

  mounted() {
    this.getAllProvinces();
    this.getAllUserRoles();
    this.getBaseUrl();
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
  },
  created(){
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
    this.fetchDataintoTable();
  },
  computed: {
    // filteredItems() {
    //   return this.items.filter(item => {
    //     const matchesUserRole = !this.userRole.userType || item.user_type === this.userRole.userType;
    //     const matchesSearch = !this.search || item.user_name.toLowerCase().includes(this.search.toLowerCase());
    //     return matchesUserRole && matchesSearch;
    //   });
    // }

    filteredItems() {
      const searchLower = this.search ? this.search.toLowerCase() : '';

      return this.items.filter(item => {

        const matchesUserRole = this.userType.length === 0
            || this.userType.includes(item.user_type);

        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {});
          ro.observe(document.body);
        }

        const registrationNum = item.registration_num ? item.registration_num.toLowerCase() : '';
        const userName = item.user_name ? item.user_name.toLowerCase() : '';
        const userType = item.user_type ? item.user_type.toLowerCase() : '';
        const nic = item.nic ? item.nic.toLowerCase() : '';


        const matchesSearch =
            !this.search ||
            userName.includes(searchLower) ||
            registrationNum.includes(searchLower) ||
            userType.includes(searchLower) ||
            nic.includes(searchLower);

        return matchesUserRole && matchesSearch;
      });
    }



  },
  methods: {
    getBaseUrl(){
      this.baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
    },
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },
    exportExcel() {
      // Define the base columns for all users
      const baseColumns = [
        'DISPLAY NAME', 'REG NUM', 'TYPE', 'MOBILE NUMBER', 'EMAIL',
        'NIC', 'GENDER', 'ADDRESS', 'PROVINCE', 'DISTRICT', 'DSD', 'GND',
      ];

    

      // Check if additional columns should be included
      const includeExtraColumns = this.filteredItems.some(
          item => item.user_catagory?.category !== 'Web User'
      );

      // Final columns to include
      const allColumns = includeExtraColumns
          ? [...baseColumns,]
          : baseColumns;

      // Prepare the data for Excel export
      const tableData = this.filteredItems.map((item) => {
        const rowData = {
          'DISPLAY NAME': item.name || '',
          'REG NUM': item.registration_num || '',
          'TYPE': item.user_type || '',
          'MOBILE NUMBER': item.mobile_number
              ? item.mobile_number.map((numberObj) => numberObj.mobile_number).join(', ')
              : 'N/A',
         
          'EMAIL': item.email || '',
          'NIC': item.nic || '',
          'GENDER': item.gender || '',
          'ADDRESS': item.address || '',
          'PROVINCE': item.province || '',
          'DISTRICT': item.district || '',
          'DSD': item.dsd || '',
          'GND': item.gnd || '',
        };

        

        return rowData;
      });

      // Create worksheet from JSON data with dynamic columns
      const worksheet = XLSX.utils.json_to_sheet(tableData, {header: allColumns});

      // Set custom column widths
      worksheet['!cols'] = Array(allColumns.length).fill({wch: 20}); // Adjust width dynamically

      // Freeze the header row
      worksheet['!freeze'] = {ySplit: 1};

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'User Reports');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'All_User_Reports.xlsx');

      // const fileName = 'All_Agents_Reports.xlsx';
      // const file_type = 'excel';
      // this.logDocumentDownload(fileName, file_type);
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('User Analysis', 14, 10);

      // Prepare the data for the table
      const tableData = this.filteredItems.map((item, index) => [
        index + 1,
        item.registration_num,
        item.user_name,
        item.user_type,
        // item.mobile_number,
        // Join mobile numbers with a newline
        item.mobile_number.map(m => m.mobile_number).join('\n'),
        item.nic,
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'REG: NUMBER', 'DISPLAY NAME', 'USER TYPE', 'MOBILE', 'NIC'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('users_analysis.pdf');

    },


    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];

      this.user.district = null;
      this.user.dsd = null;
      this.user.gnd = null;
      this.districts = [];

      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.gndList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    deactivateUser(item) {
      if (item.active_status === 1 && item.user_catagory && item.user_catagory.category === "Mobile User"
      ) {
        this.agentBusyWarningDialog = true;
        return; // Exit function if condition is met
      }
      this.user_status_dialog = true;
      this.deactivateItemId = item.id;
      this.deactivateItemStatus = item.status; // Store the current status
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    uploadImage() {
      if (this.imageBase64) {
        // Send the extracted Base64 string to the server
        console.log("Image Base64 string:", this.imageBase64);
        this.dialog_image_upload = false;
      } else {
        console.error("No image to upload");
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();  // Triggers file input dialog
    },

    removeImage() {
      this.previewImage = null;
      this.$refs.fileInput.value = null;
    },

    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result; // Set the previewImage to the base64 data URL

          const fullBase64 = e.target.result; // Full Base64 string
          this.imageBase64 = fullBase64.split(',')[1]; // Extract only the data portion
          // this.user.user_image = this.imageBase64;
        };
        reader.readAsDataURL(file); // Convert the file to a base64 string
      }
    },

    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.handleImageUpload({target: {files: [file]}});
    },

   
    validateForm() {
        // Validate Display Name (User Name)
        if (!this.user.user_name || !/^[a-zA-Z0-9\s]+$/.test(this.user.user_name)) {
            this.showSnackbar('Display Name is required and should contain only letters, numbers, and spaces.', 'error');
            return false;
        }


        // Validate User Type
        if (!this.user.user_type) {
            this.showSnackbar('User Type is required.', 'error');
            return false;
        }

        // Validate Email Address
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.user.email || !emailRegex.test(this.user.email)) {
            this.showSnackbar('A valid Email Address is required.', 'error');
            return false;
        }

        // Validate Phone Numbers (At least one required)
        const phonePattern = /^[0-9]{10}$/;
        if (!this.user.mobile_number || this.user.mobile_number.length === 0) {
            this.showSnackbar('At least one phone number is required.', 'error');
            return false;
        }

        for (let i = 0; i < this.user.mobile_number.length; i++) {
            if (!this.user.mobile_number[i].mobile_number || !phonePattern.test(this.user.mobile_number[i].mobile_number)) {
                this.showSnackbar(`Phone Number ${i + 1} is invalid. It should contain exactly 10 digits.`, 'error');
                return false;
            }
        }

        // Validate NIC
        const nicPattern = /^(\d{9}[VvXx]|[0-9]{12})$/;
        if (!this.user.nic || !nicPattern.test(this.user.nic)) {
            this.showSnackbar(
                'NIC must be either 9 digits followed by "V/v" or "X/x" (e.g., 123456789V or 123456789X) or exactly 12 digits (e.g., 123456789012).',
                'error'
            );
            return false;
        }

        // Validate Gender
        if (!this.user.gender) {
            this.showSnackbar('Gender is required.', 'error');
            return false;
        }

        // Validate Province
        if (!this.user.province) {
            this.showSnackbar('Province is required.', 'error');
            return false;
        }

        // Validate District
        if (!this.user.district) {
            this.showSnackbar('District is required.', 'error');
            return false;
        }

       

        // Validate Address
        if (!this.user.address) {
            this.showSnackbar('Address is required.', 'error');
            return false;
        }

        

        return true;
    },


    replaceNullWithEmptyString(value) {
      return value === null ? "" : value;
    },

    async updateUser() {
      if (!this.validateForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const logUserId = localStorage.getItem('userId');

        if (!this.user.id) {
          console.error("User ID is missing");
          return;
        }

        this.centerLoading = true;

        const formData = new FormData();
        formData.append("userId", this.replaceNullWithEmptyString(this.user.id));
        formData.append("logUserId", this.replaceNullWithEmptyString(logUserId));
        formData.append("userName", this.replaceNullWithEmptyString(this.user.user_name));
        formData.append("fullName", this.replaceNullWithEmptyString(this.user.name));
        formData.append("userType", this.replaceNullWithEmptyString(this.user.user_type));
        formData.append("province", this.replaceNullWithEmptyString(this.user.province));
        formData.append("district", this.replaceNullWithEmptyString(this.user.district));
        formData.append("dsd", this.replaceNullWithEmptyString(this.user.dsd));
        formData.append("gnd", this.replaceNullWithEmptyString(this.user.gnd));
        formData.append("emailAddress", this.replaceNullWithEmptyString(this.user.email));
        formData.append("nic", this.replaceNullWithEmptyString(this.user.nic));
        formData.append("gender", this.replaceNullWithEmptyString(this.user.gender));
        formData.append("address", this.replaceNullWithEmptyString(this.user.address));
        formData.append("image", this.imageBase64);

        const mobileNumbersArray = this.user.mobile_number
            .filter(phoneNo => phoneNo.mobile_number) // Avoid empty values
            .map(phoneNo => ({
              mobile_number: this.replaceNullWithEmptyString(phoneNo.mobile_number),
            }));

        formData.append("mobileNumber", JSON.stringify(mobileNumbersArray));

        // Append image if available
        if (this.user.image && this.user.image.length > 0) {
          const base64String = await this.convertToBase64(this.user.image[0]);
          formData.append("image", base64String);
        }

        console.log([...formData]);

        // API call
        const response = await axios.post(
            "/api/createdUserUpdate",
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            }
        );

        if (response.status === 201 || response.status === 200) {
          this.showSnackbar("User updated successfully", "success");
          this.centerLoading = false;
          this.edit_user_dialog = false;
          this.previewImage = "";
          this.imageBase64 = "";
          this.fetchDataintoTable();
        } else {
          this.showSnackbar(response.data.message || "Failed to update user", "error");
          this.centerLoading = false;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400 && error.response.data.error === 'User already exists') {
            this.showSnackbar('User already exists. Please use a different NIC.', "error");
          } 
          if (error.response.status === 400 && error.response.data.error === 'Email already in use') {
            this.showSnackbar('Email already in use.', "error");
          }
          if (error.response.status === 400 && error.response.data.error === 'Display name already exists') {
            this.showSnackbar('Display name already exists. Please use a different Display Name.', "error");
          } else {
            console.error('Error:', error.response.data);
            this.showSnackbar("Failed to register user Email already exists. Please check Email", "error");
          }
        } else if (error.request) {
          console.error('Error request data:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.centerLoading = false;
      }
    },

    // Convert image to base64 string (if necessary)
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.user_role
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },

    fetchDataintoTable(){

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      this.tableLoading = true;
      const formData = new FormData();
      formData.append("userId", userId);


      axios.post("/api/createdUserList", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        //  this.items= response.data.data;
        // Sort the data by created_at in descending order (newest first)
        this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        this.tableLoading = false;
        console.log('table data',this.items);
      })
          .catch((error) => {
            // if (error.response.status === 401) {

            // } else {

            // }

            console.log(error);

          });
    },


    backPageRoute(){
      this.$router.back();
    },

    viewItem(item) {
      this.user = item;
      this.view_user_dialog = true;
      console.log("View item:", item);
    },
    editItem(item) {
      this.user = { ...item };
     
      console.log("Edit item:", item);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const userListId = item.id; // Use the stored ID
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/beforUserDeleteCheck`, {
        userId: userId,
        userListId: userListId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then((response) => {
            if (response.status === 200) {
              const checkDeletedtStatus = response.data.data;
              console.log("fucking data" ,checkDeletedtStatus )

              if(checkDeletedtStatus.active_status === 1){
                this.showSnackbar("User is busy you can not edit.", "error");
              }else{
                this.edit_user_dialog = true;
              }
            }
          })
          .catch(error => {
            console.error('Error deleting users:', error);
           
          });

    },

    lockItem(item) {
      console.log("Lock item:", item);
      this.user = item;
      this.reset_password_dialog = true;
    },
    async generateResetLink(user) {
      this.centerLoading = true;
      const emails = user.email;

      const baseUrl = window.location.origin;

      try {
        const response = await axios.post('/api/genarateForgetPassword', {email: emails, baseUrl: baseUrl});
        if (response.status === 200) {
          this.showSnackbar("Reset Link successfully sent.Check user mail", "success");
          this.centerLoading = false;
          console.log("Reset link generated and sent to:", emails);
        } else {
          this.showSnackbar("Failed to send reset email. Please try again.", "error");
          console.error("Error generating reset link:", response.statusText);
          this.centerLoading = false;
        }
      } catch (error) {
        if (error.response) {
          const errorMsg = error.response.data.message || "Failed to send reset email.";
          this.showSnackbar(errorMsg, "error");
          this.centerLoading = false;
        } else {
          this.showSnackbar("An error occurred. Please try again later.", "error");
          this.centerLoading = false;
        }
      }
    },

    generateAndSendPassword(user) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token')?.trim();
      const email = user.email;
      this.centerLoading = true;

      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      console.log("Token from localStorage:", token);
      console.log("User ID:", userId);
      console.log("Email:", email);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/autogenaratePassword`,
          {
            userId: userId,
            email: email,
            token: token
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            if (response.status === 200) {
              console.log("Password generated and sent to:", email);
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "success";
              this.snackAlert.snackMessage = "Password generated and sent to user";
            } else {
              console.error("Error generating password:", response.statusText);
              this.centerLoading = false;
            }
          })
          .catch(error => {
            console.error("There was an error generating the password!", error);
            this.centerLoading = false;
            if (error.response && error.response.data) {
              console.error("Error details:", error.response.data);
              this.centerLoading = false;
            }
          });
    },
    confirmDelete(item) {
      

      console.log("item",item);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const userListId = item.id; // Use the stored ID
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/beforUserDeleteCheck`, {
        userId: userId,
        userListId: userListId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then((response) => {
            if (response.status === 200) {
              const checkDeletedtStatus = response.data.data;
              console.log("fucking data" ,checkDeletedtStatus )

              if(checkDeletedtStatus.active_status === 1){
                this.showSnackbar("User is busy you can not delete.", "error");
              }else{
                this.delete_user_dialog = true;
                this.deleteItemId = item.id;
              }
            }
          })
          .catch(error => {
            console.error('Error deleting users:', error);
           
          });

      


    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const userListId = this.deleteItemId; // Use the stored ID
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/createdUserdelete`, {
        userId: userId,
        userListId: userListId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.fetchDataintoTable(); // Refresh the user list
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
          })
          .catch(error => {
            console.error('Error deleting users:', error);
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
          });
    },
    homeReload(){
      window.location.href = '/dashboard';
    },
    addPhoneNo() {
      // this.user.mobile_number.push('');
      // this.user.mobile_number.push({ mobile_number: '' });
      this.user.mobile_number.push({ mobile_number: '' });
    },
    removePhoneNo(index) {
      // if (this.user.mobile_number.length > 1) {
      // this.user.mobile_number.splice(index, 1);
      // }
      if (this.user.mobile_number.length > 1) {
        this.user.mobile_number.splice(index, 1);
      }
    },

    // generateResetLink(item){
    //   this.user = item;
    //   console.log("resentlink password item:", item);
    // },
    // generateAndSendPassword(item){
    //   this.user = item;
    //   console.log("autoGenarateLink password item:", item);
    // },

    validatePasswordForm() {
      // Validate form fields
      if (!this.manualPassword || this.manualPassword.length < 8) {
        this.showSnackbar("Password must be at least 8 characters long.", "error");
        return false;
      }
      if (!/[A-Za-z]/.test(this.manualPassword)) {
        this.showSnackbar("Password must contain at least one letter.", "error");
        return false;
      }
      if (!/\d/.test(this.manualPassword)) {
        this.showSnackbar("Password must contain at least one number.", "error");
        return false;
      }
      if (this.manualPassword !== this.manualConfirmPassword) {
        this.showSnackbar("Passwords do not match.", "error");
        return false;
      }

      return true;
    },

    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    updatePasswordRest(user) {
      console.log("update password item:", user);

      if (!this.validatePasswordForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const email = user.email;
      this.centerLoading = true;

      if (!token) {
        console.error("No token found. Please log in again.");
        this.centerLoading = false;
        return;
      }

      // Check if password and confirm password match
      if (this.manualPassword !== this.manualConfirmPassword) {
        console.error("Password and Confirm Password do not match.");
        this.showSnackbar("Password and Confirm Password do not match.", 'error');
        this.centerLoading = false;
        return;
      }

      axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/manualEnterPassword`,
          {
            userId: userId,
            email: email,
            password: this.manualPassword,
            confirmPassword: this.manualConfirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
      )
          .then(response => {
            if (response.status === 200) {
              console.log("Password generated and sent to:", email);
              this.showSnackbar("Password updated successfully.", 'success');
              this.manualPassword = '';
              this.manualConfirmPassword = '';
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "success";
              this.snackAlert.snackMessage = "Password Updated";
            } else {
              console.error("Error generating password:", response.statusText);
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "error";
              this.snackAlert.snackMessage = "Password Updated failed";
            }
          })
          .catch(error => {
            console.error("There was an error generating the password!", error);
            this.centerLoading = false;
            if (error.response && error.response.data) {
              console.error("Error details:", error.response.data);
              this.showSnackbar(error.response.data.error || "An error occurred.", 'error');
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "error";
              this.snackAlert.snackMessage = "Password Updated failed";
            }
          });
    },
    createUserRole(){
      this.$router.push({ path: '/create-user-role' });
    },
  }
};
</script>
<style>
@import "../../styles/user_management/users_list.scss";
</style>
    
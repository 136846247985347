<template>
  <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
    <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
  </v-overlay>

  <v-app id="app_lead_list">
    <v-container fluid>

      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">ITINERARY DATA</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse Itinerary data in a variety of
            parameters.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-btn color="#05146F" size="x-large" variant="text" @click="createItenarary()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;CREATE ITINERARY
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">

        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>


        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" md="4">
              <v-date-input v-model="dates" @click:append-inner="clearDateRange" append-inner-icon="mdi-close"
                            label="Date Range" prepend-inner-icon="$calendar" multiple="range"
                            prepend-icon="" placeholder="Select a Date Range" variant="outlined" density="comfortable"
                            class="custom_input_field_color"
              ></v-date-input>
            </v-col>


            <v-col cols="12" md="4">
              <v-select
                  v-model="filters.visitedStatus"
                  :items="visitStatus"
                  chips
                  @change="fetchDataintoTable"
                  clearable
                  label="Visited Status"
                  multiple
                  placeholder="Select status"
                  variant="outlined"
              >

              </v-select>
            </v-col>


            <v-col cols="12" md="4">
              <v-select
                  v-model="officer"
                  :items="agentList"
                  item-value="name"
                  item-title="name"
                  chips
                  clearable
                  label="Officers"
                  multiple
                  placeholder="Select Officer"
                  variant="outlined"
              >

              </v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-data-table
              v-model:page="page"
              :headers="headersItenaray"
              :items="processedItineraryList"
              :items-per-page="itemsPerPage"
              :loading="tableLoading"
              class="dataTable"
          >
            <!-- Assign Officer Column -->
            <template v-slot:[`item.assign_officer`]="{ item }">
              <div>
                <div>{{ item.assign_officer }}</div>
                <div style="color: #01579B; font-size: 0.9em;">ITINERARY NUM: {{ item.id }}</div>
              </div>
            </template>

            <!-- Visited Status Column -->
            <template v-slot:[`item.visited_status`]="{ item }">
              <v-row>
                <v-col cols="12" md="8" lg="8">
                  <!-- Status Chip -->
                  <v-chip v-if="item.visited_status === 'VISITED'" class="custom_green_chip" size="small"
                          variant="outlined">
                    Visited
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.visited_status === 'PENDING'" class="custom_orange_chip" size="small"
                          variant="outlined">
                    Pending
                    <v-icon class="justify-end ml-12">mdi-check</v-icon>
                  </v-chip>

                  <v-chip v-if="item.visited_status === 'NOT VISITED'" class="custom_red_chip" size="small"
                          variant="outlined">
                    Not Visited
                    <v-icon class="justify-end ml-6">mdi-close</v-icon>
                  </v-chip>

                  <!-- Visited Date Below the Chip -->
                  <div v-if="item.visited_status === 'VISITED'" style="font-size: 0.8em; color: #666; margin-top: 4px;">
                    {{ formatDate(item.visited_date) }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <!-- Assign Date Column -->
            <template v-slot:[`item.assign_date`]="{ item }">
              {{ formatDate(item.assign_date) }}
            </template>

            <!-- Actions Column -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_product_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>

                  <!-- Conditionally Render Edit Button -->
                  <v-list-item
                      v-if="item.visited_status === 'NOT VISITED'"
                      @click="editItem(item)"
                  >
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>

                  <!-- Conditionally Render Delete Button -->
                  <v-list-item
                      v-if="item.visited_status === 'NOT VISITED'"
                      @click="confirmDelete(item)"
                  >
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>

        </v-card-text>


      </v-card>


      <!-- VIEW LEAD DIALOG -->
      <v-dialog v-model="view_leads_dialog" fullscreen id="app_lead_list">
        <v-card flat>


          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/assets/app_bar_logo.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="view_leads">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">

            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">ITINERARY DETAILS
                  </v-card-title>
                </template>
              </v-card>


              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">

                  <v-col cols="12" xs="12" sm="4" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                        <v-img
                            :src="selectedLead.officer.user_image ? `${imageURL}/${selectedLead.officer.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                            aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="9" lg="10" xl="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Officer Name:
                            &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #05146F !important;">{{ selectedLead.assign_officer }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ selectedLead.officer_mobile_numbers.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Itinerary num:
                            &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ selectedLead.id }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip v-if="selectedLead.visited_status === 'NOT VISITED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <v-chip v-if="selectedLead.visited_status === 'VISITED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <!--                                <v-divider class="mx-3" vertical></v-divider>-->

                          <!--                                <v-chip v-if="selectedLead.crm_status === 'CREATED'" class="custom_green_chip"-->
                          <!--                                        variant="outlined">-->
                          <!--                                  <v-icon>mdi-check-circle-outline</v-icon>&nbsp;CRM ENTRY &nbsp; {{-->
                          <!--                                    selectedLead.crm_status-->
                          <!--                                  }}-->
                          <!--                                </v-chip>-->

                          <!--                                <v-chip :v-if="selectedLead.crm_status === 'NOT CREATED'" class="custom_red_chip"-->
                          <!--                                        variant="outlined">-->
                          <!--                                  <v-icon>mdi-close</v-icon>&nbsp;CRM ENTRY &nbsp; {{ selectedLead.crm_status }}-->
                          <!--                                </v-chip>-->
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>


                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn
                          v-if="selectedLead.visited_status === 'NOT VISITED'"
                          class="mr-3 custom_edit_btn"
                          icon rounded size="small"
                          variant="outlined" @click="editItem(selectedLead)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        <v-tooltip activator="parent" location="top">Edit</v-tooltip>
                      </v-btn>

                      <v-btn
                          v-if="selectedLead.visited_status === 'NOT VISITED'"
                          class="custom_delete_btn" icon rounded
                          size="small" variant="outlined" @click="confirmDelete(selectedLead)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        <v-tooltip activator="parent" location="top">Delete</v-tooltip>
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>

                </v-row>

                <v-divider class="my-3"></v-divider>

                <v-row class="pt-6">
                  <v-col cols="6">

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-text-field
                          class="mb-2 "
                          label="Itinerary Title"
                          placeholder="Type title here"
                          readonly
                          v-model="selectedLead.title"
                          variant="outlined"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="Province"
                          readonly
                          v-model="selectedLead.province"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="District"
                          readonly
                          v-model="selectedLead.district"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="DSD"
                          readonly
                          v-model="selectedLead.dsd"
                      ></v-select>
                    </v-col>

                    <!--                        <v-col cols="12" xs="12" sm="12" md="12" lg="12">-->
                    <!--                          <v-textarea-->
                    <!--                                    v-model="selectedLead.description"-->
                    <!--                                    class="mb-2"-->
                    <!--                                    label="Itineraries Description"-->
                    <!--                                    placeholder="Write a description here..."-->
                    <!--                                    variant="outlined"-->
                    <!--                                    readonly-->

                    <!--                                ></v-textarea>-->

                    <!--                        </v-col>-->
                  </v-col>

                  <v-col cols="6">

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          variant="outlined"
                          class="mb-2"
                          label="GND"
                          readonly
                          v-model="selectedLead.gnd"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-text-field
                          variant="outlined"
                          class="mb-2"
                          label="Assign Officer"
                          readonly
                          v-model="selectedLead.assign_officer"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <!-- <v-text-field
                          variant="outlined"
                          label="Assign Date"
                          readonly
                          class="mb-2"
                          v-model="selectedLead.assign_date"
                      ></v-text-field> -->

                      <v-date-input v-model="assignDateObject" readonly
                                    label="Assign Date *" persistent-placeholder
                                    variant="outlined" density="comfortable" class="px-3 custom_input_field_color"
                                    prepend-icon="" prepend-inner-icon="$calendar"
                      ></v-date-input>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-divider></v-divider>
                      <h5 class="px-3 py-3">SELECTED ITINERARY LIST</h5>
                      <h6 class="px-3 pb-3 light-gray-text">he following table shows all the outlets (food city | KFC)
                        related to your
                        selected itinerary .</h6>
                      <v-card class="ma-3 mb-8 pa-3" height="100%" style="overflow-x: hidden; width: fit-content">
                        <v-divider></v-divider>
                        <v-data-table
                            :headers="headers_two"
                            :items="items_two"
                            class="data-table"
                            :search="search"
                        >
                          <template v-slot:[`item.outlet_type`]="{ item }">
                            <span class="red-text">{{ item.outlet_type }}</span>
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                class="mr-6 bg-green-lighten-4"
                                style="font-size: 9px"
                                color="green"
                                variant="outlined"
                                size="small"
                                @click="viewTableData(item)"
                            >
                              <v-card-text v-if="items_two === item" class="red-text">{{
                                  item.outlet_ttt
                                }}
                              </v-card-text>
                              <v-icon size="small" color="green">mdi-pencil</v-icon>
                              view
                            </v-btn>
                          </template>

                          <!-- Template for Status Column -->
                          <template v-slot:[`item.status`]="{ item }">
                            <!--                                <v-chip-->
                            <!--                                  :color="item.visited_status === 'VISITED' ? 'green' : 'red'"-->
                            <!--                                  dark-->
                            <!--                                  class="text-uppercase mt-3"-->
                            <!--                                  label-->
                            <!--                                  size="small"-->
                            <!--                                >-->
                            <!--                                  {{ item.visited_status }}-->
                            <!--                                  <v-icon :icon="item.visited_status === 'VISITED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>-->
                            <!--                                </v-chip>-->

                            <div style="padding: 3px">

                              <div style="padding-bottom: 3px">
                                <v-chip v-if="item.status === 'VISITED'" color="green" label
                                        style="height: 30px; width:auto; font-size: 12px;" class="bg-green-lighten-5">
                                  VISITED
                                  <v-icon class="justify-end ml-3">mdi-check</v-icon>
                                </v-chip>

                                <v-chip v-if="item.status === 'NOT VISITED'" class="bg-red-lighten-4" color="red" label
                                        style="height: 30px; width:auto; font-size: 12px;  ">
                                  NOT VISITED
                                  <v-icon class="justify-end ml-3">mdi-close</v-icon>
                                </v-chip>

                                <v-chip v-if="item.status === 'PENDING'" color="orange" label
                                        style="height: 30px; width:auto; font-size: 12px; background: lightgoldenrodyellow ">
                                  PENDING
                                  <v-icon class="justify-end ml-3">mdi-help-circle-outline</v-icon>
                                </v-chip>
                              </div>
                            </div>
                          </template>


                          <template v-slot:[`item.visited_datess`]="{ item }">
                            <span v-if="item.status === 'VISITED'">{{ item.visited_date.split(' ')[0] }}</span>
                            <!-- <v-btn v-else class="custom_btn_primary" @click="openReminderDialog(item)">REMINDER</v-btn> -->
                          </template>

                        </v-data-table>
                      </v-card>
                    </v-col>

                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>


        </v-card>
      </v-dialog>

      <!-- Edit LEAD DIALOG -->
      <v-dialog v-model="edit_leads_dialog" fullscreen id="app_lead_list">
        <v-card flat>

          <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <v-img class="custom_company_logo_nav" :src="require('@/assets/app_bar_logo.png')"
                     @click="homeReload()"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="closeEditDialog">
              CLOSE
            </v-btn>
          </v-toolbar>

          <v-row class="mt-15  custom_scroll_option_fullscreen_dialog">

            <v-col cols="12" md="12">
              <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
                <template v-slot:prepend>
                  <v-icon>mdi-view-dashboard</v-icon>
                  <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE ITINERARY DETAILS
                  </v-card-title>
                </template>
              </v-card>


              <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
                <v-row class="ma-0">
                  <v-col cols="12" xs="12" sm="4" md="9" lg="9">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="3" lg="2" xl="2">
                        <v-img
                            :src="selectedLead.officer.user_image ? `${imageURL}/${selectedLead.officer.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                            aspect-ratio="1" class="user_image_view"></v-img>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="9" lg="10" xl="10">
                        <div class="d-flex">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Officer Name:
                            &nbsp;</p>
                          <p class="font-weight-bold custom_page_component_main_title"
                             style="color: #05146F !important;">{{ selectedLead.assign_officer }}</p>
                        </div>

                        <div class="d-flex mt-1">
                          <p class="font-weight-bold custom_page_component_main_title text-body-1">Mobile: &nbsp;</p>
                          <p class="font-weight-medium text-body-2 custom_page_component_main_title mt-1">
                            {{ selectedLead.officer_mobile_numbers.map(num => num.mobile_number).join(', ') }}</p>
                        </div>

                        <div class="d-flex mt-6">
                          <v-chip v-if="selectedLead.visited_status === 'NOT VISITED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <v-chip v-if="selectedLead.visited_status === 'VISITED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;{{ selectedLead.visited_status }}
                          </v-chip>

                          <!-- <v-divider class="mx-3" vertical></v-divider> -->

                          <!-- <v-chip v-if="selectedLead.crm_status === 'CREATED'" class="custom_green_chip"
                                  variant="outlined">
                            <v-icon>mdi-check-circle-outline</v-icon>&nbsp;CRM ENTRY &nbsp; {{
                              selectedLead.crm_status
                            }}
                          </v-chip> -->

                          <!-- <v-chip :v-if="selectedLead.crm_status === 'NOT CREATED'" class="custom_red_chip"
                                  variant="outlined">
                            <v-icon>mdi-close</v-icon>&nbsp;CRM ENTRY &nbsp; {{ selectedLead.crm_status }}
                          </v-chip> -->
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                    <v-row class="text-right mt-3 mr-3">
                      <v-spacer></v-spacer>
                      <v-btn class="custom_btn_primary" size="large" variant="outlined"
                             :disabled="officerAvailability === 1" @click="updateItinerary()">
                        UPDATE
                        ITINERARY DATA
                      </v-btn>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>


                <v-row class="pt-6">
                  <v-col cols="6" md="6">

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-text-field
                          variant="outlined"
                          class="mb-2 "
                          label="Itinerary Title"
                          placeholder="Type title here"
                          v-model="selectedLead.title"

                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          readonly
                          class="mb-2"
                          label="Province"
                          variant="outlined"
                          placeholder="Select province"
                          v-model="selectedLead.province"
                          :items="provinces"
                          @update:modelValue="getAllDistrictAccProv"
                          item-text="label"
                          item-value="value"
                          :loading="mDistrictLoading"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          readonly
                          class="mb-2"
                          label="District"
                          variant="outlined"
                          placeholder="Select district"
                          v-model="selectedLead.district"
                          :items="districts"
                          @update:modelValue="getAllDsd"
                          item-text="label"
                          item-value="value"
                          :loading="mDistrictLoading"
                          :disabled="isDisabledDistrictList"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          readonly
                          class="mb-2"
                          label="Divisional"
                          variant="outlined"
                          placeholder="Select divisional"
                          v-model="selectedLead.dsd"
                          :items="dsdList"
                          item-text="label"
                          item-value="value"
                          @update:modelValue="getAllGnd"
                          :loading="mDSDLoading"
                          :disabled="isDisabledDSDLIST"
                      ></v-select>
                    </v-col>


                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-select
                          readonly
                          class="mb-2"
                          label="GND"
                          variant="outlined"
                          :items="gndList"
                          v-model="selectedLead.gnd"
                          :loading="mDSDLoading"
                          :disabled="isDisabledGNDList"
                          @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
                          placeholder="Select GND"

                      ></v-select>
                    </v-col>

                    <!--                  <v-col cols="12" xs="12" sm="12" md="12" lg="12">-->
                    <!--                    <v-textarea-->

                    <!--                              v-model="selectedLead.description"-->
                    <!--                              class="mb-2"-->
                    <!--                              label="Itineraries Description"-->
                    <!--                              placeholder="Write a description here..."-->
                    <!--                              variant="outlined"-->

                    <!--                          ></v-textarea>-->
                    <!--                  </v-col>-->
                  </v-col>

                  <v-col cols="6" class="px-6">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <!-- <v-text-field
                          readonly
                          variant="outlined"
                          class="mb-2"
                          label="Assign Officer"
                          v-model="selectedLead.assign_officer"
                      ></v-text-field> -->

                      <v-autocomplete
                          class="mt-2 px-3 custom_input_field_color"
                          density="comfortable"
                          v-model="selectedLead.assign_officer"
                          :disabled="selectedLead.visited_status === 'VISITED'"
                          :items="officerDetails"
                          item-title="name"
                          item-value="name"
                          label="Assign Agent *"
                          placeholder="Select agent"
                          variant="outlined"
                          @update:modelValue="checkOfficerAvailability"
                      ></v-autocomplete>

                      <v-alert v-if="officerAvailabilityError" type="error" dense class="mx-3">
                        {{ officerAvailabilityError }}
                      </v-alert>

                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <!-- <v-text-field
                          readonly
                          variant="outlined"
                          label="Assign Date"
                          class="mb-2"
                          v-model="selectedLead.assign_date"
                      ></v-text-field> -->


                      <v-date-input
                          v-model="assignDateObject"
                          :disabled="selectedLead.visited_status === 'VISITED'"
                          label="Assign Date *"
                          persistent-placeholder
                          variant="outlined"
                          density="comfortable"
                          class="px-3 custom_input_field_color"
                          prepend-icon=""
                          prepend-inner-icon="$calendar"
                          @update:modelValue="checkOfficerAvailability"
                      ></v-date-input>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-divider></v-divider>
                      <h5 class="px-3 py-3">SELECTED ITINERARY LIST</h5>
                      <h6 class="px-3 pb-3 light-gray-text">The following table shows all the outlets (food city | KFC)
                        related to your
                        selected itinerary </h6>

                      <v-divider></v-divider>
                      <v-data-table
                          :headers="headers_two"
                          :items="items_two"
                          class="data-table"
                          :search="search"
                      >
                        <template v-slot:[`item.outlet_type`]="{ item }">
                          <span class="red-text">{{ item.outlet_type }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                              class="mr-6 bg-green-lighten-4"
                              style="font-size: 9px"
                              color="green"
                              variant="outlined"
                              size="small"
                              @click="viewTableData(item)"
                          >
                            <v-card-text v-if="items_two === item" class="red-text">{{ item.outlet_ttt }}</v-card-text>
                            <v-icon size="small" color="green">mdi-pencil</v-icon>
                            view
                          </v-btn>
                        </template>

                        <!-- Template for Status Column -->
                        <template v-slot:[`item.status`]="{ item }">


                          <div style="padding: 3px">

                            <div style="padding-bottom: 3px">
                              <v-chip v-if="item.status === 'VISITED'" color="green" label
                                      style="height: 30px; width:auto; font-size: 12px;" class="bg-green-lighten-5">
                                VISITED
                                <v-icon class="justify-end ml-3">mdi-check</v-icon>
                              </v-chip>

                              <v-chip v-if="item.status === 'NOT VISITED'" class="bg-red-lighten-4" color="red" label
                                      style="height: 30px; width:auto; font-size: 12px;  ">
                                NOT VISITED
                                <v-icon class="justify-end ml-3">mdi-close</v-icon>
                              </v-chip>

                              <v-chip v-if="item.status === 'PENDING'" color="orange" label
                                      style="height: 30px; width:auto; font-size: 12px; background: lightgoldenrodyellow ">
                                PENDING
                                <v-icon class="justify-end ml-3">mdi-help-circle-outline</v-icon>
                              </v-chip>
                            </div>
                          </div>
                        </template>

                      </v-data-table>

                    </v-col>


                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>

        </v-card>
      </v-dialog>

      <!-- Send reminder for not visited records -->
      <v-dialog v-model="reminderDialog" max-width="600" max-height="800" persistent>
        <v-card>
          <v-row dense class="rowClr mb-1 mt-3 mx-3">
            <v-col cols="12" md="9">
              <v-card-title class="headline">UPLOADED DETAILS</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="reminderDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3"></v-divider>


          <v-btn width="40vw" height="5vh" @click="toggleCard('imageCard')">UPLOAD IMAGES</v-btn>

          <!-- Image Card -->
          <v-card
              v-if="imageCard"
              style="margin-top: 8px; margin-bottom: 5px; margin-left: 15px; margin-right: 15px; padding: 10px; max-height: 400px; overflow-y: auto"
              variant="elevated"
          >
            <v-row dense>
              <v-col
                  v-for="(image, index) in imageUrls"
                  :key="index"
                  cols="4"
                  class="mb-2"
              >
                <v-card
                    width="100%"
                    height="150px"
                    class="d-flex justify-center align-center mt-6"
                >
                  <v-img
                      :src="imageURLForOutletImage + '/' + image.image"
                      width="100%"
                      height="100%"
                      class="rounded"
                      alt="Uploaded Image"
                      contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-btn width="40vw" height="5vh" @click="toggleCard('checkList')">CHECKLIST</v-btn>


          <!-- Checklist Card -->
          <v-card
              v-if="checkList"
              style="margin: 20px; padding: 10px; max-height: 400px; overflow-y: auto;"
              variant="outlined"
          >
            <!-- Checklist Section -->
            <v-card class="mb-4">
              <v-row>
                <v-col cols="4" v-for="(section, index) in selectedSection" :key="index">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          color="red"
                          class="bg-red-lighten-4 text-wrap"
                          :variant="activeSection === section ? 'flat' : 'text'"
                          @click="setActiveSection(section)"
                          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"
                          v-on="on"
                      >
                        {{ section }}
                      </v-btn>
                    </template>
                    <span>{{ section }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>

            <!-- Checklist Items -->
            <v-list v-if="activeSection">
              <v-list-item
                  v-for="(item, index) in filteredChecklistItems"
                  :key="index"
              >
                <v-card
                    class="mb-2 bg-red-lighten-5"
                    height="7vh"
                    style="font-size: 12px"
                    color="red"
                >
                  <v-row align="center">
                    <v-col cols="8">
                      <v-list-item-title class="ma-3 pa-2">{{ item.item_name }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-card>
              </v-list-item>
            </v-list>
          </v-card>

          <v-divider class="mb-2"></v-divider>
          <v-card-actions class="mr-1">
            <v-spacer></v-spacer>
            &nbsp; &nbsp;<v-btn @click="reminderDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL
          </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteLeadDialog" max-width="500" max-height="500" persistent>
        <v-card class="pa-1">
          <v-row dense class="rowClr ma-1">
            <v-col cols="12" md="9">
              <v-card-title class="headline">DELETE ITINERARY</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="deleteLeadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2">
            <v-icon class="mr-2" color="orange">mdi-alert</v-icon>
            Do you want to delete this itinerary ?
          </v-card-text>
          <v-card-text class="textspace">
            This itinerary will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp;
            &nbsp;
            <v-btn @click="deleteLeadDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteLeadListEditDialog" max-width="500" max-height="500" persistent>
        <v-card class="pa-1">
          <v-row dense class="rowClr ma-1">
            <v-col cols="12" md="9">
              <v-card-title class="headline">DELETE ITINERARY</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="deleteLeadListEditDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2">
            <v-icon class="mr-2" color="orange">mdi-alert</v-icon>
            Do you want to delete this itinerary ?
          </v-card-text>
          <v-card-text class="textspace">
            This itinerary will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="deleteLeadsListItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp;
            &nbsp;
            <v-btn @click="deleteLeadListEditDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- sms dialog -->
      <v-dialog v-model="smsDialog" max-width="400" max-height="600" persistent>
        <v-card class="pa-1">
          <v-row dense class="rowClr mb-1 mt-3 mx-3">
            <v-col cols="12" md="9">
              <v-card-title class="headline">SEND MESSAGE</v-card-title>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
              <v-btn icon size="x-small" @click="smsDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-text-field
              class="mb-1 mt-3 mx-3"
              label="Mobile"
              variant="outlined"
              placeholder="Mobile number"
              v-model="selectedItem.mobile_number"
              :rules="[v => !!v || 'Mobile number is required']"
              required
          ></v-text-field>

          <v-text-field
              class="mb-1 mx-3"
              label="Email"
              variant="outlined"
              placeholder="Email address"
              v-model="selectedItem.email"
              :rules="[v => !!v || 'Email is required']"
              required
          ></v-text-field>

          <v-textarea
              class="mb-1 mx-3"
              label="Note"
              variant="outlined"
              placeholder="Type your massage here..."
              v-model="selectedItem.note"
              :rules="[v => !!v || 'Note is required']"
              required
          ></v-textarea>

          <v-divider class="mb-3"></v-divider>
          <v-card-actions class="mr-1">
            <v-spacer></v-spacer>
            <v-btn color="red" @click="sendSMSSubmit" variant="outlined" class="bg-red-lighten-4">SEND</v-btn>&nbsp;
            &nbsp;
            <v-btn @click="smsDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Snackbar to show notifications -->
      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :top="snackAlert.snackBarTop"
          :right="snackAlert.snackBarRight"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>

    </v-container>
  </v-app>


</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";

export default {
  components: {},
  name: 'LeadsList',
  data() {
    return {
      officerAvailabilityError: '',
      officerAvailability: 0,
      isInitialDate: null,
      isInitialOfficerID: null,
      officerDetails: [],
      imageURL: '',
      imageURLForOutletImage: '',
      selectedLeadEdit: {
        assign_officer: '',
        mobile_number: '',
        visited_status: '',
        title: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        description: '',
        assign_date: '',
      },

      officer: [],
      agentList: [],

      activeSection: null,
      smsMobile: '',
      smsEmail: '',
      smsNote: '',

      imageCard: '',
      checkList: '',
      selectedTab: 'uploadedImages',

      selectedSection: [],
      imageUrls: [],
      checklistItems: [],
      checkListData: [],

      status: [],

      headersItenaray: [
        {text: 'Officer Name', value: 'assign_officer', sortable: true, title: 'Officer Name'},
        {text: 'Officer Visited Status', value: 'visited_status', sortable: true, title: 'Officer Visited Status'},
        {text: 'Assign Date', value: 'assign_date', sortable: true, title: 'Assign Date'},
        {text: 'Action', value: 'actions', sortable: false, title: 'Action'}
      ],
      tableLoading: false,
      items: [],
      // items: [], // Items fetched from API
      totalItems: 5, // Set the correct total number of items
      page: 1,
      itemsPerPage: 10,
      filters: {
        startDate: '',
        endDate: '',
        visitedStatus: [],
        crm_status: []
      },
      visitStatus: ['VISITED', 'NOT VISITED', 'PENDING'],
      CRMstatus: ['COMPLETED', 'NOT COMPLETED'],


      view_leads_dialog: false,

      edit_leads_dialog: false,
      assignDateObject: new Date(),

      deleteLeadDialog: false,
      deleteLeadListEditDialog: false,

      reminderDialog: false,

      selectedItem: {},

      smsDialog: false,
      dates: [],

      //addleads
      lead: {
        title: '',
        mainCategory: '',
        sub_category: '',
        province: '',
        district: '',
        description: '',
        dsd: '',
        gnd: '',
        assignAgent: '',
        assign_date: '',
      },

      file: null,

      headersTwoT: [
        {text: '', value: 'data-table-select', title: 'data-table-select'},
        {text: 'Sub Category', value: 'subCategory', sortable: true, title: 'Sub Category'},
        {text: 'Institute Name', value: 'instituteName', sortable: true, title: 'Institute Name'},
        {text: 'Address', value: 'address', sortable: true, title: 'Address'},
      ],

      // add leads
      headers_two: [
        // { text: '', value: 'data-table-select', title: 'data-table-select' },
        {text: 'Outlet name', value: 'institute_name', sortable: true, title: 'Outlet name'},
        {text: 'Address', value: 'address', sortable: true, title: 'Address'},
        {text: 'Status', value: 'status', sortable: true, title: 'Status'},
        {text: 'Visited Date', value: 'visited_date', sortable: true, title: 'Visited Date'},
        {text: 'Action', value: 'actions', sortable: false, title: 'Action'}
      ],
      // items_two: [],
      items_two: [],

      showLeadsCard: true,

      // add leads bulk upload
      headers_dialog: [
        {text: 'Main Category', value: 'mainCategory', title: 'Main Category'},
        {text: 'Sub Category', value: 'sub_category', title: 'Sub Category'},
        {text: 'Province', value: 'province', title: 'Province'},
        {text: 'District', value: 'district', title: 'District'},
        {text: 'DSD', value: 'dsd', title: 'DSD'},
        {text: 'GND', value: 'gnd', title: 'GND'},
        {text: 'Assign Officer', value: 'assignAgent', title: 'Assign Officer'},
        {text: 'Assign Date', value: 'assign_date', title: 'Assign Date'},
        {text: 'Description', value: 'description', title: 'Description'},
        {text: 'Lead List', value: 'leadList', title: 'Lead List'},
      ],
      // items_dialog: [],
      items_dialog: [],

      //search
      search: '',

      // Dialog state
      leadsBulkUploadDialog: false,

      //select date
      assignDateMenu: false,

      selectedLeadId: null,
      selectedLead: null, // Store current lead details


      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      selectedCategory: null,
      selectAll: false,

      mainFilterLoading: false,
      subFilterLoading: false,

      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],


      isDisabledProvinceList: false,
      isDisabledDistrictList: false,
      isDisabledDSDLIST: false,
      isDisabledGNDList: false,

      selectedDistrict: [],
      selectedProvince: "",
      selectedDSD: [],
      selectedGND: [],

      agents: [],

      categories: [],
      subCategories: [],
      allCategories: [],
      itineraryList: [],
    };
  },
  watch: {

    'selectedLead.assign_officer'() {
      this.checkOfficerAvailability();
    },
    assignDateObject() {
      this.checkOfficerAvailability();
    },

    'selectedLead.lead_main_category': function (newValue) {
      // Example of using newValue
      console.log('Main Category changed to:', newValue);
      this.fetchLeads();
    },
    'selectedLead.lead_sub_category': function (newValue) {
      console.log('Sub Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.province': function (newValue) {
      console.log('Province changed to:', newValue);
      this.fetchLeads();
    },
    'lead.district': function (newValue) {
      console.log('District changed to:', newValue);
      this.fetchLeads();
    },
    'lead.dsd': function (newValue) {
      console.log('DSD changed to:', newValue);
      this.fetchLeads();
    },
    'lead.gnd': function (newValue) {
      console.log('GND changed to:', newValue);
      this.fetchLeads();
    }
  },

  computed: {

    processedItineraryList() {
      return this.itineraryList
          .map(item => {
            // Extract statuses and visited dates
            const statuses = item.itenerary_list?.map(subItem => subItem.status) || [];
            const visitedDates = item.itenerary_list
                ?.filter(subItem => subItem.status === "VISITED")
                .map(subItem => subItem.visited_date) || [];

            let visitedStatus = "NOT VISITED"; // Default
            let visitedDate = null; // Default no date

            if (statuses.includes("VISITED") && statuses.includes("NOT VISITED")) {
              visitedStatus = "PENDING";
            } else if (statuses.every(status => status === "VISITED")) {
              visitedStatus = "VISITED";
            }

            // If visitedDates exist, pick the first (earliest) or last (latest) date
            if (visitedDates.length > 0) {
              visitedDate = visitedDates[0]; // Earliest visited date
              // visitedDate = visitedDates[visitedDates.length - 1]; // Latest visited date (optional)
            }

            return {
              ...item,
              visited_status: visitedStatus, // Add computed status
              visited_date: visitedDate // Add computed visited date
            };
          })
          .filter(item => {
            const formatDate = (date) => {
              if (!date) return null;
              const d = new Date(date);
              return d.toISOString().split("T")[0];
            };

            // Ensure proper date filtering
            const startDate = this.dates.length ? formatDate(this.dates[0]) : null;
            const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;
            const isWithinDateRange =
                !startDate ||
                !endDate ||
                (item.assign_date &&
                    formatDate(item.assign_date) >= startDate &&
                    formatDate(item.assign_date) <= endDate);

            // Ensure filtering by assigned officer
            const isMatchingOfficer =
                !this.officer.length || this.officer.includes(item.assign_officer);

            // Ensure filtering by visited_status (user selection)
            const isMatchingStatus =
                !this.filters.visitedStatus.length ||
                this.filters.visitedStatus.includes(item.visited_status);

            return isWithinDateRange && isMatchingOfficer && isMatchingStatus;
          });
    },


    filteredItems() {
      let filtered = this.items;
      // eslint-disable-next-line no-empty
      if (this.filters.startDate && this.filters.endDate) {
      }

      if (this.filters.visitedStatus.length) {
        filtered = filtered.filter(item =>
            this.filters.visitedStatus.includes(item.visited_status)
        );
      }

      if (this.filters.crm_status.length) {
        filtered = filtered.filter(item =>
            this.filters.crm_status.includes(item.crm_status)
        );
      }

      return filtered;
    },

    filteredChecklistItems() {
      // Return only items matching the active section
      return this.checklistItems.filter(item => item.main_category === this.activeSection);
    }

  },

  created() {
    this.fetchDataintoTable();
    this.getItineraryList();
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
    this.imageURLForOutletImage = process.env.VUE_APP_IMAGE_URL;
    this.isInitialDate = null;
    this.isInitialOfficerID = null;
  },

  mounted() {
    this.fetchMainCategories();
    this.getAllProvinces();
    this.fetchAllAgents();
    this.getItineraryList();
    this.getOfficerDetails();
    // this.loadLeadsList('All','province',null);
    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;
    this.imageURLForOutletImage = process.env.VUE_APP_IMAGE_URL;
  },

  methods: {

    async checkOfficerAvailability() {
      console.log("Officer name", this.selectedLead.assign_officer + "ID", this.officerDetails);

      // Assuming this.selectedLead.assign_officer contains the user_name of the officer
      const assignedOfficerUserName = this.selectedLead.assign_officer;

      const assignedOfficer = this.officerDetails.find(officer => officer.name === assignedOfficerUserName);

      if (assignedOfficer) {
        // Print the id and name of the assigned officer
        console.log("Officer ID:", assignedOfficer.id, "Officer Name:", assignedOfficer.name);
      } else {
        // Handle the case where no officer was found
        console.log("No officer found with the user_name:", assignedOfficerUserName);
        return; // Exit the function early if no officer is found
      }

      if (this.selectedLead.assign_officer && this.assignDateObject) {
        const date = new Date(this.assignDateObject);
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
        const formattedDate = adjustedDate.toISOString().split('T')[0];

        // Set only if null (initial assignment)
        if (this.isInitialDate === null) {
          this.isInitialDate = formattedDate;
        }
        if (this.isInitialOfficerID === null) {
          const assignedOfficerUserName = this.selectedLead.assign_officer;
          const assignedOfficer = this.officerDetails.find(officer => officer.user_name === assignedOfficerUserName);

          // Check if assignedOfficer is defined before accessing its id
          if (assignedOfficer) {
            this.isInitialOfficerID = assignedOfficer.id;
          } else {
            console.error("No officer found with the user_name:", assignedOfficerUserName);
            return; // Exit the function early if no officer is found
          }
        }

        if (this.isInitialDate === formattedDate && this.isInitialOfficerID === this.selectedLead.officer_id) {
          console.log("hello");
          return 0;
        } else {
          try {
            const response = await axios.post('/api/checkOfficerAvailable', {
              officerID: assignedOfficer.id,
              assigndata: formattedDate
            });

            console.log('checkOfficerAvailable', response.data);

            if (response.data.status === 201) {
              this.officerAvailabilityError = response.data.message;
              this.officerAvailability = 1;
              console.log("check ", this.officerAvailabilityError);
            } else {
              this.officerAvailabilityError = '';
              this.officerAvailability = 0;
            }
          } catch (error) {
            console.error('Error checking officer availability:', error);
            this.officerAvailabilityError = 'Error checking officer availability.';
          }
        }
      } else {
        this.officerAvailabilityError = '';
      }
    },

    // Get Officer Deteils
    getOfficerDetails() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);
      // formData.append("dsd", this.lead.dsd);

      axios.post('/api/getAllOfficer', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }

      })
          .then(response => {
            if (response.data.http_status === 'success') {

              this.officerDetails = response.data.data;
              console.log("officer", response.data.data);

            }
          })
          .catch(() => {
            console.log("fk2");
          });

    },


    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);

      // Adjust for timezone offset
      const offset = d.getTimezoneOffset(); // Get the timezone offset in minutes
      d.setMinutes(d.getMinutes() - offset); // Adjust the date to account for the offset

      return d.toISOString().split('T')[0]; // Extracts YYYY-MM-DD
    },
    setActiveSection(section) {
      this.activeSection = section;

    },
    closeEditDialog() {
      this.officerAvailabilityError = "";
      this.officerAvailability = '';
      this.edit_leads_dialog = false

      // this.isInitialDate = null;
      // this.isInitialOfficerID = null;
    },

    viewTableData(item) {
      this.checklistItems = [];
      this.selectedSection = [];
      this.reminderDialog = true;
      this.checklistItems = item.itenerary_check_list;
      this.imageUrls = item.itenerary_list_image;

      console.log("imageUrls isss", this.imageUrls)
      // this.selectedSection = item.itenerary_check_list.main_category

      this.checklistItems.forEach(checklistItem => {
        if (checklistItem.main_category && !this.selectedSection.includes(checklistItem.main_category)) {
          this.selectedSection.push(checklistItem.main_category);
        }
      });


      console.log('ssssssss', this.checklistItems)
      console.log('rrrrrrrr', this.selectedSection)
    },


    getItineraryList() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      this.tableLoading = true;
      const formData = new FormData();
      formData.append("userID", userId);

      axios.post('/api/getItinararyDetailsTotheWeb', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.itineraryList = response.data.data;
            this.tableLoading = false;

            this.statusCheck();

            // Use a Set to store unique names
            const uniqueAgents = new Set();

            this.itineraryList.forEach(item => {
              if (!uniqueAgents.has(item.assign_officer)) {
                uniqueAgents.add(item.assign_officer);
                this.agentList.push({
                  id: item.id,
                  name: item.assign_officer,
                });
              }
            });

          })
          .catch(() => {
            console.log("fk2");
          });
    },

    clearDateRange() {
      this.dates = [];
    },

    view_leads() {
      this.view_leads_dialog = false;
      this.isInitialDate = null;
      this.isInitialOfficerID = null;
    },


    statusCheck() {
      let hasNotVisited = false;

      // Iterate through each itinerary item
      for (const item of this.itineraryList) {
        // Check if the status is "not_visited"
        if (item.status === 'NOT VISITED"') {
          hasNotVisited = true;
          break; // Exit loop early if a not_visited item is found
        }


      }

      // View based on the presence of "not_visited" items
      if (hasNotVisited) {

        this.checkStaus = "Not Visited"
        console.log('There are items not visited.');
        // You can perform any actions needed for "not_visited" state
      } else {
        this.checkStaus = "Visited"
        console.log('All items are visited.');
        // You can perform any actions needed for "visited" state
      }
    },


    toggleCard(cardType) {

      if (cardType === 'imageCard') {
        this.imageCard = !this.imageCard;
      } else if (cardType === 'checkList') {
        this.checkList = !this.checkList;
      }
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
      const tableData = this.processedItineraryList.map((item) => ({
        'Officer Name': item.assign_officer,
        'Officer Visited Status': item.visited_status,
        'Assign Date': item.assign_date,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Itenarary');


      XLSX.writeFile(workbook, 'itinerary_analysis.xlsx');
    },

    createItenarary() {
      this.$router.push({path: '/add-itinerary'});
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Itinarary Analysis', 14, 10);

      // Prepare the data for the table
      const tableData = this.processedItineraryList.map((item, index) => [
        index + 1,
        item.assign_officer,
        item.visited_status,
        item.assign_date,
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Officer Name', 'Officer Visited Status', 'Assign Date'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('itinerary_analysis.pdf');

    },

    toggleSelectAll() {
      this.filteredItems.forEach(item => {
        item.selected = this.selectAll;
      });
    },

    confirmDeleteList() {
      this.deleteLeadListEditDialog = true;
      // Perform delete operation here
    },

    deleteLeadsListItem() {
      // Then update card visibility
      this.showLeadsCard = false;
      this.deleteLeadListEditDialog = false;
    },

    // Call this method whenever mainCategory, subCategory, or location fields change
    async fetchLeads() {
      await this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
    },

    async loadLeadsList(mainCategory, subCategory) {
      try {
        // Determine the location_type and location values based on selected values
        let locationType = '';
        let locationValue = [];

        if (this.lead.gnd && this.lead.gnd.length > 0) {
          locationType = 'gnd';
          locationValue = [this.lead.gnd];
        } else if (this.lead.dsd && this.lead.dsd.length > 0) {
          locationType = 'dsd';
          locationValue = [this.lead.dsd];
        } else if (this.lead.district && this.lead.district.length > 0) {
          locationType = 'district';
          locationValue = [this.lead.district];
        } else if (this.lead.province && this.lead.province !== "") {
          locationType = 'province';
          locationValue = [this.lead.province];
        }
        // Construct the payload
        const payload = {
          type: 'Spatial',
          country: 'SriLanka',
          district: locationType === 'district' ? locationValue : [],
          province: locationType === 'province' ? locationValue : [],
          dsd: locationType === 'dsd' ? locationValue : '',
          gnd: locationType === 'gnd' ? locationValue : '',
          location_type: locationType,
          main_filter: mainCategory,
          sub_filter: subCategory
        };


        // Make the API call
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getGISData', payload);

        console.log('table data for leads:', payload);
        if (response.data.http_status === 'success') {
          console.log('table data for leads two:', payload);
          // Map the response data to your table structure
          // this.filteredItems = response.data.data.map(item => ({
          //   subCategory: item.Type,
          //   instituteName: item.Name,
          //   address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`
          // }));

          this.items = response.data.data.map(item => ({
            subCategory: item.Type,
            instituteName: item.Name,
            address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`,
            selected: false // Add a default value for selection
          }));
        } else {
          console.error("Failed to load leads:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },


    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/agentUser', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        }
      })
          .then(response => {
            console.log('Full API Response:', response.data); // ✅ Debug here

            let sample = response.data.data;

            if (!Array.isArray(sample)) {
              console.error("Error: 'data' is not an array", sample);
              sample = []; // Set an empty array to prevent errors
            }

            this.agents = sample.map(item => ({
              id: item.id,
              name: item.name
            }));

            console.log('Agents:', this.agents);
          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });

    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;
              this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
              console.log('main c:', this.selectedLead.lead_main_category);
              console.log('sub c:', this.selectedLead.lead_sub_category);
            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
              this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
              console.log('main c:', this.selectedLead.lead_main_category);
              console.log('sub c:', this.selectedLead.lead_sub_category);
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
              this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
              console.log('main c:', this.selectedLead.lead_main_category);
              console.log('sub c:', this.selectedLead.lead_sub_category);
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');

          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
              .filter(val => val.spatial_type !== 'Point')
              .map(val => ({
                data: null,
                name: val.sub_filter,
                main_type: val.main_filter,
                sub_directory: val.data_model
              }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset) {
      // if (filter === 'main'){
      this.isDisabledDistrictList = false;
      this.isDisabledDSDLIST = false;
      this.isDisabledGNDList = false;
      this.lead.province = null;
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      // if (selectedLead.lead_sub_category) {
      //   this.selectedLead.lead_sub_category="";
      // }
      // if (selectedLeadEdit.lead_sub_category) {
      this.selectedLeadEdit.lead_sub_category = "";
      // }


      // Debugging: Check the input parameters
      // console.log('Filter:', filter);
      console.log('Dataset:', dataset);

      // Ensure that `this.allCategories` is correctly populated
      console.log('All Categories:', this.allCategories);

      let sub_filter = _.map(this.allCategories, function (o) {
        if (o.main_filter === dataset)
          return o.sub_filter
      });

      sub_filter = _.reject(sub_filter, _.isNil);
      this.subCategories = sub_filter.sort();

      // Debugging: Verify the final result
      console.log('Sub Categories:', this.subCategories);

    },

    fetchDataintoTable() {
      // const userId = localStorage.getItem('userId');
      // const token = localStorage.getItem("token");
      //
      // const formData = new FormData();
      // formData.append("userId", userId);
      //
      // axios.post("/api/getLeadList", formData, {
      //   headers: {
      //       'Authorization': `Bearer ${token}`
      //   }
      //   }).then((response) => {
      //
      //     this.items = response.data.data.map(lead => {
      //       // Parse the assign_date and format it
      //       // const assignDate = new Date(lead.assign_date);
      //       // const formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;
      //
      //       let formattedDate = null;
      //       if (lead.assign_date) {
      //         const assignDate = new Date(lead.assign_date);
      //         formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;
      //       }
      //       this.filterDataByDateRange();
      //
      //       // Return a new object with the formatted date
      //       return {
      //         ...lead,
      //         assign_date: formattedDate
      //       };
      //
      //     }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at descending
      //             // this.items= response.data.data;
      //             // // this.totalItems = response.data.totalItems;
      //             console.log('lead list data',this.items);
      //           })
      //           .catch((error) => {
      //
      //
      //               console.log(error);
      //
      //           });
    },

    backPageRoute() {
      this.$router.back();
    },
    homeReload() {
      window.location.href = '/dashboard';
    },

    viewItem(item) {

      this.selectedLead = item;
      this.selectedLead.mobileNumber = item.officer_mobile_numbers.length > 0 ? item.officer_mobile_numbers[0].officer_mobile_numbers : null;
      this.view_leads_dialog = true;
      console.log('View item:', item);
      this.items_two = item.itenerary_list;
      this.assignDateObject = new Date(this.selectedLead.assign_date);
      console.log('View itemmmmmmmmm:', this.items_two);

    },
    editItem(item) {

      this.selectedLead = item;
      this.selectedLead.mobileNumber = item.officer_mobile_numbers.length > 0 ? item.officer_mobile_numbers[0].officer_mobile_numbers : null;
      this.edit_leads_dialog = true;
      this.officerAvailabilityError = "";
      this.officerAvailability = '';

      this.assignDateObject = new Date(this.selectedLead.assign_date);

      console.log('View item:', item);
      this.items_two = item.itenerary_list;
      console.log('View itemmmmmmmmm:', this.items_two);
    },

    sendSMSSubmit() {
      // Validate required fields
      if (!this.selectedItem.mobile_number || !this.selectedItem.email || !this.selectedItem.note) {
        // Show snackbar message
        this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackVisible = true;
        return; // Stop the function execution if validation fails
      }
      // Extract the required data
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const sendSMSTo = {
        mobileNumber: this.selectedItem.mobile_number,
        email: this.selectedItem.email,
        note: this.selectedItem.note,
        userID: userId
      };
      // Log the reminder data
      console.log("SMS sent to:", this.selectedItem);

      // Make API call using axios
      axios.post('/api/message', sendSMSTo, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            console.log('Success:', response.data);
            // Handle success response if needed
            this.smsDialog = false;
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle error response if needed
          });
    },


    updateItinerary() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      if (!this.selectedLead.title || !/^[a-zA-Z0-9\s]+$/.test(this.selectedLead.title)) {
        this.showSnackbar('Itinerary Title is required and should contain only number and letters and spaces.', 'error');
        return false;
      }

      if (this.officerAvailabilityError) {
        this.showSnackbar('Officer already has a task please change officer or date.', 'error');
        return false;
      }

      // const date = new Date(this.selectedLead.assignDate).toISOString().split('T')[0];

      const formData = new FormData();
      formData.append("userID", userId);
      formData.append("title", this.selectedLead.title);
      formData.append("officerID", this.selectedLead.officer_id);
      formData.append("description", this.selectedLead.description);
      formData.append("ItineraryID", this.selectedLead.id);

      formData.append("province", this.selectedLead.province);
      formData.append("district", this.selectedLead.district);
      formData.append("dsd", this.selectedLead.dsd);
      formData.append("gnd", this.selectedLead.gnd);

      const date = new Date(this.assignDateObject);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const assignDate = date.toISOString().split('T')[0];

      formData.append("assigndata", assignDate);

      formData.append("iteneraryList", JSON.stringify(this.items_two));

      axios.post('/api/addItinararyWeb', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }

      })
          .then(response => {
            console.log('Itinerary added successfully!:', response.data);
            this.showSnackbar("Itinerary updated successfully!", "success");
            this.getItineraryList();
            this.addItinararyLoading = false;
            this.edit_leads_dialog = false;
            this.officerAvailabilityError = '';


          })
          .catch(error => {
            console.error('Error adding lead:', error);
            this.showSnackbar("Error!", "error");
            setTimeout(() => {
              this.addItinararyLoading = false;

            }, 3000);

          });

    },


    openReminderDialog(item) {
      this.selectedItem = {...item};
      console.log("Opening reminder dialog for item:", item);
      this.reminderDialog = true;
    },

    sendReminder() {
      // Validate required fields
      if (
          !this.selectedItem.lead_title ||
          !this.selectedItem.institute_name ||
          !this.selectedItem.address ||
          !this.selectedItem.agent_name ||
          !this.selectedItem.mobile_number ||
          !this.selectedItem.email ||
          !this.selectedItem.note
      ) {
        // Show snackbar message
        this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackVisible = true;
        return; // Stop the function execution if validation fails
      }
      // Extract the required data
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const reminderData = {
        title: this.selectedItem.lead_title,
        outletName: this.selectedItem.institute_name,
        outletAddress: this.selectedItem.address,
        assignee: this.selectedItem.agent_name,
        mobileNumber: this.selectedItem.mobile_number,
        email: this.selectedItem.email,
        note: this.selectedItem.note,
        userID: userId
      };

      // Log the reminder data
      console.log("Reminder sent to:", this.selectedItem);

      // Make API call using axios
      axios.post('/api/reminder', reminderData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            console.log('Success:', response.data);
            // Handle success response if needed
            this.reminderDialog = false;
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle error response if needed
          });
    },

    // Opens the delete confirmation dialog and sets the selectedLeadId
    confirmDelete(item) {
      this.selectedLeadId = item.id; // Save the ID of the record to delete
      this.deleteLeadDialog = true; // Show the confirmation dialog
    },

    // Deletes the selected lead
    deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const leadId = this.selectedLeadId;
      console.log('lead id', leadId);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteItenarary`, {
        userId: userId,
        ItineraryID: leadId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.deleteLeadDialog = false;
            this.showSnackbar("Itinerary deleted successfully!", "success");
            this.getItineraryList();
            this.view_leads_dialog = false;
          })
          .catch((error) => {
            console.log('Error deleting lead', error);
            this.deleteLeadDialog = false;
            this.getItineraryList();
          });
    },
  }
};
</script>

<style>
@import "../../styles/lead_management/leads_list.scss";

</style>
    
<template>
   <v-app id="app_outlets_list">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">OUTLET LISTS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse the created outlet list using a variety of
            parameters.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">


        <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-row class="pa-5">
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutlet"
                  :items="outletTypes"
                  chips
                  item-title="outlet_name"
                  item-value="id"
                  label="Select Outlet"
                  multiple
                  variant="outlined"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutletStatus"
                  :items="outletSates"
                  chips
                  item-title="outlet_status"
                  item-value="outlet_status"
                  label="Outlet Status"
                  multiple
                  variant="outlined"
              ></v-select>
            </v-col>

          </v-row>
     

      <v-data-table
          :headers="headers"
          :items="filteredRoutes"
          :items-per-page="5"
          :search="search"
          :loading="tableLoading"
          class="custom_table_normal"
          style="padding-left: 25px; padding-right: 10px;  padding-bottom: 30px; height: fit-content; margin-bottom: 20px "
      >
        <template v-slot:[`item.outlet_status`]="{ item }">
          <div style="padding: 10px">

            <div style="padding-bottom: 3px">
              <v-chip v-if="item.outlet_status === 'ALLOWED'" color="green" label
                      style="height: 20px; width:140px; font-size: 12px; background: palegreen;">
                ALLOWED
                <v-icon class="justify-end ml-12">mdi-check</v-icon>
              </v-chip>

              <v-chip v-if="item.outlet_status === 'NOT ALLOWED'" class="bg-red-lighten-4" color="red" label
                      style="height: 20px; width:140px; font-size: 12px;  ">
                NOT ALLOWED
                <v-icon class="justify-end ml-6">mdi-close</v-icon>
              </v-chip>

              <v-chip v-if="item.outlet_status === 'NEED APPROVAL'" color="orange" label
                      style="height: 20px; width:140px; font-size: 12px; background: lightgoldenrodyellow ">
                NEED APPROVAL
                <v-icon class="justify-end ml-4">mdi-help-circle-outline</v-icon>
              </v-chip>
            </div>


            <div>
              <v-btn style="height: 30px; width:140px; font-size: 9px; background: darkgrey"
                     @click="updateStates(item)">UPDATE STATUS
              </v-btn>
            </div>
          </div>
        </template>

         <!-- Custom Template for Actions -->
         <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_outlets_list">
                <template v-slot:activator="{ props }">
                  <v-btn
                      class="custom_btn_primary"
                      variant="flat"
                      size="large"
                      v-bind="props"
                  >
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item  @click="viewItem(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item  @click="editItem(item)">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item  @click="confirmDelete(item)">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

       
      </v-data-table>
    </v-card>

    <!--        update states dialog-->

    <v-dialog v-model="updateDialog" class="solid-dialog d-flex" height="80%" width="35%">
      <v-card class="pa-6 " style="overflow-y: auto">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline" style="font-size: 17px">UPDATE STATUS</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="updateDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <v-row style="padding: 10px">
          <v-col>
            <v-text-field
                v-model="updateDetails.outlet_name"
                class="reminder"
                label="Outlet Name"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="updateDetails.owner_name"
                class="reminder"
                label="Owner Name"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="updateDetails.mobile_number"
                class="reminder"
                label="Mobile Number"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-select
                v-model="selectOS"
                :items="outletSates"
                class="reminder"
                item-title="outlet_status"
                item-value="outlet_status"
                label="Outlet Status"
                variant="outlined"
            ></v-select>
            <v-text-field
                v-model="reason"
                class="reminder"
                label="Reason"
                variant="outlined"
            ></v-text-field>

          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col class="d-flex justify-end " cols="12" md="12" @click="updateDialog = false">
            <v-btn class="mt-3 bg-red-lighten-4" color="red" size="large" variant="outlined"
                   @click="reminderSend(updateDetails.id)">
              UPDATE
            </v-btn>
            <v-btn class="mt-3 ml-3 bg-grey-darken-3" color="gray" size="large" variant="outlined"
                   @click="updateDialog = false">
              CANCEL
            </v-btn>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE OUTLET DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2">
          <v-icon class="mr-2" color="orange">mdi-alert</v-icon>
          Do you want to delete this outlet record ?
        </v-card-text>
        <v-card-text class="font-weight-medium">
          This outlet record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <OutletDetails/>
        </v-row>
      </v-card>
    </v-dialog>


    <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <OutletDetailsEdit/>
        </v-row>
      </v-card>
    </v-dialog>


  <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      location="top-right"
      outlined
      timeout="3000"
  >
    {{ snackbarMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

  <v-overlay v-model="loading">
    <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
  </v-overlay>

    </v-container>
   </v-app>


</template>

<script>
import axios from 'axios';
import {EventBus} from '@/plugins/event-bus';
import OutletDetails from "@/components/OutletManagement/OutletDetails.vue";
import OutletDetailsEdit from "@/components/OutletManagement/OutletDetailsEdit.vue";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export default {
  name: "OutletList",
  components: {
    OutletDetails,
    OutletDetailsEdit
  },
  data() {
    return {

      snackbarMessage: '', // Dynamic message for the snackbar
      snackbarColor: 'success', // Dynamic color for the snackbar

      loading: false,
      snackbar: false,
      outletName: '',
      ownerName: '',
      mobileNumber: '',
      reason: '',
      search: '',
      deleteDialog: false,
      updateDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      showSwitches: false,
      SalesSwitch: false,
      CollectionsSwitch: false,
      tableLoading:false,
      selectOS: '',
      selectedOutlet: [],
      outletTypes: [],
      selectedOutletStatus: [],
      // outletSates: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      outletSates: ['ALLOWED', 'NOT ALLOWED'],
      headers: [
        {text: 'OUTLET TYPE', value: 'outlet_type.outlet_name', title: 'OUTLET TYPE'},
        {text: 'OUTLET NAME', value: 'outlet_name', title: 'OUTLET NAME'},
        {text: 'OUTLET STATUS', value: 'outlet_status', title: 'OUTLET STATUS'},
        {text: 'OUTLET ADDRESS', value: 'street_address', title: 'OUTLET ADDRESS'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      headersSecond: [
        {text: '#', value: 'index', title: '#'},
        {text: 'OUTLET TYPE', value: 'outlet_type.outlet_name', title: 'OUTLET TYPE'},
        {text: 'OUTLET NAME', value: 'outlet_name', title: 'OUTLET NAME'},
        {text: 'OUTLET STATUS', value: 'outlet_status', title: 'OUTLET STATUS'},
        {text: 'OUTLET ADDRESS', value: 'outlet_address', title: 'OUTLET ADDRESS'},
        {text: 'OWNER NAME', value: 'owner_name', title: 'OWNER NAME'},
        {text: 'MOBILE NUMBER', value: 'mobile_number', title: 'MOBILE NUMBER'},
        {text: 'PROVINCE', value: 'province', title: 'PROVINCE'},
        {text: 'DISTRICT', value: 'district', title: 'DISTRICT'},
        {text: 'DSD', value: 'dsd', title: 'DSD'},
        {text: 'GND', value: 'gnd', title: 'GND'},
        {text: 'LATITUDE', value: 'latitude', title: 'LATITUDE'},
        {text: 'LONGITUDE', value: 'longitude', title: 'LONGITUDE'},
      ],
      routes: [], // Initially empty, will be filled by API call
      updateDetails: [],
      id: '',
    };
  },
  methods: {
    ...mapActions(['updateSharedDataOutlet']),

    updateStates(item) {
      this.selectOS = item.outlet_status
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = item.id;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.updateDetails = response.data;
            console.log("API Response:", response.data);
            this.updateDialog = true;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchRoutes() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      this.tableLoading = true;
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routes = response.data;
            this.tableLoading = false;
            console.log("API Response:", response.data);
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    // fetchOutletStates() {
    //   const token = localStorage.getItem('token');
    //   const userId = localStorage.getItem('userId');
    //
    //   axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/status/view`, {userId}, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //       .then(response => {
    //         this.outletSates = response.data;
    //       })
    //       .catch(error => {
    //         console.error('Error fetching routes:', error);
    //       });
    //
    // },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

          .then(() => {
            this.routes = this.routes.filter(route => route.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },
    exportPDF() {
      const doc = new jsPDF('landscape');

      // Set the title for the document
      doc.text('Outlet list', 14, 10);

      // Prepare the data for the table
      const tableData = this.routes.map((item, index) => [
        index + 1,
        item.outlet_type.outlet_name,
        item.outlet_name,
        item.outlet_status,
        item.outlet_address,
        item.owner_name,
        item.mobile_number,
        item.province,
        item.district,
        item.dsd,
        item.gnd,
        item.latitude,
        item.longitude
      ]);

      // Define the columns for the table
      const tableColumns = this.headersSecond.map(header => header.text);

      const columsWidth = {
        0: {cellWidth: 10},
        1: {cellWidth: 25},
        2: {cellWidth: 30},
        3: {cellWidth: 30},
        4: {cellWidth: 40},
        5: {cellWidth: 20},
        6: {cellWidth: 20},
        7: {cellWidth: 20},
        8: {cellWidth: 15},
        9: {cellWidth: 15},
        10: {cellWidth: 15},
        11: {cellWidth: 20},
        12: {cellWidth: 20}
      }

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
        columnStyles: columsWidth,
        styles: {
          fontSize: 8,
          cellPadding: 2,
          overflow: 'linebreak',
          fontColor: 'black',
        },
        margin: {top: 20},
        theme: 'grid',
        tableWidth: 'wrap',
        // didDrawCell: (data) => {
        //
        // }
      });

      // Save the PDF with a name
      doc.save('outlet_details.pdf');

    },
    exportExcel() {
      // Prepare the data for the Excel sheet
      const tableData = this.routes.map((item, index) => ({
        '#': index + 1,
        'Outlet Type': item.outlet_type.outlet_name,
        'Outlet Name': item.outlet_name,
        'Outlet Status': item.outlet_status,
        'Outlet Address': item.outlet_address,
        'Owner Name': item.owner_name,
        'Mobile Number': item.mobile_number,
        'Province': item.province,
        'District': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
        'Latitude': item.latitude,
        'Longitude': item.longitude
      }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set column widths for better alignment
      const colWidths = [
        { wch: 5 },  // #
        { wch: 20 }, // Outlet Type
        { wch: 25 }, // Outlet Name
        { wch: 15 }, // Outlet Status
        { wch: 40 }, // Outlet Address
        { wch: 20 }, // Owner Name
        { wch: 15 }, // Mobile Number
        { wch: 15 }, // Province
        { wch: 15 }, // District
        { wch: 15 }, // DSD
        { wch: 15 }, // GND
        { wch: 15 }, // Latitude
        { wch: 15 }  // Longitude
      ];
      worksheet['!cols'] = colWidths;

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Outlet Details');

      // Write the workbook to a file
      XLSX.writeFile(workbook, 'outlet_details.xlsx');
    },
    viewItem(item) {
      this.id = item.id
      this.updateSharedDataOutlet(item.id);
      this.view_Dialog = true;

    },
    editItem(item) {
      // Functionality to edit item details
      this.updateSharedDataOutlet(item.id);
      this.editDialog = true;
    },
    closeD() {
      this.view_Dialog = false;
      this.editDialog = false;
    },
    outletManagementButtonClick() {

      this.editDialog = true;
      this.view_Dialog = false;
    },
    backPageRoute() {
      this.$router.push('/dashboard');
    },
    o2() {
      this.editDialog = false;
      this.view_Dialog = false;
      this.routes = this.routes.filter(route => route.id !== this.id);
      this.id = null;
    },
    setupEventListeners() {
      EventBus.on('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.on('outletManagementButtonClick2', this.o2);
    },
    removeEventListeners() {
      EventBus.off('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.off('outletManagementButtonClick2', this.o2);
    },
    toggleSwitches() {
      this.showSwitches = !this.showSwitches;
    },
    reminderSend(id) {
      // Check if a reason is provided when the status is changed
      if (this.selectOS && !this.reason) {
        this.snackbarMessage = 'Please provide a reason for changing the status.'; // Set error message
        this.snackbarColor = 'error'; // Set snackbar color to error
        this.snackbar = true; // Show snackbar
        this.updateDialog = true;
        return; // Exit the function if no reason is provided
      }

      this.loading = true;

      const userId = localStorage.getItem('userId');
      const outletData = {
        status: this.selectOS,
        reason: this.reason,
        userId: userId,
      };

      const token = localStorage.getItem('token');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`, outletData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
          .then(response => {
            console.log('Reminder sent successfully:', response.data);
            this.snackbarMessage = 'UPDATE SUCCESSFULLY.'; // Set success message
            this.snackbarColor = 'success'; // Set snackbar color to success
            this.snackbar = true; // Show snackbar
            this.reason="";
            this.fetchRoutes();
          })
          .catch(error => {
            console.error('Error sending reminder:', error);
            this.snackbarMessage = 'Failed to update. Please try again.'; // Set error message
            this.snackbarColor = 'error'; // Set snackbar color to error
            this.snackbar = true; // Show snackbar
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.setupEventListeners();
    this.fetchRoutes();
    this.fetchOutletType();

  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  computed: {
    filteredRoutes() {
      return this.routes.filter((route) => {
        const withinDateRange =
            !this.dateRange ||
            (new Date(route.assignedDate) >= new Date(this.dates[0]) &&
                new Date(route.assignedDate) <= new Date(this.dates[1]));

        const matchesCategory =
            !this.selectedOutlet.length ||
            this.selectedOutlet.includes(route.outlet_type_id);

        const matchesRouteStatus =
            !this.selectedOutletStatus.length ||
            this.selectedOutletStatus.includes(route.outlet_status);

        return withinDateRange && matchesCategory && matchesRouteStatus;
      });
    },

  }
}
</script>

<style>
@import "../../styles/outlet_management/outlet_list.scss";
</style>
<template>
    <v-app id="app_authentication">
  
      <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
        <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
      </v-overlay>
  
      <v-navigation-drawer class="custom_navigation_drawer" width="30%" :image="require('@/img/background_images/image_5.png')"  v-model="drawer" app></v-navigation-drawer>
      <v-main class="custom_border_with_small_devices custom_class_alignment">
        <v-container color="white" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="2"></v-col>
                <v-col cols="12" sm="12" md="8">
                    <v-card flat style="background: transparent !important;">

                      <!-- Title Section -->
                    <template v-slot:title>
                      <v-card-title class="custom_topic_sign_in text-wrap" style="white-space: normal;">
                        Enter otp code
                      </v-card-title>
                    </template>

                    <!-- Logo Section -->
                    <template v-slot:append>
                      <div class="d-flex align-center">
                        <!-- <img style="width: 80px; height: 40px; margin-right: 10px;" :src="require('@/img/company_logo/g-sfa.png')" />
                        <v-divider :thickness="3" vertical class="mb-0 mt-0 mr-3"></v-divider> -->
                        <img style="width: 220px; height: 150px;" :src="require('@/WEB/LOGOS/logo1.png')" />
                      </div>
                    </template>

                    <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

                    <v-card-subtitle class="mt-2">
                        <!-- <div>
                        <p class="custom_paragraph_option_2">
                            <v-icon size="small">mdi mdi-account-arrow-right-outline</v-icon>
                            We have sent an OTP code to your email, <br>
                            {{ this.email }} for authentication
                        </p>
                        </div> -->
                        <div>
                        <v-icon size="small">mdi-account-arrow-right-outline</v-icon>
                        <span class="custom_paragraph_option_2">
                          We have sent an OTP code to your email,  <br>
                          <span class="countdown-text ml-6">{{ this.email }}</span>, for authentication.
                        </span>
                      </div>
                    </v-card-subtitle>

                    <!-- OTP Inputs -->
                    <v-card-text class="mt-4 text-center">
                    <v-row justify="center">
                        <v-col v-for="(digit, index) in otp" :key="index" cols="2">
                            <v-text-field
                                v-model="otp[index]"
                                class="otp-input"
                                outlined
                                dense
                                single-line
                                type="text"
                                text-center
                                hide-details
                                ref="otpFields"
                                @input="moveToNext(index)"
                                :rules="[v => /^\d?$/.test(v) || 'Only one digit allowed']"
                                @keydown.delete="moveToPrevious(index)"
                             ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row justify="center">
                    <v-col cols="auto">
                        <span class="countdown-text">
                        <span v-if="timeLeft > 0">
                            Try again in <strong>{{ formattedTime }}</strong>
                        </span>
                        <span v-else>
                            <span >Haven't received the email?</span>
                        </span>
                        </span>

                        <span v-if="timeLeft === 0">
                            <v-btn variant="text" class="resend-btn" @click="resendOtp">RESEND</v-btn>
                            <!-- <v-card-actions class="justify-center">
                            <v-btn color="primary" @click="submitOtp">RESEND</v-btn>
                            </v-card-actions> -->
                            
                        </span>
                    </v-col>
                    </v-row>

                    <v-row>
                        <v-col sm="12" md="12" lg="12">
                            <v-divider></v-divider>
                        </v-col>

                    <v-col cols="12" sm="12" md="6">
                        <v-btn  @click="submitOtp"   size="x-large" block class="custom_paragraph_option_btn text-white">
                        <v-icon left>mdi mdi-account-arrow-right-outline</v-icon>
                        &nbsp;&nbsp;Continue
                        </v-btn>

                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-btn  @click="backToLogIn"  size="x-large"  block class="custom_paragraph_option_btn_2">
                        <v-icon left >mdi mdi-arrow-left</v-icon>
                        &nbsp;Back
                        </v-btn>
                    </v-col>
                    </v-row>

                    </v-card-text>
                </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="2"></v-col>
            </v-row>
            </v-container>

        <v-snackbar
            v-model="snackAlert.snackbar"
            :color="snackAlert.snackColor"
            :right="snackAlert.snackBarRight"
            :top="snackAlert.snackBarTop"
            timeout="3000"
        >
          {{ snackAlert.snackMessage }}
  
        </v-snackbar>
      </v-main>
    </v-app>
  </template>
  
  
  
  <script>
  import axios from 'axios';
  export default {
    name: "ForgotPassword",
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.timeLeft / 60);
            const seconds = this.timeLeft % 60;
            return `0${minutes}m ${seconds < 10 ? "0" : ""}${seconds}s`;
        },
    },
    mounted() {
        this.startCountdown(); // Start timer when component loads

        const params = new URLSearchParams(window.location.search);
        this.email = params.get('email');
    },
    data() {
      return {
        otp: ["", "", "", ""], // 4-digit OTP storage
        timeLeft: 60,
        centerLoading: false,
        valid: false, // Form validation state
        dialog_components: false, // State to show/hide the dialog
        emailRules: [
          v => !!v || "E-mail is required",
  
        ],
        drawer: null,
        email_errors: [], // Array to hold email validation errors
        email: "", // User input for email
        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },
      };
    },
    methods: {
      // Navigate back to the login page
      backToLogIn() {
        this.$router.push('/forgot_password');
      },
      showSnackbar(message, color) {
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
        this.snackAlert.snackbar = true;
      },
      
        moveToNext(index) {
          // Only allow numbers (0-9) and limit input to 1 digit
          this.otp[index] = this.otp[index].replace(/\D/g, '').slice(0, 1);

            if (this.otp[index] && index < this.otp.length - 1) {
                // Use $refs and querySelector to focus the next input
                const nextField = this.$refs.otpFields[index + 1]?.$el.querySelector("input");
                if (nextField) nextField.focus();
            }
        },
        moveToPrevious(index) {
            if (index > 0 && !this.otp[index]) {
                // Use $refs and querySelector to focus the previous input
                const prevField = this.$refs.otpFields[index - 1]?.$el.querySelector("input");
                if (prevField) prevField.focus();
            }
        },

        submitOtp() {
          // Concatenate the digits in the otp array.
          const otpCode = this.otp.join('');

          // Basic check: ensure all 4 digits have been entered.
          if (otpCode.length !== 4) {
            this.showSnackbar("Please enter a 4-digit OTP.", "error");
            return;
          }

          // Post the OTP and email to the backend.
          axios.post('/api/web/verifyOtp', {
            email: this.email,
            otp: otpCode,
          })
          .then(response => {
            if (response.data.success) {
              this.showSnackbar(response.data.message, "success");
              // If OTP verification is successful, redirect the user to the password reset page.
              window.location.href = `/reset-password?email=${encodeURIComponent(this.email)}`;
            } else {
              this.showSnackbar(response.data.message || "Invalid OTP", "error");
            }
          })
          .catch(error => {
            let errorMsg = "An error occurred. Please try again.";
            if (error.response && error.response.data && error.response.data.message) {
              errorMsg = error.response.data.message;
            }
            this.showSnackbar(errorMsg, "error");
          });
        },


        startCountdown() {
            const timer = setInterval(() => {
                if (this.timeLeft > 0) {
                this.timeLeft--;
                } else {
                clearInterval(timer);
                }
            }, 1000);
            },


            startTimer() {
            // Example: Reset timer logic here
            this.timeLeft = 60;
            if (this.timer) clearInterval(this.timer);
            this.timer = setInterval(() => {
              if (this.timeLeft > 0) {
                this.timeLeft--;
              } else {
                clearInterval(this.timer);
              }
            }, 1000);
          },

            // Resend OTP by calling the backend endpoint.

            resendOtp() {
                this.timeLeft = 60; // Reset countdown
                this.startCountdown();

                // Call the resend endpoint with the current email.
                axios.post('/api/resendOtp', { email: this.email })
                  .then(response => {
                    if (response.data.success) {
                      // Show a success message.
                      this.showSnackbar(response.data.message, 'success');
                      // Restart the countdown timer.
                      this.startTimer();
                      // Optionally, clear the current OTP fields.
                      this.otp = ['', '', '', ''];
                    } else {
                      this.showSnackbar(response.data.message || 'Failed to resend OTP.', 'error');
                    }
                  })
                  .catch(error => {
                    console.error('Resend OTP error:', error);
                    let errorMsg = 'An error occurred. Please try again.';
                    if (error.response && error.response.data && error.response.data.message) {
                      errorMsg = error.response.data.message;
                    }
                    this.showSnackbar(errorMsg, 'error');
                  });
            },

            
   
  
    }
  };
  </script>
  
  <style>
    @import "../../styles/auth/authentication.scss";
  </style>
  
  
<template>
  <v-app id="app_authentication">

    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-navigation-drawer class="custom_navigation_drawer" width="30%" :image="require('@/img/background_images/image_4.png')"  v-model="drawer" app></v-navigation-drawer>
    <v-main class="custom_border_with_small_devices custom_class_alignment">
      <v-container color="white" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="2"></v-col>
          <v-col cols="12" sm="12" md="8">
            <v-card flat style="background: transparent !important;">
              <!-- Title Section -->
              <template v-slot:title>
                <v-card-title class="custom_topic_sign_in text-wrap" style="white-space: normal;">
                  Forgot password?
                </v-card-title>
              </template>

              <!-- Logo Section -->
              <template v-slot:append>
                <div class="d-flex align-center">
                  <!-- <img style="width: 80px; height: 40px; margin-right: 10px;" :src="require('@/img/company_logo/g-sfa.png')" /> -->
                  <!-- <v-divider :thickness="3" vertical class="mb-0 mt-0 mr-3"></v-divider> -->
                  <img style="width: 220px; height: 150px;" :src="require('@/WEB/LOGOS/logo1.png')" />
                </div>
              </template>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <!-- Info Message -->
              <v-card-subtitle class="mt-2">
                <div>
                  <p class="custom_paragraph_option_2">
                    <v-icon size="small">mdi-account-arrow-right-outline</v-icon>
                    Don't worry! We will send you an email so you can reset your password.
                  </p>
                </div>
              </v-card-subtitle>

              <!-- Email Input -->
              <v-card-text color="white" elevation="0" light class="mt-4">
                <v-form v-model="valid" ref="form" action="#" method="post">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        :error-messages="email_errors"
                        :rules="emailRules"
                        class="mb-0 mt-2 mb-3"
                        color="#546E7A"
                        clearable
                        label="Email"
                        outlined
                        placeholder="Enter your e-mail address"
                        prepend-inner-icon="mdi-email-outline"
                        required
                        type="email"
                        variant="outlined"
                        @keyup.enter="resetPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <!-- Buttons -->
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-btn @click="forgetPassword" size="large" block class="custom_paragraph_option_btn text-white">
                    <v-icon left>mdi-account-arrow-right-outline</v-icon>
                    Continue
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-btn @click="backToLogIn" size="large" block class="custom_paragraph_option_btn_2">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Back To Login
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <!-- Footer -->
              <v-card-subtitle v-if="showPoweredBy" class="mt-2">
                <div>
                  <p class="custom_paragraph_option_2 text-center">
                    POWERED BY CELATATECH
                  </p>
                </div>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="2"></v-col>
        </v-row>
      </v-container>



      <!-- Dialog box for inform about reset email has sent -->
      <v-dialog v-model="dialog_components" max-width="450px" >
        <v-card id="app_authentication" rounded="md">
          <div class="pa-4">
            <v-card color="#F0F0F0" flat>
              <template v-slot:prepend>
                <v-card-title class="text-subtitle-1 font-weight-bold">RESET LINK</v-card-title>
              </template>
              <v-spacer></v-spacer>
              <template v-slot:append>
                <v-btn @click="backToLogIn" icon="mdi-close" size="x-small"></v-btn>
              </template>
            </v-card>

            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title class="text-body-1 font-weight-medium">
                  <v-icon color="warning" size="small">mdi-alert</v-icon>
                  &nbsp;&nbsp;Reset link has been sent to your email:
                </v-card-title>
                <v-card-subtitle style="color: #BB0000 !important;" class="font-weight-bold mt-2">{{this.email}}</v-card-subtitle>

                <p class="text-lg-caption mt-4 text-justify">
                  A link to reset your password has been sent to your email address.
                  Please click the link and provide your new password.
                </p>

                <v-divider class="mt-4"></v-divider>

                <div class="float-end mt-4 ">
                  <v-btn size="large" class="custom_btn_secondary" @click="backToLogIn">CANCEL</v-btn>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :right="snackAlert.snackBarRight"
          :top="snackAlert.snackBarTop"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>
    </v-main>
  </v-app>
</template>



<script>
import axios from 'axios';
export default {
  name: "ForgotPassword",
  data() {
    return {
      centerLoading: false,
      valid: false, // Form validation state
      dialog_components: false, // State to show/hide the dialog
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'Must be a valid email'

      ],
      drawer: null,
      email_errors: [], // Array to hold email validation errors
      email: "", // User input for email
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
    };
  },
  methods: {
    // Navigate back to the login page
    backToLogIn() {
      this.$router.push('/');
    },
    showSnackbar(message, color) {
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
      this.snackAlert.snackbar = true;
    },
    // Trigger the password reset process
    async resetPassword() {
        const baseUrl = window.location.origin;

        console.log('Base URL:', baseUrl);
        if (this.valid) {
          this.centerLoading = true;
          try {
            const response = await axios.post('/api/forgetPassword', { email: this.email, baseUrl: baseUrl });

            if (response.data.success) { // Check the 'success' field from the backend response
              this.dialog_components = true;
              this.showSnackbar("Password reset email sent successfully.", "success");
            } else {
              this.showSnackbar(response.data.message || "Failed to send reset email", "error");
            }
            this.centerLoading = false;
          } catch (error) {
            if (error.response) {
              const errorMsg = error.response.data.message || "Failed to send reset email.";
              this.showSnackbar(errorMsg, "error");
            } else {
              this.showSnackbar("An error occurred. Please try again later.", "error");
            }
            this.centerLoading = false;
          }
        } else {
          this.showSnackbar("Please enter a valid email address.", "error");
        }
      },


      //Forget Password and Send OTP to the User.
      async forgetPassword() {
        
        if (this.valid) {
          
          try {
            const response = await axios.post('/api/forgetPasswordAndSendOtp', { email: this.email });

            if (response.data.success) { 
              this.showSnackbar("Otp sent successfully.", "success");
              window.location.href = `/add_otp?email=${encodeURIComponent(this.email)}`;
            } else {
              this.showSnackbar(response.data.message || "Failed to send Otp ", "error");
            }
            this.centerLoading = false;
          } catch (error) {
            if (error.response) {
              const errorMsg = error.response.data.message || "Failed to send Otp.";
              this.showSnackbar(errorMsg, "error");
            } else {
              this.showSnackbar("An error occurred. Please try again later.", "error");
            }
            this.centerLoading = false;
          }
        } else {
          this.showSnackbar("Please enter a valid email address.", "error");
        }
      },

  }
};
</script>

<style>
  @import "../../styles/auth/authentication.scss";
</style>


<template>

  <v-app id="app_create_outlets">
    <v-container fluid>
      <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">CREATE OUTLETS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Create or bulk upload outlet data set according to
            your requirements.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <!-- <v-btn  color="#05146F" size="x-large" variant="text" @click="outletBulkUploadDialog = true">
              <v-icon>mdi-file-upload-outline</v-icon>&nbsp;&nbsp;OUTLETS BULK UPLOAD
            </v-btn> -->
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageOutlet()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>


      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">

        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Create new outlet</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row class="mt-2">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="outlet.outlet_name"
                    class="mb-2"
                    label="Outlet Name *"
                    placeholder="Type outlet name"
                    variant="outlined"
                ></v-text-field>

              </v-col>


              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="outlet.owner_name"
                    class="mb-2"
                    label="Owner Name *"
                    placeholder="Type owner name"
                    variant="outlined"
                ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                    v-model="outlet.mobile_number"
                    class="mb-2"
                    label="Mobile Number *"
                    placeholder="Type mobile number"
                    variant="outlined"
                ></v-text-field>

              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                    v-model="outlet.outlet_type"
                    :items="outletTypes"
                    item-title="outlet_name"
                    item-value="id"
                    label="Select Outlet Type *"
                    variant="outlined"
                    clearable
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                    v-model="outlet.province"
                    :items="provinces"
                    :loading="provinceLoading"
                    label="Province *"
                    placeholder="Select province"
                    variant="outlined"
                    clearable
                    @update:modelValue="getAllDistrictAccProv"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                    v-model="outlet.district"
                    :items="districts"
                    :loading="mDistrictLoading"
                    label="District *"
                    placeholder="Select district"
                    variant="outlined"
                    clearable
                    @update:modelValue="getAllDsd"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                    v-model="outlet.dsd"
                    :items="dsdList"
                    :loading="mDSDLoading"
                    clearable
                    label="DSD *"
                    placeholder="Select DSD"
                    variant="outlined"
                    @update:modelValue="getAllGnd"

                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                    v-model="outlet.gnd"
                    :items="gndList"
                    label="GND *"
                    clearable
                    placeholder="Select GND"
                    variant="outlined"
                    :loading="gndLoading"
                ></v-select>
              </v-col>


              <v-divider thickness="2"></v-divider>
            </v-row>

          </v-form>

          <v-row class="pt-6" style="padding-left: 15px; padding-right: 5px;">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="outlet.outlet_address"
                      class="mb-2"
                      label="Outlet Address"
                      placeholder="Select Address"
                      variant="outlined"
                      id="search_address"
                      append-inner-icon="mdi-magnify"
                  ></v-text-field>
                  <v-alert
                      v-if="errors.outlet_address"
                      type="error"
                      dense
                      border="left"
                      colored-border
                      elevation="2"
                      class="small-alert"
                  >
                    {{ errors.outlet_address }}
                  </v-alert>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="outlet.street_address"
                      class="mb-2"
                      label="Street Address *"
                      placeholder="Select Address"
                      variant="outlined"
                      id="search_address"
                      append-inner-icon="mdi-magnify"
                      @input="checkOutletField('street_address')"
                  ></v-text-field>
                  <v-alert
                      v-if="errors.street_address"
                      type="error"
                      dense
                      border="left"
                      colored-border
                      elevation="2"
                      class="small-alert"
                  >
                    {{ errors.street_address }}
                  </v-alert>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                  <v-text-field
                      v-model="outlet.latitude"
                      label="Latitude *"
                      readonly
                      variant="outlined"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                  <v-text-field
                      v-model="outlet.longitude"
                      label="Longitude *"
                      readonly
                      variant="outlined"
                  ></v-text-field>
                </v-col>

              </v-row>

              <h5 class="px-3 py-3">USE MAP TO SELECT </h5>
              <h5 class="px-3 pb-3 light-gray-text">In here, you can set the outlet location by searching the location
                or
                dragging and placing it.</h5>
              <v-card class="ma-3 pa-5" style=" padding: 20px" variant="flat" width="100%">
                <Map ref="map" :center="mapCenter" :zoom="13" class="d-flex map" style="width: 100%; height: 50vh"
                >
                  <Marker
                      v-if="markerPosition"
                      :icon="customIcon"
                      :position="markerPosition"
                  />
                </Map>
              </v-card>

            </v-col>
          </v-row>

        </v-card-text>

        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn
              class="red-background-text mr-3"
              variant="outlined"
              @click="createRoute"
              :disabled="isCreateButtonDisabled"
          >
            CREATE NEW OUTLET
          </v-btn>
          <v-btn class="gray-background-text" variant="outlined" @click="backPageRoute()">CANCEL</v-btn>
        </v-card-actions>

      </v-card>

      <!-- View outlet Dialog -->

      <v-dialog v-model="outletBulkUploadDialog" fullscreen>
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="outletBulkUploadDialog = false">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <v-card class="pa-3 mx-6">
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-6">UPLOAD SPREADSHEET</v-label>
              </v-card>
              <v-card class="px-3 mx-6 mt-3">
                <v-row class="ma-0">
                  <v-col cols="5">
                    <h5 class="px-3">Upload your outlet bulk data set using excel or a csv file.</h5>
                    <v-col cols="12">
                      <v-file-input
                          accept=".csv, .xls, .xlsx"
                          label="Upload your Excel or CSV file"
                          variant="outlined"
                          @change="handleFileUpload"
                      ></v-file-input>
                    </v-col>
                  </v-col>
                  <v-col class="d-flex align-center justify-center" cols="1">
                    <v-divider :thickness="2" vertical></v-divider>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">

                      </v-col>
                      <v-col cols="9">
                        <v-card class="d-flex align-center justify-space-between pa-4 mt-3" color="grey-lighten-3" flat
                                width="100%">
                          <div class="mr-4">
                            <h4 class="mb-0">DOWNLOAD SAMPLE TEMPLATE</h4>
                          </div>
                          <v-btn class="red-background-text btn-border" size="x-large" variant="text"
                                 @click="downloadTemplate">
                            DOWNLOAD
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-card v-if="bulkItems.length > 0" class="ma-3" flat>
                  <v-data-table
                      :headers="bulkHeaders"
                      :items="bulkItems"
                      :items-per-page="itemsPerPage"
                      class="elevation-1"
                  >
                    <template v-slot:[`item.STATES`]="{ item }">
                      <div>
                        <div>
                          <v-chip v-if="item.outlet_status === 'Allowed'" color="green"
                                  style="height: 20px; width:130px; font-size: 12px"
                                  variant="outlined">
                            ALLOWED
                            <v-icon class="justify-end ml-14">mdi-check</v-icon>
                          </v-chip>

                          <v-chip v-if="item.outlet_status === 'NotAllowed'" color="red"
                                  style="height: 20px; width:130px; font-size: 12px"
                                  variant="outlined">
                            NOT ALLOWED
                            <v-icon class="justify-end ml-6">mdi-close</v-icon>
                          </v-chip>
                        </div>
                      </div>
                    </template>

                  </v-data-table>
                </v-card>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="bulkItems.length === 0" class="red-background-text btn-border mx-3" size="x-large"
                         variant="text"
                         @click="uploadBulkOutlets">Upload
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-snackbar
          v-model="snackbar"
          color="success"
          location="top-right"
          outlined
          timeout="3000"
      >
        NEW OUTLET WAS CREATED SUCCESSFULLY.
        <template v-slot:action="{ attrs }">
          <v-btn
              icon
              v-bind="attrs"
              @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="snackbarBULK" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
          v-model="snackbar"
          :color="snackbarColor"
          location="top-right"
          outlined
          :timeout="3000"
      >
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
              icon
              v-bind="attrs"
              @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay v-model="loading">
        <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
      </v-overlay>


    </v-container>

  </v-app>

</template>

<script>
import axios from 'axios';
// import * as XLSX from 'xlsx';
import {Map, Marker} from '@fawmi/vue-google-maps';
// import {ref, watch} from "vue";
// import {onMounted, ref} from 'vue';
import * as XLSX from 'xlsx';


export default {
  components: {
    Map,
    Marker,
  },
  data() {
    return {
      errors: {
        outlet_name: '',
        outlet_address: '',
        street_address: '',
        mobile_number: '',
      },
      snackbarBULK: false,
      snackbarText: '',
      snackbarMessage: '',
      snackbarColor: 'success',
      snackbarFile: false,
      loading: false,
      snackbar: false,
      outlet: {
        outlet_name: '',
        owner_name: '',
        mobile_number: '',
        outlet_type_id: null,
        outlet_address: '',
        street_address: '',
        latitude: '',
        longitude: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        outlet_status: '',
        outlet_type: '',
      },
      // states:['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      states: ['ALLOWED', 'NOT ALLOWED'],
      outletTypes: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      provinceLoading: false,
      gndLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      outletBulkUploadDialog: false,
      bulkFile: null,
      bulkItems: [],
      bulkHeaders: [
        {text: 'Outlet Name', value: 'Outlet Name', title: 'Outlet Name'},
        {text: 'Owner Name', value: 'Owner Name', title: 'Owner Name'},
        {text: 'Mobile Number', value: 'Mobile Number', title: 'Mobile Number'},
        {text: 'Outlet Type', value: 'Outlet Type', title: 'Outlet Type'},
        {text: 'Outlet Address', value: 'Outlet Address', title: 'Outlet Address'},
        {text: 'Latitude', value: 'Latitude', title: 'Latitude'},
        {text: 'Longitude', value: 'Longitude', title: 'Longitude'},
        {text: 'Province', value: 'Province', title: 'Province'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        {text: 'Outlet Status', value: 'Outlet Status', title: 'Outlet Status'},
      ],
      bulkHeadersView: [
        {text: 'Outlet Name', value: 'Outlet Name', title: 'Outlet Name'},
        {text: 'Owner Name', value: 'Owner Name', title: 'Owner Name'},
        {text: 'Mobile Number', value: 'Mobile Number', title: 'Mobile Number'},
        {text: 'Outlet Type Id', value: 'Outlet Type Id', title: 'Outlet Type'},
        {text: 'Province', value: 'Province', title: 'Province'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        {text: 'Outlet Status', value: 'Outlet Status', title: 'Outlet Status'},
        {text: 'Latitude', value: 'Latitude', title: 'Latitude'},
        {text: 'Longitude', value: 'Longitude', title: 'Longitude'},
        {text: 'Outlet Address', value: 'Outlet Address', title: 'Outlet Address'},
      ],
      mapCenter: {lat: 6.863062576280659, lng: 79.98859753968648},
      markerPosition: null,
      customIcon: {
        url: require('@/assets/ICONS/maker.png'), // Path to your custom marker icon
        scaledSize: {width: 35, height: 40} // Adjust the size if needed
      }
    };
  },
  computed: {
    isCreateButtonDisabled() {
      // Disable the button if any error exists
      return (
          this.errors.street_address.length
      );
    },
  },
  mounted() {
    this.getAllProvinces();
    this.fetchOutletType();
    this.$refs.map.$mapPromise.then((map) => {
      // this.GMap = map;
      this.mapInit(map);
    });
  },
  methods: {

    mapInit(map) {
      const searchAddress_input = document.getElementById("search_address");
      const searchAddress_autocomplete = new window.google.maps.places.SearchBox(searchAddress_input);
      map.addListener("bounds_changed", () => {
        searchAddress_autocomplete.setBounds(map.getBounds());
      });

      let markers = [];
      // let imageSearch ="img/map_makers/pin_point.png";
      searchAddress_autocomplete.addListener("places_changed", () => {
        const places = searchAddress_autocomplete.getPlaces();

        if (places.length == 0) {
          return;
        }
        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }
          const icon = {
            url: require('@/assets/ICONS/maker.png'),
            size: new window.google.maps.Size(80, 80),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(35, 40),
          };
          // Create a marker for each place.
          markers.push(
              new window.google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
              })
          );
          this.outlet.latitude = markers[0].getPosition().lat();
          this.outlet.longitude = markers[0].getPosition().lng();
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });

      window.google.maps.event.addListener(map, 'click', (event) => {
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        this.outlet.latitude = event.latLng.lat();
        this.outlet.longitude = event.latLng.lng();

        const icon = {
          url: require('@/assets/ICONS/maker.png'),
          size: new window.google.maps.Size(80, 80),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(35, 40),
        };
        // Create a marker for each place.
        markers.push(
            new window.google.maps.Marker({
              map,
              icon,
              position: {lat: this.outlet.latitude, lng: this.outlet.longitude},
            })
        );
      });
    },

    async checkOutletField(field) {
      const value = this.outlet[field];
      if (!value) {
        this.errors[field] = ''; // Clear error if the field is empty
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/checkOutletAvailable`,
            {[field]: value},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
        );

        console.log("API Response:", response.data);

        // Update errors based on the API response
        if (response.data.conflicts) {
          if (response.data.conflicts.street_address) {
            this.errors.street_address = response.data.conflicts.street_address;
          }

        } else {
          this.errors[field] = '';
        }
      } catch (error) {
        console.error(`Error checking ${field}:`, error);
        this.errors[field] = 'An error occurred while checking the field.';
      }
    },

    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
            console.log("API Response:", this.outletTypes);

          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },

    validateForm() {
      const nameRegex = /^[a-zA-Z0-9\s'-]+$/;
      const mobileRegex = /^\d{10}$/;

      if (!this.outlet.outlet_name || !nameRegex.test(this.outlet.outlet_name)) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Outlet Name can only contain letters, numbers, and spaces!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.owner_name || !nameRegex.test(this.outlet.owner_name)) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Owner Name can only contain letters, numbers, and spaces!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.mobile_number || !mobileRegex.test(this.outlet.mobile_number)) {
        this.snackbarColor = "error";
        this.snackbarMessage = "Please enter a valid 10-digit mobile number.";
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.outlet_type) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Outlet type is required!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.province) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Province is required!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.district) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'District is required!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.dsd) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Dsd is required!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.gnd) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Gnd is required!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.street_address) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Street Address is required!';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.latitude || isNaN(this.outlet.latitude) || this.outlet.latitude < -90 || this.outlet.latitude > 90) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Please enter a valid latitude between -90 and 90.';
        this.snackbar = true;
        return false;
      }

      if (!this.outlet.longitude || isNaN(this.outlet.longitude) || this.outlet.longitude < -180 || this.outlet.longitude > 180) {
        this.snackbarColor = "error";
        this.snackbarMessage = 'Please enter a valid longitude between -180 and 180.';
        this.snackbar = true;
        return false;
      }

      return true; // All validations passed
    },

    async createRoute() {
      if (!this.validateForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      this.loading = true;

      const selectedOutlet = this.outletTypes.find(
          (outlet) => Number(outlet.id) === Number(this.outlet.outlet_type)
      );

      const selectedOutletName = selectedOutlet ? selectedOutlet.outlet_name : '';

      const userId = localStorage.getItem('userId');
      const outletData = {
        outlet_name: this.outlet.outlet_name,
        street_address: this.outlet.street_address,
        latitude: this.outlet.latitude,
        longitude: this.outlet.longitude,
        mobile_number: this.outlet.mobile_number,
        outlet_type_id: this.outlet.outlet_type,
        outlet_type: selectedOutletName,
        province: this.outlet.province,
        district: this.outlet.district,
        dsd: this.outlet.dsd,
        gnd: this.outlet.gnd,
        outlet_status: "ALLOWED",
        owner_name: this.outlet.owner_name,
        userId: userId,
      };

      try {
        const token = localStorage.getItem('token');

        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/outlet/save`,
            outletData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
        );

        console.log(response);

        this.snackbarColor = "success";
        this.snackbarMessage = 'Outlet created successfully!';
        this.snackbar = true;

        // Clear form fields
        this.outlet = {
          outlet_name: '',
          street_address: '',
          latitude: '',
          longitude: '',
          mobile_number: '',
          outlet_type: null,
          province: '',
          district: '',
          dsd: '',
          gnd: '',
          outlet_status: 'ALLOWED',
          owner_name: '',
        };
        this.markerPosition = null;
        // window.location.reload();

      } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.error === "Outlet already in the system") {
          this.snackbarColor = "error";
          this.snackbarMessage = 'Outlet already in the system!';
          this.snackbar = true;
        } else if (error.response && error.response.status === 409) {
          this.snackbarColor = "error";
          this.snackbarMessage = 'Outlet address already exists!';
          this.snackbar = true;
        } else {
          this.snackbarColor = "error";
          this.snackbarMessage = error.response?.data?.message || 'Something went wrong!';
          this.snackbar = true;
        }
      } finally {
        this.loading = false;
      }
    },


    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.provinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.provinceLoading = false;
            }
          })
          .catch(() => {
            this.provinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.gndLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.gndLoading = false;
            }
          })
          .catch(() => {
            this.gndLoading = false;
          });
    },


    handleFileUpload(event) {
      const file = event.target.files[0];
      this.bulkFile = file;

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
        const headers = jsonData[0].map(header => header ? header.toString().trim() : '');

        const expectedHeaders = this.bulkHeadersView.map(header => header.text);

        const headersMatch = headers.length === expectedHeaders.length &&
            headers.every((header, index) => header === expectedHeaders[index]);
        console.log('ddddddddddddd', headers, expectedHeaders);

        if (!headersMatch) {
          this.showSnackbar('Wrong File', 'error');
          return;
        }

        const dataRows = jsonData.slice(1).map(row => {
          return headers.reduce((obj, header, index) => {
            const value = row[index];
            obj[header] = value !== undefined && value !== null ? value.toString().trim() : '';
            return obj;
          }, {});
        });

        this.bulkItems = dataRows;
      };

      reader.readAsArrayBuffer(file);
    },
    showSnackbar(text, color = 'success') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    async uploadBulkOutlets() {
      this.loading = true;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (!this.bulkFile) {
        console.error('No file selected for upload');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.bulkFile);
      formData.append('userId', userId); // Append userId if needed by the backend

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlets/bulk-upload`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data' // No need to set this, it will be set automatically
          },
        });
        if (response.status === 200) {
          console.log('Bulk upload response:', response.data);
          this.outletBulkUploadDialog = false;
          this.snackbarFile = true;
        }

      } catch (error) {
        console.error('Error uploading bulk outlets:', error);
      } finally {
        this.loading = false;
      }
    },

    backPageRoute() {
      this.$router.back();
    },
    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.outlet.latitude = this.markerPosition.lat;
      this.outlet.longitude = this.markerPosition.lng;
    },

    async downloadTemplate() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/outlets/download-template?userId=${userId}`;


      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to download template');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'template_outlet_data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },
    homeReload() {
      window.location.href = '/dashboard';
    },
  },

  watch: {
    'outlet.province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'outlet.district'(newValue) {
      this.getAllDsd(newValue);
    },
    'outlet.dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  }

};
</script>

<style scoped>
.page-title {
  font-weight: bold;
}

.light-gray-text {
  color: #a09e9e !important;
}

.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

.btn-border {
  border-block-end-color: #9a1313;
}

.card-size {
  width: 100%;
}

.tool-bar-style {
  display: flex;
  justify-content: end;
}

.divider-vertical {
  color: #3c3b3b !important;
}

.card-two-title {
  color: black;
}

.leads-details-card {
  height: 100%;
}

.card-height-fixed {
  height: 100%;
  width: 100%;
  overflow: auto;
  max-height: 63vh;
  overflow-x: hidden;
  padding: 20px;
}

.data-table {
  width: 100% !important;
  border: black;
}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}

.red-background-text {
  background-color: #f0bcbc;
  color: #BB0000;
}

.gray-background-text {
  background-color: rgb(202, 202, 202);
  color: black;
}

.divider-two-style {
  margin-top: -5px;
}

.map::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: url('@/assets/ICONS/maker.png') no-repeat center center;
  background-size: cover;
  transform: translate(-50%, -50%);
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}
</style>
<style scoped>
.small-alert {
  padding: 4px 8px; /* Reduce padding */
  font-size: 12px; /* Smaller font size */
  margin-top: 0px; /* Adjust margin */
  margin-bottom: 4px;
}
</style>

<template>
   <v-app id="app_user_registration">
    
    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
        <v-progress-circular color="#2A8200" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>

      <v-row class="pl-2 px-6">
        <v-col cols="6" class="mt-3">
          <h3 class="page-title">USER REGISTRATION</h3>
          <h5 class="light-gray-text">Register users according to your requirements.</h5>
          <v-card-title>

          </v-card-title>
        </v-col>
        <v-col cols="6" class="px-4 mt-5">
          <v-row class="tool-bar-style">
            <!-- <v-btn size="x-large" class="red-text" variant="text" @click="createUserRole()" :disabled="true">
              <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
            </v-btn>
            <v-divider vertical :thickness="3" class="divider-vertical"></v-divider> -->
            <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      
    <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
      <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">Add user details</p>
        </template>
        <v-divider thickness="2"></v-divider>
        <v-card-text>
          <v-form ref="form">
              <v-row class="pt-6">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                   
                      <v-text-field
                          class="custom_input_field_color"
                          label="Full Name *"
                          variant="outlined"
                          placeholder="Type full name here"
                          v-model="user.fullName"
                          :rules="[v => !!v || 'Full Name is required', v => /^[a-zA-Z\s]+$/.test(v) || 'Only letters and spaces are allowed' ]"
                          required
                          density="comfortable"
                      ></v-text-field>
                      <v-text-field
                          class="custom_input_field_color"
                          label="Display Name *"
                          variant="outlined"
                          :rules="[v => !!v || 'Display is required', v => /^[a-zA-Z0-9\s]+$/.test(v) || 'Only letters, numbers, and spaces are allowed']"
                          placeholder="Type Dispaly name here"
                          v-model="user.userName"
                          density="comfortable"
                          required
                      ></v-text-field>
                      <v-select
                          class="mb-2 custom_input_field_color"
                          label="User Type *"
                          density="comfortable"
                          variant="outlined"
                          :items="userTypes"
                          v-model="user.userType"
                          item-title="name"
                          item-value="name"
                          :rules="[v => !!v || 'User role is required']"
                          required
                          placeholder="Select User Type Here"
                      ></v-select>
                      <v-text-field
                          class="custom_input_field_color"
                          label="Email Address *"
                          variant="outlined"
                          placeholder="Type email here"
                          v-model="user.emailAddress"
                          density="comfortable"
                          required
                          :rules="emailRules"
                      ></v-text-field>
                      <div v-for="(phoneNo, index) in phoneNumbers" :key="index">
                        <v-row class="mb-2">
                          <v-col cols="8">
                            <v-text-field v-model="phoneNumbers[index]" label="Phone Number *" variant="outlined" density="comfortable" class="custom_input_field_color" placeholder="Create sub category" :rules="phoneRules"></v-text-field>
                          </v-col>
                          <v-col cols="auto" class="align-center justify-center">
                            <v-btn density="default" class="green-text" variant="outlined" icon="mdi-plus-thick" @click="addPhoneNo"></v-btn>
                          </v-col>
                          <v-col cols="auto" class="align-center justify-center">
                            <v-btn density="default" class="red-text-btn" variant="outlined" icon="mdi-minus-thick" @click="removePhoneNo(index)" v-if="phoneNumbers.length > 1"></v-btn>
                          </v-col>
                        </v-row>
                      </div>

                      <v-text-field
                          class="custom_input_field_color"
                          density="comfortable"
                          label="NIC *"
                          variant="outlined"
                          placeholder="Type NIC number here"
                          v-model="user.nic"
                          required
                          :rules="nicRules"
                      ></v-text-field>

                      <v-select
                          class="custom_input_field_color"
                          density="comfortable"
                          label="Gender *"
                          variant="outlined"
                          :items="gender"
                          v-model="user.gender"
                          required
                          clearable
                          :rules="[v => !!v || 'Gender is required']"
                          placeholder="Select Gender"
                      ></v-select>
                    
          
                </v-col>
                <v-col cols="6" class="px-6">
                      <v-select
                          class="custom_input_field_color"
                          density="comfortable"
                          label="Province *"
                          variant="outlined"
                          placeholder="Select province"
                          v-model="user.province"
                          :items="provinces"
                          item-text="label"
                          item-value="value"
                          :loading="provinceLoading"
                          clearable
                          required
                          :rules="[v => !!v || 'Province is required']"
                          @update:modelValue="(value) => {
                          user.province = value;
                          if (!value) {
                            user.district = null;
                            user.dsd = null;
                            user.gnd = null;
                            districts = [];
                            dsdList = [];
                            gndList = [];
                          } else {
                            getAllDistrictAccProv(value);
                          }
                        }"
                      ></v-select>

                      <v-select
                          class="custom_input_field_color"
                          density="comfortable"
                          label="District *"
                          variant="outlined"
                          placeholder="Select district"
                          v-model="user.district"
                          :items="districts"
                          item-text="label"
                          item-value="value"
                          :loading="mDistrictLoading"
                          clearable
                          required
                          :rules="[v => !!v || 'District is required']"
                          @update:modelValue="(value) => {
                            user.district = value;
                            if (!value) {
                              user.dsd = null;
                              user.gnd = null;
                              dsdList = [];
                              gndList = [];
                            } else {
                              getAllDsd(value);
                            }
                          }"
                      ></v-select>

                      <v-select
                          class="custom_input_field_color"
                          density="comfortable"
                          label="DSD"
                          variant="outlined"
                          placeholder="Select DSD"
                          clearable
                          v-model="user.dsd"
                          :items="dsdList"
                          item-text="label"
                          item-value="value"
                          :loading="mDSDLoading"
                          @update:modelValue="(value) => {
                            user.dsd = value;
                            if (!value) {
                              user.gnd = null;
                              gndList = [];
                            } else {
                              getAllGnd(value);
                            }
                          }"
                      ></v-select>

                      <v-select
                          class="custom_input_field_color"
                          label="GND"
                          clearable
                          density="comfortable"
                          variant="outlined"
                          :items="gndList"
                          v-model="user.gnd"
                          :loading="gndLoading"
                          placeholder="Select GND"
                          @update:modelValue="(value) => {
                            user.gnd = value;
                            if (!value) {
                              user.gnd = null;
                            }
                          }"
                      ></v-select>

                      <v-textarea
                          class="custom_input_field_color"
                          density="comfortable"
                          label="Address *"
                          variant="outlined"
                          placeholder="Type user address here"
                          v-model="user.address"
                          required
                          rows="2"
                          :rules="[v => !!v || 'Address is required']"
                      ></v-textarea>

                      <v-card flat>
                        <div
                            class="drop-area mb-6"
                            @drop.prevent="onDrop"
                            @dragover.prevent="onDragOver"
                            @click="triggerFileInput"
                        >
                          <input
                              ref="fileInput"
                              type="file"
                              multiple
                              accept="image/*"
                              class="hidden-file-input"
                              @change="onFileChange"
                          />
                          <div v-if="previewImages.length > 0" class="image-card-container">
                            <v-card class="image-card">
                              <v-img
                                  :src="previewImages[0]"
                                  alt="Image preview"
                                  class="image-preview"
                              ></v-img>
                              <v-btn
                                  icon
                                  size="x-small"
                                  @click.stop="removeImage(0)"
                                  class="remove-icon"
                              >
                                <v-icon size="small" color="red">mdi-delete</v-icon>
                              </v-btn>
                            </v-card>
                          </div>
                          <div v-if="previewImages.length === 0" class="placeholder-container">
                            <v-icon size="36">mdi-cloud-upload</v-icon>
                            <span>Drag your photo here OR click to browse from device</span>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
              </v-row>

            </v-form>
          </v-card-text>

            <v-divider thickness="2"></v-divider>

            <v-card-actions class="float-end">
              <v-btn variant="outlined" class="red-background-text mr-3"  @click="registerUser">REGISTER USER</v-btn>
              <v-btn variant="outlined" class="gray-background-text" @click="cancel()">CANCEL</v-btn>
            </v-card-actions>
          
    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>
  </v-card>
</v-container>
</v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserRegistration',
  data() {
    return {
      centerLoading: false,
      user: {
        fullName: '',
        userName: '',
        userType: '',
        emailAddress: '',
        phoneNumber: [""],
        nic: '',
        gender: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        address: '',
        image: [],
        profilePhoto: null,
      },

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      phoneRules: [
        v => !!v || 'Phone number is required', 
        v => /^\d{10}$/.test(v) || 'Must be a valid 10-digit number' 
      ],
      emailRules: [
        v => !!v || 'Email is required', 
        v => /.+@.+\..+/.test(v) || 'Must be a valid email' 
      ],
      nicRules: [
        v => !!v || 'NIC is required', 
        v => /^[0-9]{9}[vVxX]$|^[0-9]{12}$/.test(v) || 'Must be a valid NIC number'
      ],

      e1: false,
      e2: false,
      previewImages: [],

      phoneNumbers: [""],
      userTypes: [],
      //provinces: ['Province 1', 'Province 2'], // Example provinces
      //districts: ['District 1', 'District 2'], // Example districts
      //dsds: ['DSD 1', 'DSD 2'], // Example DSDs
      gnds: ['GND 1', 'GND 2'], // Example GNDs
      gender: ['Male', 'Female'],


      mDistrictLoading: false,
      provinceLoading:false,
      gndLoading:false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
    };
  },
  mounted() {
    this.getAllProvinces();
    this.getAllUserRoles();
    this.generateRegistrationNumber();
  },

  methods: {

    generateRegistrationNumber() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      axios.post('/api/getLatestRegistrationNumber', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            const latestNumber = response.data.latestNumber;
            const nextNumber = (parseInt(latestNumber.replace('REG-', '')) + 1).toString().padStart(4, '0');
            this.user.registrationNumber = `REG-${nextNumber}`;
          })
          .catch(error => {
            console.error('Error fetching the latest registration number:', error);
            // this.user.registrationNumber = 'REG-0000';
            this.user.registrationNumber = Math.random().toString(36).substring(2, 10).toUpperCase();
          });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;

            console.log('User role fk:', usrRole);
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.user_role
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.provinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.provinceLoading = false;
            }
          })
          .catch(() => {
            this.provinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.gndLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.gndLoading = false;
            }
          })
          .catch(() => {
            this.gndLoading = false;
          });
    },


    backPageRoute() {
      this.$router.back();
    },

    addPhoneNo() {
      this.phoneNumbers.push('');
    },

    removePhoneNo(index) {
      this.phoneNumbers.splice(index, 1);
    },

    // Method to convert a file to a base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const result = reader.result;
          // Remove the "data:image/png;base64," part
          const base64String = result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = error => reject(error);
      });
    },

    validateForm() {

    if (!this.user.fullName || !/^[a-zA-Z\s]+$/.test(this.user.fullName)) {
      this.showSnackbar('Full Name is required and should contain only letters and spaces.', 'error');
      return false;
    }
    if (!this.user.userName || !/^[a-zA-Z0-9\s]+$/.test(this.user.userName)) {
      this.showSnackbar('Display Name is required and should contain only letters, numbers, and spaces.', 'error');
      return false;
    }
    // Check if the username already exists in the list
    // if (this.userRolesCheckDetails.name.includes(this.user.userName)) {
    //   this.showSnackbar('Display Name already exists.', 'error');
    //   return false;
    // }
    if (!this.user.userType) {
      this.showSnackbar('User Type is required.', 'error');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!this.user.emailAddress || !emailRegex.test(this.user.emailAddress)) {
      this.showSnackbar('A valid Email Address is required.', 'error');
      return false;
    }

    // Phone Number Validation
    const phonePattern = /^[0-9]{10}$/; // Adjust based on your phone format
    if (!this.phoneNumbers || this.phoneNumbers.length === 0) {
      this.showSnackbar('At least one phone number is required.', 'error');
      return false;
    }

    for (let i = 0; i < this.phoneNumbers.length; i++) {
      if (!this.phoneNumbers[i] || !phonePattern.test(this.phoneNumbers[i])) {
        this.showSnackbar(`Phone Number ${i + 1} is invalid. It should contain exactly 10 digits.`, 'error');
        return false;
      }
    }
 
    const nicPattern = /^(\d{9}[VvXx]|[0-9]{12})$/;
    if (!this.user.nic || !nicPattern.test(this.user.nic)) {
      this.showSnackbar(
          'NIC must be either 9 digits followed by "V/v" or "X/x" (e.g., 123456789V or 123456789X) or exactly 12 digits (e.g., 123456789012).',
          'error'
      );
      return false;
    }

    if (!this.user.gender) {
      this.showSnackbar('Gender is required.', 'error');
      return false;
    }

    if (!this.user.province) {
      this.showSnackbar('Province is required.', 'error');
      return false;
    }

    if (!this.user.district) {
      this.showSnackbar('District is required.', 'error');
      return false;
    }

   

    if (!this.user.address) {
      this.showSnackbar('Address is required.', 'error');
      return false;
    }

    return true;
    },

    async registerUser() {

      if (!this.validateForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      try {
        this.centerLoading = true;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");
        // Form validation
        if (this.$refs.form && this.$refs.form.validate()) {
          const formData = new FormData();
          formData.append("userId", userId);
          formData.append("fullName", this.user.fullName);
          formData.append("userName", this.user.userName);
          formData.append("userType", this.user.userType);
          formData.append("emailAddress", this.user.emailAddress);
          formData.append("nic", this.user.nic);
          formData.append("gender", this.user.gender);
          formData.append("province", this.user.province);
          formData.append("district", this.user.district);
          formData.append("dsd", this.user.dsd);
          formData.append("gnd", this.user.gnd);
          formData.append("address", this.user.address);
          formData.append("mobileNumber", this.phoneNumbers);
         
          if (this.user.image && this.user.image.length > 0) {
            await this.convertToBase64(this.user.image[0]).then(base64String => {
              formData.append("image", base64String);
              console.log("image", base64String);
            });
          }


      

          // API call
          const response = await axios.post(
              "/api/createUser",
              formData,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              }
          );

          if (response.status === 201) {
          this.showSnackbar("NEW USER WAS REGISTERED SUCCESSFULLY!", "success");
          this.centerLoading = false;
          // window.location.href = '/user-registration';
          setTimeout(() => {
            window.location.href = '/user-registration';
          }, 1000);
          } else {
            this.showSnackbar("Unexpected response status.", "warning");
          }
        }
      } catch (error) {
        // Handle errors
        if (error.response) {
          if (error.response.status === 400 && error.response.data.error === 'User already exists') {
            this.showSnackbar('User already exists. Please use a different NIC.', "error");
          } 
          if (error.response.status === 400 && error.response.data.error === 'Display name already exists') {
            this.showSnackbar('Display name already exists. Please use a different Display Name.', "error");
          }else {
            console.error('Error:', error.response.data);
            this.showSnackbar("Failed to register user. Please check details", "error");
          }
        } else if (error.request) {
          console.error('Error request data:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.centerLoading = false;
      }
    },


    cancel() {
      // Reset form fields
      this.user = {
        fullName: "",
        userName: "",
        userType: "",
        emailAddress: "",
        nic: "",
        gender: "",
        province: "",
        district: "",
        dsd: "",
        gnd: "",
        address: "",
        image: [],
        phoneNo: "",
      };
      this.phoneNumbers = [];
      this.previewImages = [];
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },


    onFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {

        this.handleFiles(files[0]);


        this.$refs.fileInput.value = null;
      }
    },
    handleFiles(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        alert('Only image files are allowed!');
        return;
      }

      // Clear previous images
      this.user.image = [];
      this.previewImages = [];

      // Add the new image
      this.user.image.push(file);
      this.previewImages.push(URL.createObjectURL(file));
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage(index) {
      this.previewImages.splice(index, 1);
      this.user.image.splice(index, 1);


      this.$refs.fileInput.value = null;
    },
    onDrop(event) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.handleFiles(files[0]);

        this.$refs.fileInput.value = null;
      }
    },
    onDragOver(event) {
      event.preventDefault();
    },

    createUserRole() {
      this.$router.push({path: '/create-user-role'});
    },

  }
};
</script>

<style>

@import "../../styles/user_management/user_registration.scss";
</style>
    
<template>

<v-app id="app_live_tracking">
  <v-container fluid>
    <v-row>
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">TRACKING ANALYSIS</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analysis of daily tracking data of officers.</p>
        </v-col>
        <v-col class="px-4 mt-7" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="back()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card  class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">

        <v-card-text>
          <v-row align="center" justify="center">

            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card  class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md" @click="allAgents()">
                <v-row>
                  <v-col cols="12" md="6" style="font-size: 12px">
                      <div class="mt-2 ml-3">ALL OFFICERS</div>
                      <div class=" ml-3 font-weight-bold text-red " style="font-size: 20px">{{ this.allOfficerCount }}</div>
                  </v-col>
                  <v-col cols="12" md="6" >
                    <v-img style="width: 5vw; height: 9vh" src="@/WEB/ICONS/all_vechile.png" right color="red" class="bg-red-lighten-5" ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card  class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md" @click="activeAllAgents()" >
                      <v-row>
                        <v-col cols="12" md="6" style="font-size: 12px;color: #057b0a">
                          <div class="mt-2 ml-3">ACTIVE</div>
                          <div class=" ml-3 font-weight-bold text-black " style="font-size: 20px">{{ this.activeOfficerCount }}</div>
                        </v-col>
                        <v-col cols="12" md="6" >
                          <v-img style="width: 5vw; height: 9vh" src="@/WEB/ICONS/active_vechile.png" right color="green" class="bg-green-lighten-2" ></v-img>
                        </v-col>
                      </v-row>
                    </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-card  class="mx-auto" elevation="0"  border="sm opacity-1" rounded="md" @click="deactiveAgent()" >
                <v-row>
                  <v-col cols="12" md="6" style="font-size: 12px">
                    <div class="mt-2 ml-3">INACTIVE</div>
                    <div class=" ml-3 font-weight-bold text-red " style="font-size: 20px">{{ this.deactiveOfficerCount  }}</div>
                  </v-col>
                  <v-col cols="12" md="6" >
                    <v-img style="width: 5vw; height: 9vh" src="@/WEB/ICONS/inactive_vechile.png" right color="red" class="bg-red-lighten-5" ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            </v-row>


            <v-divider class="mt-4 mb-4"></v-divider>

            
        </v-card-text>

        <v-card-text>
          
              <v-row class="mapRow" style="margin-bottom: 30px; height: 100%; width: 100%;" >
                <!-- Map Section -->
                <v-col cols="8" >
                 
                    <Map 
                      class="d-flex map" 
                      :center="mapCenter" 
                      :zoom="7" 
                      style="width: 100%; height: 76vh;">
                      
                      <Marker :key="index"
                        v-for="(m, index) in markers" 
                        :position="m.position" 
                        @click="selectAgent(m)"
                        :icon="{
                                url: require('@/assets/ICONS/vechile_icon.png'), 
                                scaledSize: { width: 40, height: 35 },
                            }"
                            

                      >
                      
                      <InfoWindow :opened="selectedAgentsamplee && selectedAgentsamplee.id === m.id"  class="d-flex gm-ui-hover-effect" @close="dialog=false"  >
                        <div style="font-size: 14px; font-weight: bold; color: black; background: white; padding: 5px; border-radius: 5px;">
                          {{ m.agnet_name }}
                        </div>
                      </InfoWindow>

                        <!-- <InfoWindow :opened="selectedAgentsamplee && selectedAgentsamplee.id === m.id"  class="d-flex gm-ui-hover-effect" @close="dialog=false"  >
                        
                          <v-row >
                            <v-card class="map-card mt-0">
                              <v-card-title>
                                <v-avatar class="mr-3">
                                  <v-img :src="officerDetails.user_image"></v-img>
                                </v-avatar>
                                <span>{{ officerDetails.name }}</span>
                              </v-card-title >
                              <v-card-text>
                                <v-card-text>{{ officerDetails.location }}<br>
                                  <span>Last updated: {{ officerDetails.lastUpdated }}</span></v-card-text>
                                <v-divider></v-divider>
                                <v-divider class="my-3"></v-divider>
                              </v-card-text>



                              <v-card class="mt-10 d-flex " style="height: 35vh; width: 600px"  >
                                <v-row>
                                  <v-col cols="12" md="9">
                                    <v-card class="dataCard" style="height: 34vh; width: 450px" variant="flat">
                                      <v-card-text>
                                        <div class="font-weight-bold ms-1 mb-2">Agent name  : {{outletDetails.name}}</div>
                                        <v-timeline align="start" density="compact">
                                          <v-timeline-item
                                              v-for="agent in agents"
                                              :key="agent.id"
                                              dot-color="red"
                                              size="x-small"
                                          >
                                            <v-row v-for="agent in outletDetails" :key="agent.id">
                                              <v-col cols="12" md="1">
                                                <v-icon  color="red">mdi-check-circle-outline</v-icon>
                                              </v-col>
                                              <v-col cols="12" md="10">
                                                <v-row >
                                                  <v-card-title style="font-size: 12px" class="mt=0 ">{{agent.institute_name}}</v-card-title>
                                                  <v-card-subtitle style="font-size: 12px" class="mt-0">{{agent.address}}</v-card-subtitle>
                                                </v-row>
                                                <v-row >
                                                  <v-card-text>{{agent.visited_date}}</v-card-text>
                                                  <v-chip v-if= "agent.locationType === 'startLocation' " color="blue" variant="flat" size="x-small" class="mt-3">
                                                    Start Location
                                                  </v-chip>
                                                  <v-chip v-if= "agent.locationType === 'endLocation' " color="green" variant="flat" size="x-small" class="mt-4">
                                                    End Location
                                                  </v-chip>
                                                </v-row>
                                                <v-divider></v-divider>
                                              </v-col>
                                            </v-row>

                                          </v-timeline-item>
                                        </v-timeline>
                                      </v-card-text>
                                    </v-card>

                                  </v-col>
                                </v-row>

                              </v-card>




                            </v-card>
                          </v-row>
                        </InfoWindow> -->


                      </Marker>
                    </Map>
                
                </v-col>

                <!-- Agents List Section -->
                <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                <div class="mt-8">
                  <p class="custom_page_component_main_title text-subtitle-1 font-weight-bold text-uppercase">List of all live officers</p>
                  <!-- <p class="custom_page_component_main_title text-caption font-weight-medium">List of all live officers</p> -->
                  <v-divider class="mt-4 mb-4"></v-divider>
                  <!-- <v-text-field clearable label="Search" placeholder="Search Agents"
                                variant="outlined"  density="comfortable"   class="custom_input_field_color"
                  ></v-text-field> -->
                </div>
                <div style="height: 340px !important; overflow-y: auto!important;">
                  <v-card class="mx-auto mb-3 custom_card_box mr-3" elevation="0" v-for="agent in this.activeAgents" :key="agent.id"  border="sm opacity-1" rounded="md">
                  <template v-slot:prepend>
              
                    <v-img :src="`${imageURL}/${userImageOfficer}`" aspect-ratio="1" class="user_image_view"></v-img>
                    
                   
                  </template>
                  <template v-slot:title>
                    <p class="float-end text-body-2 font-weight-bold mb-1">{{ agent.name }}</p>
                  </template>
                  <template v-slot:item>
                    <p class="float-end font-weight-medium custom_page_main_sub_title_very_small_red text-caption mb-3">{{ agent.registration_num }}</p>
                  </template>
                </v-card>
                </div>
            </v-col>
              </v-row>
            
        </v-card-text>

        

      </v-card>

  </v-container>
</v-app>
 
</template>

<script>

import { Map, Marker, google, InfoWindow  } from '@fawmi/vue-google-maps';
import axios from "axios";


export default {
  name: 'TrackingAnalysis',
  components: {
    Map, Marker, InfoWindow
  },
  data() {
    return {

      selectedAgentsamplee: null,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],

      mDistrictLoading: false,
      provinceLoading:false,
      gndLoading:false,
      mDSDLoading: false,
      mGNDLoading: false,

      outlet: {
        province: '',
        district: '',
        dsd: '',
        gnd: '',

      },

      allOfficerCount:"",
      activeOfficerCount:0,
      deactiveOfficerCount:0,
      userImageOfficer:"",
      defaultImage:require('@/assets/ASSETS/PERSONS/person-3.jpg'),
      

      agents: [
        {
          id: "",
          name:"",
          regNum: "",
          vehicle: "",
          avatar: "",
          trackingEnabled: true,
          position: { lat: null, lng: null },
          location: "",
          lastUpdated: "",

        }
      ],

      mapCenter: { lat: 6.753174417177423, lng: 80.76603582220012 },
      selectedAgent: {}, // Initially empty, will be set when an agent is selected
      dialog: false,
      
      markers: [
        {
          agentId:"",
          itineraryId:"",
          outletId:"",
          position: {
            lat:"" , lng: ""
          },
        }
      ],

      // carIcon: {
      //   url: 'path/to/your/car-image.png', 
      //   // scaledSize: new google.maps.Size(50, 50), 
      //   // origin: new google.maps.Point(0, 0), 
      //   // anchor: new google.maps.Point(25, 50) 
      // }

      officerDetails:{},
      outletDetails:[],
      activeAgents:[],

      trips: [
        { id: "", destination: "", time: "" },
        { id: "", destination: "", time: "" },
      ],

    };
  },
  

  watch(){

  },

  mounted() {

    this.imageURL = process.env.VUE_APP_IMAGE_PUBLIC_URL;

    this.getAllProvinces();
    this.getAllAgentAndCount();

    if (typeof google !== 'undefined' && google.maps) {
    // API is loaded, you can safely call updateUser now or later as needed
      console.log('fkkkk');
      } else {
        console.error('Google Maps API is not loaded yet.');
      }
      
        window.Echo.channel('TracingApp')
            .listen('LocationUpdated', (e) => {
                console.log('User updated:', e.location);
                // Update the dashboard with the new user data
                this.updateUser(e.location);
                this.getAllAgentAndCount();
            });
    
    },

  methods: {
   

    updateUser(vehicle) {
      console.log('tracking',vehicle);
            const position = { 
              lat: vehicle.latitude, 
              lng: vehicle.longitude 
            };

            console.log('tracking',position);

            this.markers = [
              {
                agentId:vehicle.agent_id,
                itineraryId:vehicle.itinerary_id,
                outletId:vehicle.outlet_id,
                agent_regnum: vehicle.agent_regnum,
                agnet_name: vehicle.agnet_name,
                position: {
                  lat: parseFloat(position.lat),
                  lng: parseFloat(position.lng),
                }
              }
            ];


            const existingAgent = this.activeAgents.find(agent => agent.agentId === vehicle.agent_id);
            if (!existingAgent) {
              this.activeAgents.push({
                agentId: vehicle.agent_id,
                registration_num: vehicle.agent_regnum,
                name: vehicle.agnet_name,
              });
              
            }
          
            // Call API to fetch officer image
            this.getOfficerImage(vehicle.agent_id);
          console.log('fk', this.activeAgents);
                  
        },


    getOfficerImage(agent){

      console.log('fkfkfll',agent);

          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem("token");

          const formData = new FormData();
          formData.append("userId", userId);
          formData.append("agentId", agent);


          axios.post('/api/getOfficerImage',formData,{
            headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
              .then(response => {
                if (response.data.http_status === 'success') {
                  const UserDetails = response.data.data;
                  console.log("user Image", UserDetails);
                  this.userImageOfficer = UserDetails.user_image;
                  console.log("fuckingimage", this.userImageOfficer);
              
                }
              })
              .catch(() => {
                console.log("fk2");
              });

    },
    

    selectAgent(agent) {

      console.log('fkfkfll',agent);

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("agentId", agent.agentId);
      formData.append("itineraryId", agent.itineraryId);
      formData.append("outletId", agent.outletId);

      axios.post('/api/getDetaisFromAgent',formData,{
        headers: {
                'Authorization': `Bearer ${token}`
            }
        })
          .then(response => {
            if (response.data.http_status === 'success') {
              
              this.officerDetails = response.data.officerDetails;
              console.log("fk1",this.officerDetails);
              this.outletDetails = response.data.outletDetails;
              console.log("fk2", this.outletDetails);



              this.selectedAgentsamplee = agent;
              this.dialog = true;
            }
          })
          .catch(() => {
            console.log("fk2");
          });


      // this.selectedAgent = agent;
      this.dialog = true;
    },
    toggleEnable(agent) {
      agent.trackingEnabled = !agent.trackingEnabled;
    },


    allAgents() {
      this.$router.push('/all-officer');
    },
    activeAllAgents(){
      this.$router.push('/active-officer');
    },
    deactiveAgent(){
      this.$router.push('/inactive-officer');
    },

    
    back(){
      this.$router.push('/live-tracking');
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.provinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.provinceLoading = false;
            }
          })
          .catch(() => {
            this.provinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.gndLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.gndLoading = false;
            }
          })
          .catch(() => {
            this.gndLoading = false;
          });
    },

    // Get All Agent Details and Active Deactive Ofiicer Count
    getAllAgentAndCount() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 

      axios.post('/api/getAllAgentAndCount', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.allOfficerCount = response.data.ofiicerDetails;
          console.log('Officer details',this.allOfficerCount );
          this.activeOfficerCount = response.data.getActiveOfiicer;
          this.deactiveOfficerCount = response.data.getDeactiveOfiicer;

          console.log('data1',this.activeOfficerCount);
          console.log('data2',this.deactiveOfficerCount);
        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
      },

    
  },
};
</script>

<style>
  @import "../../styles/tracking_management/live_tracking.scss";

  .user_image_view{
      width: 60px;
      height: 60px;
      margin-left: 0px;
      border: 2px solid #dcdcdc;
      border-radius: 50%;
      padding: 4px !important;
      background-color: white;
    }
</style>










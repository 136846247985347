<template>
  <v-app id="app_add_leads">

    <v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container fluid>


      <v-row>
        <v-col class="mt-3" cols="6">
          <h3 class="page-title">ADD ITINERARY</h3>
          <h5 class="light-gray-text">Add itineraries according to your requirements.</h5>
          <v-card-title>

          </v-card-title>
        </v-col>
        <v-col class="px-4 mt-5" cols="6">
          <v-row class="tool-bar-style">
            <!-- <v-btn class="red-text" size="x-large" variant="text" @click="leadsBulkUploadDialog = true">
              <v-icon>mdi-file-document</v-icon>&nbsp;&nbsp;ITINERARIES BULK UPLOAD
            </v-btn> -->
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>


      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">

        <template v-slot:title>
          <p class="custom_page_component_main_title pa-3">ADD ITINERARY DETAILS</p>
        </template>
        <v-divider thickness="2"></v-divider>

        <v-card-text>

          <v-form ref="form">
            <v-row class="mt-2">
              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-text-field
                    v-model="lead.title"
                    class="mb-2"
                    label="Itinerary Title *"
                    placeholder="Type title here"
                    variant="outlined"
                    :rules="[
                    v => !!v || 'Title is required',
                    v => /^[a-zA-Z0-9\s]+$/.test(v) || 'Title can only contain letters and numbers'
                  ]"
                ></v-text-field>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="lead.province"
                    :items="provinces"
                    :loading="provinceLoading"
                    class="mb-2"
                    item-text="label"
                    item-value="value"
                    label="Province"
                    placeholder="Select province"
                    variant="outlined"
                    @update:modelValue="getAllDistrictAccProv"
                    clearable
                ></v-select>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="lead.district"
                    :disabled="isDisabledDistrictList"
                    :items="districts"
                    :loading="mDistrictLoading"
                    class="mb-2"
                    item-text="label"
                    item-value="value"
                    label="District"
                    placeholder="Select district"
                    variant="outlined"
                    @update:modelValue="getAllDsd"
                    clearable

                ></v-select>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="lead.dsd"
                    :disabled="isDisabledDSDLIST"
                    :items="dsdList"
                    :loading="mDSDLoading"
                    class="mb-2"
                    item-text="label"
                    item-value="value"
                    label="DSD"
                    placeholder="Select divisional"
                    variant="outlined"
                    @update:modelValue="getAllGnd"
                    clearable

                ></v-select>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="lead.gnd"
                    :disabled="isDisabledGNDList"
                    :items="gndList"
                    :loading="gndLoading"
                    class="mb-2"
                    label="GND"
                    clearable

                    variant="outlined"
                    @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
                ></v-select>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="outlet_type"
                    :items="outlet_types"
                    class="mb-2"
                    item-title="name"
                    item-value="id"
                    label="Outlet Type *"
                    placeholder="Select Outlet Type"
                    variant="outlined"
                    multiple
                    :rules="[v => v && v.length > 0 || 'At least one outlet type is required']"
                    @update:modelValue="getOutletDetails(outlet_type)"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="outletValues"
                    :items="outletList"
                    class="mb-2"
                    item-title="outlet_name"
                    item-value="id"
                    label="Outlet *"
                    clearable
                    placeholder="Select Outlet"
                    multiple
                    variant="outlined"
                    :rules="[v => v && v.length > 0 || 'At least one outlet is required']"
                    @update:modelValue="handleOutletChange"
                ></v-select>
              </v-col>


              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-select
                    v-model="assignOfficer"
                    :items="officerDetails"
                    class="mb-2"
                    item-title="name"
                    item-value="id"
                    label="Assign Officer *"
                    clearable
                    placeholder="Select agent"
                    variant="outlined"
                    :rules="[v => !!v || 'Assign Officer is required']"
                ></v-select>
                <v-alert v-if="officerAvailabilityError" type="error" dense>
                  {{ officerAvailabilityError }}
                </v-alert>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                <v-date-input
                    v-model="lead.assignDate"
                    color="red"
                    label="Assign Date *"
                    max-width="580"
                    persistent-placeholder
                    placeholder="Select a Date"
                    variant="outlined"
                    :rules="[v => !!v || 'Assign Date is required']"
                    :min="new Date().toISOString().split('T')[0]"
                ></v-date-input>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="1" lg="1">
                <v-btn
                              class="custom_btn_secondary"
                              icon rounded
                              variant="outlined"
                              v-if="lead.assignDate"
                              @click="resetDateRange()"
                          >
                <v-icon>mdi mdi-refresh</v-icon>
                            <v-tooltip activator="parent" location="top">Reset Assign Date</v-tooltip>
                          </v-btn>
              </v-col>
            </v-row>


            <!--            <v-row>-->
            <!--              <v-col cols="12" xs="12" sm="12" md="12" lg="12">-->
            <!--                <v-card-text>-->
            <!--                Itineraries Description *-->
            <!--              </v-card-text>-->
            <!--              <v-divider></v-divider>-->

            <!--              <v-card flat width="100%">-->
            <!--                -->
            <!--                  <v-textarea-->
            <!--                      v-model="lead.description"-->
            <!--                      class="mb-2"-->
            <!--                      placeholder="Write a description here..."-->
            <!--                      :style="textareaStyles"-->
            <!--                      variant="outlined"-->
            <!--                      :rules="[v => !!v || 'Description is required']"-->
            <!--                  ></v-textarea>-->
            <!--             -->
            <!--              </v-card>-->
            <!--              </v-col>-->

            <!--              -->
            <!--             -->
            <!--            </v-row>-->

            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <v-divider></v-divider>
                <h5 class="px-3 py-3">SELECTED ITINERARY LIST</h5>
                <h6 class="px-3 pb-3 light-gray-text">The following table shows all the itineraries related to your
                  choice & you can add only the itinerary you like.</h6>
                <v-card class="ma-3" style="font-size: 12px">
                  <v-toolbar color="white">

                    <v-col cols="9">
                      <v-text-field
                          v-model="search"
                          append-inner-icon="mdi-magnify"
                          variant="outlined"
                          placeholder="Search Itinerary"
                          label="Search"
                          hide-details
                          clearable
                      ></v-text-field>
                    </v-col>
                  </v-toolbar>
                  <v-divider></v-divider>

                  <v-data-table
                      style="font-size: 12px"
                      :headers="headers"
                      :items="filteredItems"
                      :items-per-page="itemsPerPage"
                      :search="search"
                      class="data-table elevation-1"
                  >
                    <template v-slot:[`item.checklist`]="{ item }">
                      <v-btn
                          class="mr-6 bg-green-lighten-4"
                          color="green"
                          icon
                          rounded="lg"
                          size="small"
                          variant="outlined"
                          width="80"
                          @click="viewChecklist(item)"
                      >
                        <v-icon color="green">mdi mdi-plus-circle</v-icon>
                        ADD
                      </v-btn>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn
                          color="red"
                          icon
                          @click="deleteItem(item)"
                      >
                        <v-icon>mdi mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>


                </v-card>
              </v-col>
            </v-row>

          </v-form>

        </v-card-text>
        <v-divider thickness="2"></v-divider>

        <v-card-actions class="float-end">
          <v-btn
              :disabled="officerAvailability === 1"
              class="red-background-text mr-3"
              variant="outlined"
              @click="addLead"
              :loading="addItinararyLoading"
          >
            ADD ITINERARY
          </v-btn>
          <v-btn class="gray-background-text" variant="outlined" @click="backPageRoute()">CANCEL</v-btn>
        </v-card-actions>

      </v-card>


      <!-- View bulk upload Dialog -->
      <v-dialog v-model="leadsBulkUploadDialog" fullscreen>
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="leadsBulkUploadDialog = false">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <v-card class="pa-3 mx-6">
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-6">UPLOAD SPREDSHEET</v-label>
              </v-card>
              <v-card class="px-3 mx-6 mt-3">
                <v-row class="ma-0">
                  <v-col cols="5">
                    <h5 class="px-3">Upload your itinerary bulk data set using excel or a csv file.</h5>
                    <v-col cols="12">

                      <!-- <v-file-input
                        label="Upload your Excel or CSV file"
                        accept=".csv, .xls, .xlsx"
                        @update:modelValue="handleFileUpload"
                        variant="outlined"
                        show-size
                        v-model="excelFiles"
                        :show-size="1000"
                      ></v-file-input> -->
                      <v-file-input
                          accept=".csv, .xls, .xlsx"
                          show-size counter clearable
                          color="#086EFF" variant="outlined"
                          @change="handleFileUpload"
                          label="Upload your Excel or CSV file"
                      >

                      </v-file-input>

                    </v-col>
                  </v-col>
                  <v-col class="d-flex align-center justify-center" cols="1">
                    <v-divider :thickness="2" vertical></v-divider>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="3">

                      </v-col>
                      <v-col cols="9">
                        <v-card class="d-flex align-center justify-space-between pa-4 mt-3" color="grey-lighten-3" flat
                                width="100%">
                          <div class="mr-4">
                            <h4 class="mb-0">DOWNLOAD SAMPLE TEMPLATE</h4>
                          </div>
                          <v-btn class="red-background-text btn-border" size="x-large" variant="text"
                                 @click="downloadTemplate">
                            DOWNLOAD
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-card v-if="bulkItems.length > 0" class="ma-3" flat>
                  <v-data-table
                      :headers="bulkHeaders"
                      :items="bulkItems"
                      :items-per-page="itemsPerPage"
                      class="elevation-1"
                  >
                    <template v-slot:[`item.subcatagory`]="{ item }">
                      <span class="red--text">{{ item.subcatagory }}</span>
                    </template>
                    <template v-slot:[`item.assignAgent`]="{ item }">
                      <span>{{ item.assignAgent }}<br/><span class="red--text">{{ item.regNum }}</span></span>
                    </template>

                  </v-data-table>
                </v-card>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="bulkItems.length === 0" class="red-background-text btn-border mx-3" size="x-large"
                         variant="text" @click="uploadBulkLead">Upload
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>


      <!-- Checklist Modal -->
      <v-dialog v-model="checklistModel" fullscreen>
        <v-card>
          <v-container>
            <v-row class="pa-10">
              <v-col class="mt-3" cols="12" md="7">
                <p class="custom_page_main_title">CREATE CHECKLIST</p>
                <p class="custom_page_main_sub_title" style="opacity: 0.6">
                  Create checklist according to your requirements.
                </p>
              </v-col>
              <v-col class="px-4 mt-7" cols="12" md="5">
                <v-row class="custom_tool_bar_style">
                  <v-btn class="gray-text" size="x-large" variant="text" @click="checklistModel = false">
                    <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>

            <v-card-text>
              <v-row>
                <h5 class="card-h4-title px-3 mt-4 mb-4">ITINERARY ANALYSIS</h5>
                <v-divider thickness="2"></v-divider>
              </v-row>

              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                      v-model="mainCategory"
                      variant="outlined"
                      label="Main Category *"
                      placeholder="Enter Category"
                      class="mb-2 ml-5"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                      style="margin-left: 20px"
                      variant="outlined"
                      class="bg-red-lighten-4"
                      color="red"
                      width="50"
                      height="50"
                      @click="addMainCategory"
                  >
                    <v-icon color="red">mdi mdi-plus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-for="(item, index) in mainCategoryArray" :key="index" class="ml-10 mr-10">
                <v-col cols="12">
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            style="margin-left: 20px; width: auto; height: 40px; margin-right: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            variant="outlined"
                            class="bg-red-lighten-5"
                            color="red"
                            @click="addItemToChecklist(index)"
                        >
                          {{ item.category }}
                          <v-icon color="red" class="ml-3">mdi mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.category }}</span>
                    </v-tooltip>

                    <v-btn class="d-flex justify-end" icon variant="flat" @click="removeMainCategory(index)">
                      <v-icon color="red" class="mb-3">mdi-trash-can</v-icon>
                    </v-btn>
                  </v-row>

                  <v-row v-for="(checklistItem, itemIndex) in item.checklistItems" :key="itemIndex" style="width: 100%">
                    <v-col cols="12" md="10">
                      <v-text-field
                          v-model="item.checklistItems[itemIndex]"
                          variant="outlined"
                          placeholder="Enter item"
                          :style="{ width: '100%' }"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" style="display: flex;">
                      <v-btn icon variant="flat" style="margin-right: 10px"
                             @click="removeItemFromChecklist(index, itemIndex)">
                        <v-icon color="red">mdi-trash-can</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider thickness="2"></v-divider>

            <v-card-actions class="float-end">
              <v-btn size="x-large" class="custom_btn_primary pl-5 pr-5" @click="saveChecklist">Add Checklist</v-btn>
              <v-btn size="x-large" class="custom_btn_secondary" @click="checklistModel = false">Cancel</v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>


      <v-dialog v-model="createDialog" max-height="500" max-width="500" persistent>
        <v-card height="40vh" width="40vw" style="padding: 20px">
          <v-row dense style="background-color: #d9d9d9; height: 5vh">
            <v-col cols="12" md="10">
              <v-card-title class="headline">CREATE MAIN CATEGORY</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end" cols="12" md="2">
              <v-btn class="mt-2 mr-2" icon size="x-small" @click="createDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-text-field
              variant="outlined"
              style="margin-top: 20px; margin-bottom: 20px ; margin-left: 20px; margin-right: 20px"
              label="Create main Category"
              placeholder="Main Category"
          >
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">CREATE</v-btn>
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="createDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :right="snackAlert.snackBarRight"
          :top="snackAlert.snackBarTop"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>


    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import _ from 'lodash';

import {mapGetters} from "vuex";

//This is related to wangeditor ---START
import '@wangeditor/editor/dist/css/style.css';
import {i18nChangeLanguage} from '@wangeditor/editor'

i18nChangeLanguage('en')
// import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {onBeforeUnmount, ref, shallowRef, onMounted} from 'vue'
//This is related to wangeditor ---END

export default {

  // components: {
  //   Editor,
  //   Toolbar,
  // },

  //This is related to wangeditor ---START
  setup() {
    // editor instance, use `shallowRef`
    const editorRef = shallowRef()

    // content HTML
    const valueHtml = ref('<p></p>')

    // Simulate ajax async set HTML
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = '<p></p>'
      }, 1500)
    })

    const toolbarConfig = {toolbarKeys: ['bold', 'italic', 'underline', 'through']}
    const editorConfig = {placeholder: 'Type here...'}

    // Timely destroy `editor` before vue component destroy.
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // record editor instance
    }

    return {
      editorRef,
      mode: 'simple', // or 'simple'
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  //This is related to wangeditor ---END


  name: 'AddLeads',
  data() {
    return {

      officerAvailabilityError: '',
      officerAvailability: 0,

      // Data properties for the form
      checklistModel: false,
      openViewChecklistId: '',
      selectedItem: "",
      checklistData: {},
      mainCategory: "",
      mainCategoryArray: [],
      lead: {
        title: '',
        mainCategory: '',
        subCategory: '',
        province: '',
        district: '',
        description: '',
        dsd: '',
        gnd: '',
        assignAgent: '',
        assignDate: null,
      },

      showColorPicker: false,
      selectedColor: '#000',
      formatting: [],
      alignment: '',
      textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
      },

      excelFiles: null,

      selectedCategory: null,
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      selectAll: false,
      // categories: ['Category1', 'Category2'], // Example categories
      // subCategories: ['SubCategory1', 'SubCategory2'], // Example sub-categories
      categories: [],
      subCategories: [],
      allCategories: [],
      outlet_type: [],
      outlet_types: ['cargills food city', 'kfc outlet'],
      outletList: [],
      outletValues: [],
      assignOfficer: null,
      officerDetails: [],
      finalChackList: {},

      selectedItemId: null,
      itemChecklistData: {},

      dataToSave: {},
      isDisabledProvinceList: false,
      isDisabledDistrictList: false,
      isDisabledDSDLIST: false,
      isDisabledGNDList: false,

      selectedDistrict: [],
      selectedProvince: "",
      selectedDSD: [],
      selectedGND: [],

      agents: [],

      // file: null,
      // outletBulkUploadDialog: false,
      bulkFile: null,
      bulkItems: [],
      bulkHeaders: [
        {text: 'Institute Name', value: 'Institute Names', title: 'Institute Name'},
        {text: 'Address', value: 'Addresses', title: 'Address'},
        {text: 'Province', value: 'Province', title: 'Province'},
        {text: 'District', value: 'District', title: 'District'},
        {text: 'DSD', value: 'DSD', title: 'DSD'},
        {text: 'GND', value: 'GND', title: 'GND'},
        {text: 'Assign Agent', value: 'Assign Officer', title: 'Assign Agent'},
        {text: 'Assign Date', value: 'Assign Date', title: 'Assign Date'},
        {text: 'Description', value: 'Description', title: 'Description'},
      ],

      // add leads
      headers: [
        {text: 'Outlet Name', value: 'outlet_name', sortable: true, title: 'Outlet Name'},
        {text: 'Address', value: 'street_address', sortable: true, title: 'Address'},
        {text: 'Checklist', value: 'checklist', sortable: false, title: 'Checklist'},
        {text: 'Actions', value: 'actions', sortable: false, title: 'Actions'},
      ],


      mainFilterLoading: false,
      subFilterLoading: false,

      mDistrictLoading: false,
      gndLoading: false,
      provinceLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],

      // Pagination
      itemsPerPage: 10,
      addItinararyLoading: false,
      page: 1,
      totalPages: 0,

      //search
      search: '',
      receivedData: "",

      // Dialog state
      leadsBulkUploadDialog: false,

      //select date
      assignDateMenu: false,
      // filteredItems: [],


      // Check List Variable---------------------------------------

      list: [""],
      createDialog: false,
      checklistArray: [],
      allcheckListFinalArray: [],

      mainCatagaryTypes: "",
      mainCatagaryValues: "",
      mainCatagaryArray: [],
      checklistItem: [],
      outletId: "",
      outletName: "",
      outletAddress: "",
      outletLat: "",
      outletLng: "",
      mobileNum: "",
      // checklistItem: { value: "" },

    };

  },
  computed: {


    // Filtering items based on search input
    filteredItems() {
      return this.outletList.filter(item => {
        return (
            this.outletValues.includes(item.id)

        );
      });
    },


    ...mapGetters({
      checkListDateils: 'getCheckListDetails'
    })

  },
  watch: {

    assignOfficer() {
      this.checkOfficerAvailability();
    },
    'lead.assignDate'() {
      this.checkOfficerAvailability();
    },

    outletValues(newVal, oldVal) {

      // Find removed items
      if (oldVal) {
        const removedIds = oldVal.filter(id => !newVal.includes(id));

        // Remove from allcheckListFinalArray
        this.allcheckListFinalArray = this.allcheckListFinalArray.filter(
            entry => !removedIds.includes(entry.id) // Assuming entry has an 'id' property
        );
      }
    },
    'lead.mainCategory': function (newValue) {
      // Example of using newValue
      console.log('Main Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.subCategory': function (newValue) {
      console.log('Sub Category changed to:', newValue);
      this.fetchLeads();
    },
    'lead.province': function (newValue) {
      console.log('Province changed to:', newValue);
      this.fetchLeads();
    },
    'lead.district': function (newValue) {
      console.log('District changed to:', newValue);
      this.fetchLeads();
    },
    'lead.dsd': function (newValue) {
      console.log('DSD changed to:', newValue);
      this.fetchLeads();
      // this.getOfficerDetails();
    },
    'lead.gnd': function (newValue) {
      console.log('GND changed to:', newValue);
      this.fetchLeads();
    }
  },


  mounted() {
    this.fetchMainCategories();
    this.getAllProvinces();
    this.fetchAllAgents();
    this.getOfficerDetails();
    // this.loadLeadsList('All','province',null);
    console.log('checklistdat', this.checkListDateils);

  },

  methods: {

    resetDateRange(){
        this.lead.assignDate = null;
      },

    async checkOfficerAvailability() {
      if (this.assignOfficer && this.lead.assignDate) {
        // Adjust for timezone offset
        const date = new Date(this.lead.assignDate);
        const offset = date.getTimezoneOffset(); // Get the timezone offset in minutes
        const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000)); // Adjust for the offset
        const formattedDate = adjustedDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD

        try {
          const response = await axios.post('/api/checkOfficerAvailable', {
            officerID: this.assignOfficer,
            assigndata: formattedDate // Send the formatted date
          });


          console.log("checkOfficerAvailable", response.data);
          if (response.data.status === 201) {
            this.officerAvailabilityError = response.data.message;
            this.officerAvailability = 1;
          } else {
            this.officerAvailabilityError = '';
            this.officerAvailability = 0;
          }
        } catch (error) {
          console.error('Error checking officer availability:', error);
          this.officerAvailabilityError = 'Error checking officer availability.';
        }
      } else {
        this.officerAvailabilityError = '';
      }
    },
    handleOutletChange(newVal) {
      // Clear items from the array that are no longer in the dropdown selection
      this.filteredItems = this.outletList.filter(item => newVal.includes(item.id));
    },
    deleteItem(item) {
      // Remove from outletValues
      this.outletValues = this.outletValues.filter(id => id !== item.id);

      // Remove from filteredItems
      this.filteredItems = this.filteredItems.filter(i => i.id !== item.id);

      // Remove from allcheckListFinalArray
      this.allcheckListFinalArray = this.allcheckListFinalArray.filter(
          entry => entry.instituteName !== item.outlet_name
      );
      console.log('Delete check', item);
      console.log('Updated Array:', this.allcheckListFinalArray); // Verify removal
    },

    applyFormat(format) {

      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline'
                ? 'none'
                : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
    },
    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    toggleSelectAll() {
      this.filteredItems.forEach(item => {
        item.selected = this.selectAll;
      });
    },


    // Call this method whenever mainCategory, subCategory, or location fields change
    async fetchLeads() {
      await this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
    },

    async loadLeadsList(mainCategory, subCategory) {
      try {
        // Determine the location_type and location values based on selected values
        let locationType = '';
        let locationValue = [];

        if (this.lead.gnd && this.lead.gnd.length > 0) {
          locationType = 'gnd';
          locationValue = [this.lead.gnd];
        } else if (this.lead.dsd && this.lead.dsd.length > 0) {
          locationType = 'dsd';
          locationValue = [this.lead.dsd];
        } else if (this.lead.district && this.lead.district.length > 0) {
          locationType = 'district';
          locationValue = [this.lead.district];
        } else if (this.lead.province && this.lead.province !== "") {
          locationType = 'province';
          locationValue = [this.lead.province];
        }
        // Construct the payload
        const payload = {
          type: 'Spatial',
          country: 'SriLanka',
          district: locationType === 'district' ? locationValue : [],
          province: locationType === 'province' ? locationValue : [],
          dsd: locationType === 'dsd' ? locationValue : [],
          gnd: locationType === 'gnd' ? locationValue : [],
          location_type: locationType,
          main_filter: mainCategory,
          sub_filter: subCategory
        };


        // Make the API call
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getGISData', payload);

        console.log('table data for leads:', payload);
        if (response.data.http_status === 'success') {
          console.log('table data for leads two:', payload);
          // Map the response data to your table structure
          // this.filteredItems = response.data.data.map(item => ({
          //   subCategory: item.Type,
          //   instituteName: item.Name,
          //   address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`
          // }));

          this.items = response.data.data.map(item => ({
            subCategory: item.Type,
            instituteName: item.Name,
            address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`,
            selected: false // Add a default value for selection
          }));
        } else {
          console.error("Failed to load leads:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },


    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/agentUser', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data',
        }
      })
          .then(response => {
            let sample = response.data.data;
            this.agents = sample.map(item => {
              return {
                id: item.id,
                name: item.name
              };
            });
            console.log('Agents:', this.agents);
          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.provinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.provinceLoading = false;
            }
          })
          .catch(() => {
            this.provinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.lead.assignAgent = "";
      this.districts = [];
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.items = [];


      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;
              this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
              console.log('main c:', this.lead.mainCategory);
              console.log('sub c:', this.lead.subCategory);
            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {

      this.dsdList = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.items = [];
      this.lead.assignAgent = "";
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
              this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
              console.log('main c:', this.lead.mainCategory);
              console.log('sub c:', this.lead.subCategory);
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {

      this.gndList = [];
      this.lead.gnd = [];
      this.items = [];
      this.gndLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.gndLoading = false;
              this.loadLeadsList(this.lead.mainCategory, this.lead.subCategory);
              console.log('main c:', this.lead.mainCategory);
              console.log('sub c:', this.lead.subCategory);
            }
          })
          .catch(() => {
            this.gndLoading = false;
          });

      // this.getOfficerDetails();
    },

    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');

          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
              .filter(val => val.spatial_type !== 'Point')
              .map(val => ({
                data: null,
                name: val.sub_filter,
                main_type: val.main_filter,
                sub_directory: val.data_model
              }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset) {
      // if (filter === 'main'){
      this.isDisabledDistrictList = false;
      this.isDisabledDSDLIST = false;
      this.isDisabledGNDList = false;
      this.lead.province = null;
      this.lead.district = [];
      this.lead.dsd = [];
      this.lead.gnd = [];
      this.lead.subCategory = "";

      // Debugging: Check the input parameters
      // console.log('Filter:', filter);
      console.log('Dataset:', dataset);

      // Ensure that `this.allCategories` is correctly populated
      console.log('All Categories:', this.allCategories);

      let sub_filter = _.map(this.allCategories, function (o) {
        if (o.main_filter === dataset)
          return o.sub_filter
      });

      sub_filter = _.reject(sub_filter, _.isNil);
      this.subCategories = sub_filter.sort();

      // Debugging: Verify the final result
      console.log('Sub Categories:', this.subCategories);

    },


    handleFileUpload(event) {
      const file = event.target.files[0];
      this.bulkFile = file; // Assign the selected file to bulkFile

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});

        // Assuming the data is in the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
        const headers = jsonData[0].map(header => header ? header.toString().trim() : '');
        const dataRows = jsonData.slice(1).map(row => {
          return headers.reduce((obj, header, index) => {
            const value = row[index];
            obj[header] = value !== undefined && value !== null ? value.toString().trim() : '';
            return obj;
          }, {});
        });

        this.bulkItems = dataRows;
      };

      reader.readAsArrayBuffer(file);
    },

    async uploadBulkLead() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (!this.bulkFile) {
        console.error('No file selected for upload');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.bulkFile);
      formData.append('userId', userId); // Append userId if needed by the backend

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/bulk-upload`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data' // No need to set this, it will be set automatically
          },
        });
        console.log('Bulk upload response:', response.data);
        this.leadsBulkUploadDialog = false;
      } catch (error) {
        console.error('Error uploading bulk outlets:', error);
      }
    },

    async downloadTemplate() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/Itinarary/download-template?userId=${userId}`;


      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Failed to download template');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'template_leads_data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
        this.showSnackbar("Template download successfully!", "success");
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },

    uploadData() {
      // Your API call logic
      axios.post('/your-api-endpoint', this.items)
          .then(response => {
            console.log('Data uploaded successfully:', response.data);
          })
          .catch(error => {
            console.error('Error uploading data:', error);
          });
    },
    backPageRoute() {
      this.$router.back();
    },
    viewChecklist(item) {
      console.log("Selected Item:", item);
      this.selectedItem = {
        id: item.id,
        name: item.outlet_name,
        address: item.street_address,
        lat: item.latitude,
        lng: item.longitude,
        mobile: item.mobile_number,
      };
      this.mainCategoryArray = this.checklistData[item.id] || [];
      this.checklistModel = true;
    },
    homeReload() {
      window.location.href = '/dashboard';
    },


    addMainCategory() {
      if (this.mainCategory) {
        this.mainCategoryArray.push({category: this.mainCategory, checklistItems: []});
        this.mainCategory = "";
      } else {
        console.warn("Please enter a category!");
      }
    },

    validateForm() {
      // Regex pattern to allow only letters and numbers
      const titleRegex = /^[a-zA-Z0-9\s]+$/;

      // Validate Itinerary Title
      if (!this.lead.title) {
        this.showSnackbar('Itinerary Title is required.', 'error');
        return false;
      }

      if (!titleRegex.test(this.lead.title)) {
        this.showSnackbar('Itinerary Title can only contain letters and numbers.', 'error');
        return false;
      }

      // Validate Outlet Type
      if (!this.outlet_type) {
        this.showSnackbar('Outlet Type is required.', 'error');
        return false;
      }

      // Validate Outlet
      if (!this.outletValues) {
        this.showSnackbar('Outlet is required.', 'error');
        return false;
      }

      // Validate Assign Officer
      if (!this.assignOfficer) {
        this.showSnackbar('Assign Officer is required.', 'error');
        return false;
      }

      // Validate Assign Date
      if (!this.lead.assignDate) {
        this.showSnackbar('Assign Date is required.', 'error');
        return false;
      }

      // if (!this.lead.description) {
      //   this.showSnackbar('Lead descriptions is required.', 'error');
      //   return false;
      // }

      // Validate Final Checklist (Ensure it has at least one category with items)
      if (!this.finalChackList || this.finalChackList.length === 0) {
          this.showSnackbar('At least one checklist item is required.', 'error');
          return false;
      }

      //  Validate `allcheckListFinalArray` - Ensure it's not empty
      if (!this.allcheckListFinalArray || this.allcheckListFinalArray.length === 0) {
        this.showSnackbar('Checklist data is required. Please save at least one checklist.', 'error');
        return false;
      }

      //  Validate each checklist inside `allcheckListFinalArray`
      for (const checklist of this.allcheckListFinalArray) {
        // Ensure required properties exist
        if (!checklist.instituteName || !checklist.address || !checklist.lat || !checklist.lng) {
          this.showSnackbar('Checklist items must include institute name, address, latitude, and longitude.', 'error');
          return false;
        }

        // Ensure each checklist has a checklistArray
        if (!checklist.checklistArray || checklist.checklistArray.length === 0) {
          this.showSnackbar('Each checklist must have at least one item.', 'error');
          return false;
        }


      }

      return true;
    },


    // Added addLead method
    addLead() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      // Call the validation function before proceeding
      if (!this.validateForm()) {
        return;
      }

      // Handle the assign date formatting
      const date = new Date(this.lead.assignDate);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const assignDate = date.toISOString().split('T')[0];

      console.log("assignDate is", assignDate);

      const formData = new FormData();
      formData.append("userID", userId);
      formData.append("title", this.lead.title);
      formData.append("province", this.lead.province);
      formData.append("district", this.lead.district);
      formData.append("dsd", this.lead.dsd);
      formData.append("gnd", this.lead.gnd);
      formData.append("officerID", this.assignOfficer);
      formData.append("assigndata", assignDate);
      formData.append("description", this.lead.description);
      formData.append("iteneraryList", JSON.stringify(this.allcheckListFinalArray));

      axios.post('/api/addItinararyWeb', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            console.log('Itinerary added successfully!:', response.data);
            this.showSnackbar("Itinerary added successfully!", "success");
            this.resetForm();
            setTimeout(() => {
            window.location.reload();
          }, 2000);
          })
          .catch(error => {
            console.error('Error adding lead:', error);
            this.showSnackbar("Error adding itinerary. Please try again.", "error");
          });
    },

    resetForm() {
      this.lead.title = "";
      this.lead.province = "";
      this.lead.district = "";
      this.lead.dsd = "";
      this.lead.gnd = "";
      this.assignOfficer = "";
      this.lead.assignDate = null;
      this.lead.description = "";
      this.outlet_type = "";
      this.outletValues = "";
      this.finalChackList = [];
    },


    // Get ALl Outlet Details Accroging to gnd
    getOutletDetails(catagory) {
      console.log('fk', catagory);


      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("catagary", JSON.stringify(catagory));

      axios.post('/api/getAllOutletDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }

      })
          .then(response => {
            if (response.data.http_status === 'success') {
              this.outletList = response.data.data;
              console.log("fuking outlet",this.outletList );
            }
          })
          .catch(() => {
            console.log("fk2");
          });


    },

    // Get Officer Deteils
    getOfficerDetails() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);
      // formData.append("dsd", this.lead.dsd);

      axios.post('/api/getAllOfficer', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }

      })
          .then(response => {
            if (response.data.http_status === 'success') {

              this.officerDetails = response.data.data;
              console.log("officer", response.data.data);

            }
          })
          .catch(() => {
            console.log("fk2");
          });

    },


    // -------------------------------------------Check list methyod----------------------------------------------------------------------------------------

    // addItemToChecklist(item, index) {

    //   console.log("subItem" ,item,index )
    //   if (!this.checklistArray[index]) {
    //     this.checklistArray[index] = [];
    //   }

    //   console.log('fkchecklitsitem',this.checklistItem.value)

    //   this.checklistArray[index].push({ category:item,checklistItems: this.checklistItem.value });

    //   console.log("Checklist Array:", this.checklistArray);
    // },
    addItemToChecklist(index) {
      this.mainCategoryArray[index].checklistItems.push("");
    },

    removeItemFromChecklist(mainIndex, itemIndex) {
      if (this.mainCategoryArray[mainIndex] && this.mainCategoryArray[mainIndex].checklistItems) {
        this.mainCategoryArray[mainIndex].checklistItems.splice(itemIndex, 1);
      }
    },

    getChecklistArrayForItem(index) {
      // Return the checklist array for the specified main category item
      return this.checklistArray[index] || [];
    },


    addMainCatagary(mainCatagaryTypes) {
      console.log('mainCatogary', this.mainCatagaryTypes);

      if (this.mainCatagaryTypes) {
        this.mainCatagaryArray.push(mainCatagaryTypes);
        this.mainCatagaryTypes = "";
        // this.checklistArray = [],
        console.log('array', this.mainCatagaryArray);

      } else {
        this.showSnackbar("Need Category", "error");
      }


    },
    removeMainCategory(index) {
      this.mainCategoryArray.splice(index, 1);
    },

    saveChecklist() {
      if (this.mainCategoryArray.length === 0) {
        console.warn("Please add categories and checklist items!");
        this.showSnackbar('Please Add Main Categories & Checklist Items!', 'error');
        return;
      }

      // Save the checklist data for the selected item
      this.checklistData[this.selectedItem.id] = this.mainCategoryArray;

      const finalChecklist = {
        instituteName: this.selectedItem.name,
        address: this.selectedItem.address,
        lat: this.selectedItem.lat,
        lng: this.selectedItem.lng,
        mobileNumber: this.selectedItem.mobile,
        checklistArray: this.mainCategoryArray,
      };

      console.log("Final Checklist Data:", finalChecklist);

      // Push finalChecklist to allcheckListFinalArray
      this.allcheckListFinalArray.push(finalChecklist);

      console.log('pusharray', this.testArray)

      this.showSnackbar("Add successfully", "success");
      this.checklistModel = false;
    },


  }
};
</script>

<style>
@import "../../styles/lead_management/add_leads.scss";
</style>

export default {
    install() {
        let timeout;
        const logoutTime = 30 * 60 * 1000; 

        // const logoutTime = 2 * 60 * 1000

        function resetTimer() {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                alert('Session expired due to inactivity.');
                localStorage.removeItem('token'); // Clear token
                window.location.href = '/login'; // Redirect to login
            }, logoutTime);
        }

        ['mousemove', 'keydown', 'click'].forEach(event => {
            document.addEventListener(event, resetTimer);
        });

        resetTimer(); // Start the timer
    }
};

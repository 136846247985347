<template class="main">
  <v-app id="app_user_role_list">

    <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
      <v-progress-circular color="green" indeterminate size="64"></v-progress-circular>
    </v-overlay>
  <v-container fluid>

    <v-row class="pl-2 pr-6">
      <v-col cols="6" class="mt-3">
        <h3 class="page-title">USER ROLES</h3>
        <h5 class="light-gray-text">User roles & their permissions registered in the system.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col cols="6" class="px-4 mt-5">
        <v-row class="tool-bar-style">
          <v-btn size="x-large" class="red-text" variant="text"  @click="createUser()">
            <v-icon>mdi-account-plus</v-icon>&nbsp;&nbsp;CREATE USER
          </v-btn>
          <v-divider vertical :thickness="3" class="divider-vertical"></v-divider>
          <v-btn variant="text" size="x-large" class="gray-text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>


    <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">

      <v-card-text class="custom_tool_bar_style">
          <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
            <v-icon size="large" >mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
            <v-icon size="large" >mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-card-text>

        <v-divider thickness="2"></v-divider>
        <v-card-text>
          <!-- ------------- -->
          <v-row>
           
            <v-col cols="12">
              <v-text-field
                  v-model="searchQuery"
                  append-inner-icon="mdi-magnify"
                  variant="outlined"
                  placeholder="Search"
                  label="Search"
                  hide-details
                  clearable
                  class="custom_input_field_color"
              ></v-text-field>
            </v-col>
      
          </v-row>

          <v-divider></v-divider>

          <v-data-table
              :headers="headers"
              :items="filteredItems"
              items-per-page="5"
              class="custom_table_normal mt-5"
              item-value="name"
              :loading="tableLoading"
          >

            <template v-slot:[`item.user_role`]="{ item }">
              <span class="red-text">{{ item.user_role}}</span>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span class="description-text">{{ item.description }}</span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-x-transition" id="app_user_role_list">
                <template v-slot:activator="{ props }">
                  <v-btn class="custom_btn_primary" variant="flat" size="large" v-bind="props">
                    ACTIONS &nbsp; &nbsp;<v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item  @click="openViewDialog(item)">
                    <v-list-item-title class="custom_font_color_view">VIEW</v-list-item-title>
                  </v-list-item>
                  <v-list-item  @click="openEditDialog(item)" :disabled="true">
                    <v-list-item-title class="custom_font_color_edit">EDIT</v-list-item-title>
                  </v-list-item>
                  <v-list-item  @click="confirmDelete(item)" :disabled="true">
                    <v-list-item-title class="custom_font_color_delete">DELETE</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>


            <template v-slot:[`item.permissionLevel`]="{ item }">
              <v-chip :color="getPermissionColor(item.dashboard)">
                {{ getPrimaryPermission(item.dashboard) }}
                <span v-if="getSecondaryPermission(item.dashboard)">
                  ({{ getSecondaryPermission(item.dashboard) }})
                </span>
              </v-chip>
            </template>
          </v-data-table>

        </v-card-text>
      
    </v-card>


    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteUserListDialog" max-width="500" max-height="500" persistent>
      <v-card class="pa-1">
        <v-row dense class="rowClr ma-1">
          <v-col cols="12" md="9">
            <v-card-title class="headline">DELETE USER ROLE</v-card-title>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end pt-3 pr-3">
            <v-btn icon size="x-small" @click="deleteUserListDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this user role ?</v-card-text>
        <v-card-text class="textspace">
          This user role will be deleted immediately. Once deleted, it can no longer be used in any purpose.
        </v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="deleteItem" variant="outlined" class="bg-red-lighten-4">DELETE</v-btn>&nbsp; &nbsp;
          <v-btn @click="deleteUserListDialog = false" variant="outlined" class="bg-grey-lighten-2">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="edit_user_role_dialog" fullscreen>
      <v-card>

        <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <!-- <v-img class="custom_company_logo_nav" :src="require('@/assets/app_bar_logo.png')"
                     @click="homeReload()"></v-img> -->
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="edit_user_role_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>
      
          <v-row class="mt-1 custom_scroll_option_fullscreen_dialog">
          <v-col cols="12" md="12">

            <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
              <template v-slot:prepend>
                <v-icon>mdi-view-dashboard</v-icon>
                <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">UPDATE USER ROLE</v-card-title>
              </template>
            </v-card>

            <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">
              <v-row class="ma-0">

                <v-col cols="12" xs="12" sm="9" md="9" lg="9"></v-col>

                <v-col cols="12" xs="12" sm="3" md="3" lg="3">
                  <v-row class="text-right mt-0 mr-3">
                    <v-spacer></v-spacer>
                    <v-btn class="custom_btn_primary" size="large" variant="outlined" @click="updateUserRole()">Update
                      User Role
                    </v-btn>
                  </v-row>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-divider class="mt-2"></v-divider>
                </v-col>
              </v-row>

            
              <v-row class="ma-0">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                  <v-row class="mb-5">
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-alert color="#F0F0F0" prominent density="default" border="border-lg"
                                 text="These user roles help you grant permissions and assign related functions to users in the system.">
                          <template v-slot:prepend>
                            <v-icon color="orange" size="x-large">mdi-alert</v-icon>
                          </template>
                        </v-alert>
                      </v-col>

                      

                    </v-row>
                      <v-text-field
                        v-model="roleForm.roleType" :items="userTypes" label="Role type *"
                        placeholder="Type User Role Here"
                        variant="outlined" density="comfortable" color="#05146F"
                    ></v-text-field>

                    <v-textarea
                        label="Role Description *"
                        v-model="roleForm.description"
                        variant="outlined"
                        placeholder="Type user description here"
                        required
                        rows="2"
                    ></v-textarea>
                </v-col>


                <v-divider vertical :thickness="3" ></v-divider>
                
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" v-if="userCatogaryType !== 'Mobile User' &&  roleForm.selectedUser != 'Mobile User'">

                  <h5 class="card-two-title px-3 mt-5 mb-4">Setup Permissions</h5>
                    <v-row v-for="(permission, index) in roleForm.permissions" :key="index" class="align-center no-gutters">
                      <v-row class="ml-3">
                        <v-col cols="auto" class="d-flex margin-up-down-two">
                          <v-switch
                              v-model="permission.isOn"
                              :color="permission.isOn ? 'red' : 'grey'"
                              class="mr-1"
                              @change="handleSwitchChange(permission)"
                          ></v-switch>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                          <v-card-text :class="{ 'red-text': permission.isOn }" class="ml-3 mr-1">{{ permission.premission_dashboard }}</v-card-text>
                        </v-col>
                      </v-row>
                      <v-col cols="12" v-if="permission.isOn" class="pl-10">
                        <v-row class="margin-up-down">
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.read"
                                color="red"
                                @change="handlePermissionChange(permission, 'read')"
                            ></v-switch>
                            <v-card-text class="mx-1">Read</v-card-text>
                          </v-col>
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.write"
                                color="red"
                                @change="handlePermissionChange(permission, 'write')"
                            ></v-switch>
                            <v-card-text class="mr-1">Write</v-card-text>
                          </v-col>
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.delete"
                                color="red"
                                @change="handlePermissionChange(permission, 'delete')"
                            ></v-switch>
                            <v-card-text class="mr-1">Delete</v-card-text>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider v-if="index < roleForm.permissions.length - 1"></v-divider>
                    </v-row>

                </v-col>

                <!-- ---------------------------------- -->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    

    <v-dialog v-model="view_user_role_dialog" fullscreen>
      <v-card>

        <v-toolbar style="border-radius: 0px !important;"
                     class="custom_fullscreen_dialog_toolbar pr-5 custom_card_box" color="white" dense>
            <v-toolbar-title>
              <!-- <v-img class="custom_company_logo_nav" :src="require('@/assets/app_bar_logo.png')"
                     @click="homeReload()"></v-img> -->
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="custom_red_btn" variant="flat" @click="view_user_role_dialog = false">
              CLOSE
            </v-btn>
          </v-toolbar>
      
          <v-row class="mt-1 custom_scroll_option_fullscreen_dialog">
          <v-col cols="12" md="12">

            <v-card class="pa-0 mx-6 mt-2 custom_card_box" border="sm opacity-1">
              <template v-slot:prepend>
                <v-icon>mdi-view-dashboard</v-icon>
                <v-card-title class="font-weight-bold custom_page_main_sub_title ml-3">VIEW USER ROLE</v-card-title>
              </template>
            </v-card>

            <v-card class="pa-3 mx-6 custom_card_box mt-4" border="sm opacity-1">

              <v-row class="ma-0">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6">

                  <v-row class="mb-5">
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <v-alert color="#F0F0F0" prominent density="default" border="border-lg"
                                 text="These user roles help you grant permissions and view related functions to users in the system.">
                          <template v-slot:prepend>
                            <v-icon color="orange" size="x-large">mdi-alert</v-icon>
                          </template>
                        </v-alert>
                      </v-col>

                      

                    </v-row>
                      <v-text-field
                        v-model="roleForm.roleType" :items="userTypes" label="Role type *"
                        placeholder="Type User Role Here"
                        variant="outlined" density="comfortable" color="#05146F"
                        readonly
                    ></v-text-field>

                    <v-textarea
                        label="Role Description *"
                        v-model="roleForm.description"
                        variant="outlined"
                        placeholder="Type user description here"
                        required
                        rows="2"
                        readonly
                    ></v-textarea>
                </v-col>


                <v-divider vertical :thickness="3" ></v-divider>
                
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" v-if="userCatogaryType !== 'Mobile User' &&  roleForm.selectedUser != 'Mobile User'">

                  <h5 class="card-two-title px-3 mt-5 mb-4">Setup Permissions</h5>
                    <v-row v-for="(permission, index) in roleForm.permissions" :key="index" class="align-center no-gutters" >
                      <v-row class="ml-3">
                        <v-col cols="auto" class="d-flex margin-up-down-two">
                          <v-switch
                              v-model="permission.isOn"
                              :color="permission.isOn ? 'red' : 'grey'"
                              class="mr-1"
                              @change="handleSwitchChange(permission)"
                              readonly
                          ></v-switch>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                          <v-card-text :class="{ 'red-text': permission.isOn }" class="ml-3 mr-1">{{ permission.premission_dashboard }}</v-card-text>
                        </v-col>
                      </v-row>
                      <v-col cols="12" v-if="permission.isOn" class="pl-10">
                        <v-row class="margin-up-down">
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.read"
                                color="red"
                                @change="handlePermissionChange(permission, 'read')"
                                readonly
                            ></v-switch>
                            <v-card-text class="mx-1">Read</v-card-text>
                          </v-col>
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.write"
                                color="red"
                                @change="handlePermissionChange(permission, 'write')"
                                readonly
                            ></v-switch>
                            <v-card-text class="mr-1">Write</v-card-text>
                          </v-col>
                          <v-col cols="auto" class="d-flex">
                            <v-switch
                                v-model="permission.permissions.delete"
                                color="red"
                                @change="handlePermissionChange(permission, 'delete')"
                                readonly
                            ></v-switch>
                            <v-card-text class="mr-1">Delete</v-card-text>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider v-if="index < roleForm.permissions.length - 1"></v-divider>
                    </v-row>

                </v-col>

                <!-- ---------------------------------- -->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :top="snackAlert.snackBarTop"
        :right="snackAlert.snackBarRight"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>
 
  </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: 'UserRoleList',
  data() {
    return {
      search: '',
      headers: [
        { text: 'User Category', value: 'category', title: 'User Category' },
        { text: 'User Role', value: 'user_role', title: 'User Role' },
        { text: 'Creation Date', value: 'creationDate', title: 'Creation Date' },
        { text: 'Description', value: 'description', title: 'Description' },
        // { text: 'Permission Level', value: 'permissionLevel', title: 'Permission Level' },
        { text: 'Action', value: 'action', sortable: false, title: 'Action' }
      ],

      isEditMode: false,
      roleForm: {
        id: null,
        selectedUser:'',
        roleType: '',
        description: '',
        permissions: [],
        dashboard:[]
      },
      items: [],
      tableLoading:false,
      selectedUser:'',

      relatedValue: null,

      // Pagination
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,

      //dialog status
      deleteUserListDialog: false,
      edit_user_role_dialog: false,
      view_user_role_dialog:false,
      // selectedItem: null,
      roleToDelete: null,
      centerLoading:false,

      userRoles: [],
      userTypes: [],
      searchQuery: '',
      selectedItem: {
        id: null,
        user_role_id: null,
        description: '',
        dashboard: [],
      },
      deleteItemId: null,

      permissionsItems: [],
      userCatogaryType:'',
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
    };
  },
  mounted() {
    this.getAllUserRoles();
    this.getDashboardPermissions();
  },
  created() {
    this.fetchUserRoles();
  },
  computed: {
    filteredItems() {
     
      const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';
      return this.items.filter(item => {
        return Object.values(item).some(val => String(val).toLowerCase().includes(query));
      });
    },
  },

  methods: {

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
      const tableData = this.filteredItems.map((item, index) => ({
        '#': index + 1,
        'Catagory': item.category,
        'Role Type': item.user_role,
        'Creation Date': item.creationDate,
        'Description': item.description,
        // 'Permission Level': item.permissionLevel,
        // Combine the primary and secondary permissions
        'Permission Level': `${this.getPrimaryPermission(item.dashboard)}${this.getSecondaryPermission(item.dashboard) ? ` (${this.getSecondaryPermission(item.dashboard)})` : ''}`,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'User Role');


      XLSX.writeFile(workbook, 'user_role_analysis.xlsx');
    },

    exportPDF() {
    const doc = new jsPDF({
        orientation: 'portrait', // Portrait mode
        unit: 'mm',
        format: 'a4'
    });

    // Set title
    doc.setFontSize(16);
    doc.text('User Role Analysis', 14, 15);

    // Table column headers
    const tableColumns = ['#', 'Category', 'Role Type', 'Creation Date', 'Description', 'Permission Level'];

    // Prepare table data
    const tableData = this.filteredItems.map((item, index) => [
        index + 1,  // Serial number
        item.category,
        item.user_role,
        item.creationDate,
        item.description,
        `${this.getPrimaryPermission(item.dashboard)}${this.getSecondaryPermission(item.dashboard) ? ` (${this.getSecondaryPermission(item.dashboard)})` : ''}`
    ]);

    // Generate table
    doc.autoTable({
        head: [tableColumns],
        body: tableData,
        startY: 25, // Ensures the table starts below the title
        styles: {
            fontSize: 9,
            cellPadding: 2,
            valign: 'middle',
            halign: 'center'
        },
        headStyles: {
            fillColor: [40, 40, 200], // Dark blue header
            textColor: [255, 255, 255],
            fontSize: 10,
        },
        bodyStyles: {
            textColor: [50, 50, 50],
        },
        alternateRowStyles: {
            fillColor: [240, 240, 240],
        },
        columnStyles: {
            0: { cellWidth: 10 }, // Serial Number
            1: { cellWidth: 30 }, // Category
            2: { cellWidth: 30 }, // Role Type
            3: { cellWidth: 25 }, // Creation Date
            4: { cellWidth: 40 }, // Description
            5: { cellWidth: 45 }, // Permission Level
        },
        margin: { top: 10 }
    });

    // Footer with date
    doc.setFontSize(10);
    doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

    // Save the PDF
    doc.save('user_role_analysis.pdf');
},


    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: { read: false, write: false, delete: false },
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;

            this.userTypes = response.data.data;
            console.log('User role11:', usrRole);
            // this.userTypes = usrRole.map(item => {
            //   return {
            //     id: item.id,
            //     name: item.role_type
            //   };
            // });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },

    async fetchUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      this.tableLoading = true;
      try {
        const response = await axios.post('/api/allCreatedUserRoles', {
          userId: userId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data.success === "Get Dashboard successfully") {
          const roles = response.data.data;
          console.log('Roles data:', roles); // Log the roles data
          this.tableLoading = false;

          // Sort the data by created_at in descending order (newest first)
          this.items = roles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(role => ({
            category: role.category ? role.category : 'N/A',
            user_role: role.user_role ? role.user_role : 'N/A',
            creationDate: new Date(role.created_at).toLocaleDateString(),
            description: role.description || 'No description',
            // permissionLevel: this.formatPermissionLevel(role.dashboard)
            dashboard: role.dashboard || [],
            id: role.id,
          }));

          this.console.log("fucking data",this.items);

          this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
         
        }
      } catch (error) {
        console.error('Error fetching user roles:', error);
        // Optionally handle errors or show user feedback
      }
    },

    formatPermissionLevel(dashboard) {
      if (dashboard.length === 0) return 'No Permissions';

      const permissions = dashboard.map(d => {
        let perms = [];
        if (d.read_premission) perms.push('Read');
        if (d.write_premission) perms.push('Write');
        if (d.delete_premission) perms.push('Delete');
        return perms.join(' / ');
      });

      return `Permissions for: ${permissions.join(', ')}`;
    },

    backPageRoute(){
      this.$router.back();
    },

    createUser(){
      this.$router.push({ path: '/user-registration' });
    },

    handleSwitchChange(permission) {
      if (!permission.isOn) {
        // Reset the permissions when the switch is turned off
        permission.permissions.read = false;
        permission.permissions.write = false;
        permission.permissions.delete = false;
      } else {
        // Optionally, you can enable default permissions here if needed
        // For example, you might want to enable read by default when the main switch is turned on
        permission.permissions.read = true;
      }
    },

    // handlePermissionChange(item, permissionType) {
    //   // You can perform additional logic here if needed
    //   // For example, you can ensure that if the 'delete' permission is enabled, 'read' and 'write' must also be enabled.
    //   if (permissionType === 'delete' && item.permissions.delete) {
    //     item.permissions.read = true;
    //     item.permissions.write = true;
    //   }

    //   // If both 'read' and 'write' are disabled, ensure 'delete' is also disabled
    //   if (!item.permissions.read && !item.permissions.write) {
    //     item.permissions.delete = false;
    //   }
    // },

    handlePermissionChange(permission, permissionType) {
      // Check if 'delete' permission is being modified
      if (permissionType === 'delete') {
        if (permission.permissions.delete) {
          // Enable 'read' and 'write' if 'delete' is enabled
          permission.permissions.read = true;
          permission.permissions.write = true;
        } else {
          // Ensure 'read' and 'write' are disabled if 'delete' is turned off
          if (!permission.permissions.read && !permission.permissions.write) {
            permission.permissions.delete = false;
          }
        }
      }

      // Check if 'read' or 'write' permissions are being modified
      if (permissionType === 'read' || permissionType === 'write') {
        if (!permission.permissions.read && !permission.permissions.write) {
          // Disable 'delete' if both 'read' and 'write' are turned off
          permission.permissions.delete = false;
        }
      }
    },


    getPermissionLevel(dashboard) {
      let hasRead = false;
      let hasWrite = false;
      let hasDelete = false;

      dashboard.forEach(dash => {
        if (dash.read_premission) hasRead = true;
        if (dash.write_premission) hasWrite = true;
        if (dash.delete_premission) hasDelete = true;
      });

      if (hasRead && hasWrite && hasDelete) {
        return 'All Permissions';
      } else if (hasRead && hasWrite) {
        return 'Permissions for Read and Write';
      } else if (hasRead) {
        return 'Permission for Read';
      } else if (hasWrite) {
        return 'Permission for Write';
      } else if (hasDelete) {
        return 'Permission for Delete';
      } else {
        return 'No Permissions';
      }
    },

    getPermissionColor(dashboard) {
      let hasRead = false;
      let hasWrite = false;
      let hasDelete = false;

      dashboard.forEach(dash => {
        if (dash.read_premission) hasRead = true;
        if (dash.write_premission) hasWrite = true;
        if (dash.delete_premission) hasDelete = true;
      });

      if (hasRead && hasWrite && hasDelete) {
        return 'red';
      } else if (hasRead && hasWrite) {
        return 'green';
      } else if (hasRead || hasWrite || hasDelete) {
        return 'blue';
      } else {
        return 'grey';
      }
    },
    getPrimaryPermission(dashboard) {
      let permission = this.getPermissionLevel(dashboard);
      if (permission === 'All Permissions') {
        return 'All Permissions';
      } else if (permission.startsWith('Permissions for')) {
        return permission.split(' ')[2];
      } else {
        return permission;
      }
    },
    getSecondaryPermission(dashboard) {
      let permission = this.getPermissionLevel(dashboard);
      if (permission === 'All Permissions') {
        return 'Read, Write, Delete';
      } else if (permission.startsWith('Permissions for')) {
        return permission.split(' ').slice(-2).join(' ');
      } else {
        return null;
      }
    },

    confirmDelete(item) {
      this.selectedItem = item;
      this.deleteItemId = item.id;
      this.deleteUserListDialog = true;
    },

    async deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const userDiscriptionId = this.deleteItemId;

      console.log('Deleting item with ID:', userDiscriptionId);
      console.log('Deleting item with token:', token);
      console.log('Deleting item with userId:', userId);

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteCreatedUserPrermission`, {
          userId,
          userDiscriptionId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('API Response:', response.data); // Log API response

        if (response.data.success) {
          // Close the dialog and reset deletion state
          this.deleteUserListDialog = false;
          this.deleteItemId = null;
          this.showSnackbar("User role deleted successfully!", "success");

          // Refresh the data table
          await this.fetchUserRoles();
        } else {
          console.error('Failed to delete the user role:', response.data);
          this.showSnackbar("Failed to delete user role.", "error");
        }
      } catch (error) {
        console.error('Error deleting user role:', error);

        if (error.response && error.response.data) {
          if (error.response.data.error === "you can not delete this user role") {
            this.showSnackbar("You cannot delete this user role.", "error");
          } else {
            this.showSnackbar(error.response.data.error || "Error deleting user role.", "error");
          }
        } else {
          this.showSnackbar("Server error. Please try again later.", "error");
        }

        this.deleteUserListDialog = false;
        this.showSnackbar("Error deleting user role.", "error");
      }
    },



    openEditDialog(item) {
      this.isEditMode = true;
      this.selectedItem = item;

      // Set role type and description
      this.roleForm = {
        roleType: item.user_role,
        selectedUser:item.category,
        description: item.description,
        permissions: this.permissionsItems.map(permission => {
          const existingPermission = item.dashboard.find(d => d.dashboard_id === permission.id);
          if (existingPermission) {
            return {
              id: permission.id,
              premission_dashboard: permission.premission_dashboard,
              isOn: true,
              permissions: {
                read: !!existingPermission.read_premission,
                write: !!existingPermission.write_premission,
                delete: !!existingPermission.delete_premission,
              },
            };
          }
          // Return the existing permission object as default
          return {
            id: permission.id,
            premission_dashboard: permission.premission_dashboard,
            isOn: false,
            permissions: {
              read: false,
              write: false,
              delete: false,
            },
          };
        }),
      };

      this.edit_user_role_dialog = true;
      this.userCatogaryType = item.category;
      console.log('Editing item:', item);
      console.log('Role Form:', this.roleForm);
    },

    openViewDialog(item){
      this.isEditMode = true;
      this.selectedItem = item;

      // Set role type and description
      this.roleForm = {
        roleType: item.user_role,
        selectedUser:item.category,
        description: item.description,
        permissions: this.permissionsItems.map(permission => {
          const existingPermission = item.dashboard.find(d => d.dashboard_id === permission.id);
          if (existingPermission) {
            return {
              id: permission.id,
              premission_dashboard: permission.premission_dashboard,
              isOn: true,
              permissions: {
                read: !!existingPermission.read_premission,
                write: !!existingPermission.write_premission,
                delete: !!existingPermission.delete_premission,
              },
            };
          }
          // Return the existing permission object as default
          return {
            id: permission.id,
            premission_dashboard: permission.premission_dashboard,
            isOn: false,
            permissions: {
              read: false,
              write: false,
              delete: false,
            },
          };
        }),
      };

      this.view_user_role_dialog = true;
      this.userCatogaryType = item.category;
      console.log('Editing item:', item);
      console.log('Role Form:', this.roleForm);
    },

    updateUserRole() {
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const token = localStorage.getItem('token');

      if (!userId || !token) {
        this.showSnackbar('User authentication failed. Please log in again.', 'error');
        return;
      }

      if (!this.roleForm.roleType || !/^[a-zA-Z\s]+$/.test(this.roleForm.roleType)) {
        this.showSnackbar('Role is required and should contain only letters and spaces.', 'error');
        return false;
      }

      if (!this.roleForm.description || !/^[a-zA-Z\s]+$/.test(this.roleForm.description)) {
        this.showSnackbar('Description is required and should contain only letters and spaces.', 'error');
        return false;
      }

      if (this.roleForm.description.length > 300) { // FIXED HERE
        this.showSnackbar('The description exceeds 300 characters. Please provide a shorter description.', 'error');
        return;
      }

      // ** Validate Dashboard Permissions **
      const selectedDashboards = this.roleForm.permissions.filter(item => item.isOn);

      if (this.roleForm.selectedUser === 'Web User') {
        if (selectedDashboards.length === 0) {
          this.showSnackbar('At least one dashboard must be selected.', 'error');
          return;
        }

        const invalidDashboard = selectedDashboards.find(item => 
          item.permissions && !item.permissions.read && !item.permissions.write && !item.permissions.delete
        );

        if (invalidDashboard) {
          this.showSnackbar(`Please select at least one permission for the dashboard: ${invalidDashboard.name}`, 'error');
          return;
        }
      }


      this.centerLoading = true;
      // Map the permissionsItems to the required format
      const dashboards = this.roleForm.permissions
          .filter(item => item.isOn) // Only include dashboards where the switch is ON
          .map(item => ({
            dashboardId: item.id, // Assuming item.id represents the dashboard ID
            readPremission: item.permissions.read,
            writePremission: item.permissions.write,
            deletePremission: item.permissions.delete,
          }));


      const payload = {
        userId,
        createdPremissionId: this.selectedItem.id,
        userRoleType: this.roleForm.roleType,
        userRoleDescription: this.roleForm.description, 
        accessDashboard: dashboards,
        
      };
      // Print the payload as a formatted JSON string
      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

      // Convert the payload to a JSON string for sending in the request
      const jsonPayload = JSON.stringify(payload);

      // Log the payload being sent
      console.log('Payload being sent:', jsonPayload);

      axios.post('/api/createNewUserPrermission', jsonPayload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
          .then(response => {
            console.log('User role created:', response.data);
            this.fetchUserRoles();
            this.showSnackbar("User role permission created successfully!", "success");
            this.centerLoading = false;
            this.edit_user_role_dialog = false;
            // Handle successful creation (e.g., display a success message, reset form)
          })
          .catch(error => {
            console.error('Error creating user role:', error);
            // Handle error (e.g., display an error message)
            this.centerLoading = false;
          });
    },
  }
};
</script>

<style>
@import "../../styles/user_management/user_role_list.scss";
</style>
<template>

<v-overlay v-model="centerLoading" :scrim="false" class="custom_centered_overlay">
        <v-progress-circular color="#2A8200" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  <v-card style="height: 100%; width: 100%">
    <div style="display: flex; justify-content: center;">
      <v-card class="pa-3 mt-3" style="width: 93%;">
        <v-icon class="ml-8">mdi-view-dashboard
        </v-icon>
        <v-label class="ml-10">OUTLETS DETAILS</v-label>
      </v-card>
    </div>

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-2 mt-3 mb-5" style="width: 93%; height: 100%; padding-bottom: 20px;">
        <v-row class="first_row ma-0 pa-0 ">
          <v-col class="secondC textCenter" cols="12" md="8">
            <div class="image">
              <v-img
                  :src="require('@/assets/ICONS/shop.png')"
                  class="rounded-circle"
              ></v-img>
            </div>
            <v-card class="dataCard" flat style=" padding: 20px; width: 30vw">
              <v-row>
                <v-col cols="12">
                  <v-row><h4><span>Outlet Name </span><span>  : </span><span style="color: red">{{ outletName }}</span>
                  </h4></v-row>
                  <v-row><h5><span>Mobile </span><span style="margin-left: 39px"> : </span><span>{{
                      mobileNumber
                    }}</span></h5></v-row>
                  <v-row>
                    <v-chip class="mt-3 pa-3" color="green" size="small" style="width: auto" text-color="white"
                            variant="outlined">
                      <v-icon color="green">mdi-check-circle-outline</v-icon>
                      OUTLET STATUS - <span></span><span
                        style="font-size: 14px; margin-left: 4px">{{ outletStates }}</span></v-chip>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

          </v-col>

          <v-col class="pdfC" cols="12" md="4">
            <div class="action">
              <div class="action">
                <v-btn class="editB bg-red-lighten-4 ml-5" color="red" icon rounded size="small" variant="outlined"
                       width="200" @click="createRoute" :disabled="isCreateButtonDisabled"> UPDATE OUTLET DATA
                </v-btn>
                <!--                <v-btn class="bg-grey-lighten-2 ml-3" color="black" icon  rounded size="small" variant="outlined" width="150" @click="backPageRoute()">CANCEL-->
                <!--                </v-btn>-->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <!--        <v-card class="mt-3" flat style="width: 100%; padding-right: 20px; padding-bottom: 40%; height: fit-content; overflow-y: auto">-->
        <v-card class="mt-3 responsive-card" flat style="width: 100%; overflow-y: auto; padding-right: 20px;">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="pt-2 card-hight-fixed" flat>
                <v-row class="textfield">
                  <v-text-field
                      v-model="outletName"
                      label="Outlet Name"
                      variant="outlined"
                      :rules="[v => !!v || 'Outlet Name is required']"
                  ></v-text-field>
                  <v-text-field
                      v-model="ownerName"
                      label="Owner Name"
                      variant="outlined"
                      :rules="[v => !!v || 'Owner Name is required']"
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-select
                          v-model="outletStates"
                          :items="outletStatess"
                          item-title="outlet_status"
                          item-value="outlet_status"
                          label="Outlet Status"
                          variant="outlined"
                          :rules="[v => !!v || 'Outlet Status is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn class="bg-black" @click="reasoncard = !reasoncard">Reason</v-btn>
                    </v-col>
                    <v-textarea
                        v-if="reasoncard" v-model="reason"
                        label="Reason"
                        style="margin-bottom: 5px; margin-left: 15px"
                        variant="outlined"
                        :rules="[v => !!v || 'Reason is required']"
                    ></v-textarea>
                  </v-row>
                  <v-text-field
                      v-model="mobileNumber"
                      label="Mobile Number"
                      variant="outlined"
                      :rules="[v => !!v || 'Mobile Number is required']"
                  ></v-text-field>
<!--                  <v-select-->
<!--                      v-model="outletType"-->
<!--                      :items="outletTypes"-->
<!--                      item-title="outlet_name"-->
<!--                      item-value="id"-->
<!--                      label="Select Outlet"-->
<!--                      variant="outlined"-->
<!--                      @click="fetchOutletType"-->
<!--                      :rules="[v => !!v || 'Select Outlet is required']"-->
<!--                  ></v-select>-->
                  <v-text-field
                      v-model="outletType"
                      label="Outlet Type"
                      variant="outlined"
                  ></v-text-field>
                  <v-select
                      v-model="province"
                      :items="provinces"
                      :loading="mDistrictLoading"
                      label="Province"
                      placeholder="Select province"
                      variant="outlined"
                      @update:modelValue="getAllDistrictAccProv"
                      :rules="[v => !!v || 'Province is required']"
                  ></v-select>

                  <v-select
                      v-model="district"
                      :items="districts"
                      :loading="mDistrictLoading"
                      label="District"
                      placeholder="Select district"
                      variant="outlined"
                      @update:modelValue="getAllDsd"
                      :rules="[v => !!v || 'District is required']"
                  ></v-select>

                  <v-select
                      v-model="dsd"
                      :items="dsdList"
                      :loading="mDSDLoading"
                      label="DSD"
                      placeholder="Select DSD"
                      variant="outlined"
                      @update:modelValue="getAllGnd"
                      :rules="[v => !!v || 'DSD is required']"
                  ></v-select>

                  <v-select
                      v-model="gnd"
                      :items="gndList"
                      label="GND"
                      placeholder="Select GND"
                      variant="outlined"
                      :rules="[v => !!v || 'GND is required']"
                  ></v-select>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card class="pt-2 card-hight-fixed " flat style="padding-right: 20px; padding-left: 5px;">
                <v-row class="textfield2">
                  <!--                  <v-text-field-->
                  <!--                      v-model="outletAddress"-->
                  <!--                      label="Outlet Address"-->
                  <!--                      variant="outlined"-->
                  <!--                      :rules="[v => !!v || 'Outlet Address is required']"-->
                  <!--                  ></v-text-field>-->
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                          v-model="outlet.street_address"
                          label="Street Address"
                          variant="outlined"
                          :rules="[v => !!v || 'Street Address is required']"
                          @input="checkOutletField('street_address')"
                      ></v-text-field>
                      <v-alert
                          v-if="errors.street_address"
                          type="error"
                          dense
                          border="left"
                          colored-border
                          elevation="2"
                          class="small-alert"
                      >
                        {{ errors.street_address }}
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="latitude"
                          label="Latitude"
                          variant="outlined"
                          :rules="[v => !!v || 'Latitude is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="longitude"
                          label="Longitude"
                          variant="outlined"
                          :rules="[v => !!v || 'Longitude is required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
                <v-divider></v-divider>
                <v-card-title>
                  <h4>OUTLET LIST</h4>
                </v-card-title>
                <v-card-subtitle>
                  In here, you can set the outlet location by searching the location or dragging and placing it.
                </v-card-subtitle>
                <v-card class="mt-3" height="100%" width="100%">
                  <Map ref="map" :center="mapCenter" :zoom="13" class="d-flex map" style="width: 100%; height: 60vh"
                       @click="mapClicked">
                    <Marker
                        v-if="markerPosition"
                        :icon="customIcon"
                        :position="markerPosition"
                    />
                  </Map>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>
    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        location="top-right"
        outlined
        :timeout="3000"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    

  </v-card>
</template>

<script>


import {Map, Marker} from '@fawmi/vue-google-maps';
import axios from "axios";
import {mapGetters} from "vuex";
// import Swal from "sweetalert2";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "RoutePlan",
  components: {Marker, Map},
  data() {
    return {
      outlet: {
        street_address: '',
        // Add other fields if necessary
      },
      errors: {
        street_address: '',
      },
      snackbarMessage: '',
      snackbarColor: 'success',
      centerLoading: false,
      snackbar: false,
      alignment: 1,
      formatting: [],
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      reminderDialog: false,
      reasoncard: false,
      mapCenter: {},
      outletTypeId: '',
      reason: '',
      fetchData: '',
      outletStates: '',
      ownerName: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      outletType: '',
      latitude: '',
      longitude: '',
      statesDefault: '',
      outletTypes: [],
      street_address: '',
      isInitialstreet_address: null,
      // outletStatess: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      outletStatess: ['ALLOWED', 'NOT ALLOWED'],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,

      outletProducts: [],

      markerPosition: null,
      customIcon: {
        url: require('@/assets/ICONS/maker.png'), // Path to your custom marker icon
        scaledSize: {width: 40, height: 40} // Adjust the size if needed
      }

    };
  },

  methods: {

    async checkOutletField(field) {
      const value = this.outlet[field];
      if (!value) {
        this.errors[field] = ''; // Clear error if the field is empty
        return;
      }

      if (this.isInitialstreet_address === null) {
        this.isInitialstreet_address = this.outlet.street_address;
      }
      console.log("First value is ", this.isInitialstreet_address + " Accual value is ", this.outlet.street_address)
      if (this.isInitialstreet_address !== this.outlet.street_address) {


        try {
          const token = localStorage.getItem('token');
          const response = await axios.post(
              `${process.env.VUE_APP_API_BASE_URL}/api/checkOutletAvailable`,
              {[field]: value},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
          );

          console.log("API Response:", response.data);

          // Update errors based on the API response
          if (response.data.conflicts) {
            if (response.data.conflicts.street_address) {
              this.errors.street_address = response.data.conflicts.street_address;
            }
          } else {
            this.errors[field] = '';
          }
        } catch (error) {
          console.error(`Error checking ${field}:`, error);
          this.errors[field] = 'An error occurred while checking the field.';
        }
      }
    },

    cancell() {
      //  functionality to edit item details
      EventBus.emit('outletManagementButtonClick2');
    },

    backPageRoute() {
      this.$router.back();
    },

    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitude = this.markerPosition.lat;
      this.longitude = this.markerPosition.lng;
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;

    },
    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataOutlet;

      console.log('Shared Data:', this.sharedDataOutlet);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data);
            if (response.data) {

              this.outletProducts = Array.isArray(response.data) ? response.data : [response.data];
              console.log("this.outletProducts:", this.outletProducts);

              if (this.outletProducts.length > 0) {
                const route = this.outletProducts[0];
                this.ownerName = route.owner_name;
                this.latitude = route?.latitude;
                this.longitude = route?.longitude;
                this.province = route?.province;
                this.district = route?.district;
                this.dsd = route?.dsd;
                this.gnd = route?.gnd;
                this.outletStates = route?.outlet_status;
                this.statesDefault = route?.outlet_status;
                this.outletName = route?.outlet_name;
                this.outletType = route?.outlet_type.outlet_name;
                this.outletAddress = route?.outlet_address;
                this.outlet.street_address = route?.street_address;
                this.mobileNumber = route.mobile_number;
                this.outletTypeId = route?.outlet_type.id;
                console.log("sahan", this.mobileNumber);

                this.markerPosition = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};
                this.mapCenter = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};

              }
            } else {
              this.outletProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.outletProducts = [];
          });
    },
    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    // fetchOutletStates() {
    //   const token = localStorage.getItem('token');
    //   const userId = localStorage.getItem('userId');
    //
    //   axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/status/view`, {userId}, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //       .then(response => {
    //         this.outletStatess = response.data;
    //       })
    //       .catch(error => {
    //         console.error('Error fetching routes:', error);
    //       });
    //
    // },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async createRoute() {
      // Validate required fields

      console.log("fk", this.outletName);
      console.log("fk", this.street_address,);
      console.log("fk", this.mobileNumber,);
      console.log("fk", this.outletType);
      if (!this.outletName || !this.outlet.street_address || !this.mobileNumber || !this.outletType) {
        this.snackbarMessage = 'Please fill in all required fields.';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return;
      }

      this.centerLoading = true; // Show loading spinner

      try {
        const id = this.sharedDataOutlet;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        // Prepare the outlet data
        const outletData = {
          outlet_name: this.outletName,
          // outlet_address: this.outletAddress,
          street_address: this.outlet.street_address,
          latitude: this.latitude,
          longitude: this.longitude,
          mobile_number: this.mobileNumber,
          outlet_type_id: this.outletTypeId,
          outlet_type: this.outletType,
          province: this.province,
          district: this.district,
          dsd: this.dsd,
          gnd: this.gnd,
          owner_name: this.ownerName,
          outlet_status: this.outletStates,
          userId: userId,
          id: id,
        };

        console.log('Outlet Data:', outletData);

        // Save outlet data
        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/api/outlet/save`,
            outletData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
        );

        if (response.status === 200) {
          // If outlet status is updated, send a status update request
          if (this.outletStates !== this.statesDefault) {
            const updateData = {
              reason: this.reason,
              status: this.outletStates,
              userId: userId,
            };

            const updateResponse = await axios.post(
                `${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`,
                updateData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                }
            );

            console.log('Status Update Response:', updateResponse);
          }

          // Show success message
          this.snackbar = true;
          this.snackbarColor = 'success';
          this.snackbarMessage = 'Outlet updated successfully!';
          this.snackbarColor = 'success';
          this.snackbar = true;

          this.editDialog = false;
          // Clear form fields
          // this.resetForm();
          setTimeout(() => {
            EventBus.emit('outletManagementButtonClick2');
            window.location.reload();
          }, 2000);
          window.location.reload();
        } else {
          // Handle unexpected response status
          this.snackbarMessage = 'Error updating outlet. Please try again.';
          this.snackbarColor = 'error';
          this.snackbar = true;
        }
      } catch (error) {
        console.error('Error in createRoute:', error);

        // Handle specific error cases
        if (error.response) {
          switch (error.response.status) {
            case 409:
              this.snackbarMessage = 'Outlet address already exists!';
              break;
            case 400:
              this.snackbarMessage = 'Invalid data. Please check your inputs.';
              break;
            case 401:
              this.snackbarMessage = 'Unauthorized. Please log in again.';
              break;
            default:
              this.snackbarMessage = error.response.data?.message || 'Something went wrong!';
          }
        } else {
          this.snackbarMessage = 'Network error. Please check your connection.';
        }

        this.snackbarColor = 'error';
        this.snackbar = true;
      } finally {
        this.centerLoading = false; // Hide loading spinner
      }
    },

// Helper function to reset form fields
    resetForm() {
      this.outletName = '';
      this.outletAddress = '';
      this.street_address = '';
      this.latitude = '';
      this.longitude = '';
      this.mobileNumber = '';
      this.outletType = null;
      this.province = '';
      this.district = '';
      this.dsd = '';
      this.gnd = '';
      this.ownerName = '';
      this.outletStates = this.statesDefault;
      this.reason = '';
    },
  },

  computed: {
    isCreateButtonDisabled() {
      // Disable the button if any error exists
      return (
          this.errors.street_address.length
      );
    },
    ...mapGetters(['sharedDataOutlet']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    }
  },
  created() {
    this.fetchOutletRecodeData();
    if (this.isInitialstreet_address === null) {
      this.isInitialstreet_address = this.outlet.street_address;
    }

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchOutletRecodeData();
    this.getAllProvinces();
  },
  watch: {
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    }
  }

};
</script>


<style scoped>

.FR {
  width: 100vw;
}

.fc {
  width: 100vw;
  margin-top: 4px;
  margin-bottom: 10px;
}

.closeC {
  justify-content: right;
  margin-top: 0;
  margin-left: 88vw;

}

.card-hight-fixed {
  height: 100%;
  overflow: auto;
  max-height: 65%;
}

.pdfC {
  display: flex;
  justify-content: right;
  width: 90vw;
  margin-top: 40px;
}

.editB {
  display: flex;
  margin-right: 10px;
}

.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}

.fill-height {
  height: 100vh; /* Full viewport height */
  padding: 20px;
  display: flex;
}

.textCenter {
  text-align: center;
}


.textfield {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 20px;
  margin-top: 20px;
}

.textfield2 {
  display: flex;
  width: 40vw;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row {
  display: flex;
  justify-content: flex-end;

}

.secondC {
  display: flex;

}


.tableCard {
  height: 31vh;
}

.red--text {
  color: red;
}

/* Default height for screens with height <= 768px */
.responsive-card {
  height: 400px;
}

/* Remove fixed height for screens taller than 768px */
@media (min-height: 769px) {
  .responsive-card {
    height: auto;
  }
}
</style>

<template>
  <v-app id="app_authentication">
    <v-navigation-drawer class="custom_navigation_drawer" width="30%" :image="require('@/img/background_images/image_2.png')"   v-model="drawer" app></v-navigation-drawer>
    <v-main class="custom_border_with_small_devices custom_class_alignment">
      <v-container color="white"  fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="2"></v-col>
          <v-col cols="12" sm="12" md="8">
            <v-card flat style="background: transparent !important;">
              <!-- Title Section -->
              <template v-slot:title>
                <v-card-title class="custom_topic_sign_in text-wrap" style="white-space: normal;">
                  Change Password
                </v-card-title>
              </template>

              <!-- Logo Section -->
              <template v-slot:append>
                <div class="d-flex align-center">
                  <!-- <img style="width: 80px; height: 40px; margin-right: 10px;" :src="require('@/img/company_logo/g-sfa.png')" />
                  <v-divider :thickness="3" vertical class="mb-0 mt-0 mr-3"></v-divider> -->
                  <img style="width: 220px; height: 150px;" :src="require('@/WEB/LOGOS/logo1.png')" />
                </div>
              </template>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <v-card-subtitle class="mt-2">
                <div>
                  <p class="custom_paragraph_option_2">
                    <v-icon size="small">mdi-lock-open-outline</v-icon>
                    Please provide your new password details according to the system rules.
                  </p>
                </div>
              </v-card-subtitle>

              <v-card-text  class="mt-5">
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="password"
                          :rules="passwordRules"
                          color="#546E7A" clearable
                          label="New Password"
                          outlined
                          placeholder="New Password"
                          prepend-inner-icon="mdi-lock-outline"
                          required
                          :type="e1 ? 'text' : 'password'"
                          variant="outlined"
                          :append-inner-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append-inner="e1 = !e1"
                      ></v-text-field>

                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                          v-model="confirmPassword"
                          :rules="confirmPasswordRules"
                          label="Re-enter new password"
                          outlined
                          placeholder="Re-Enter New Password"
                          prepend-inner-icon="mdi-lock-outline"
                          required color="#546E7A" clearable
                          :type="e2 ? 'text' : 'password'"
                          variant="outlined"
                          :append-inner-icon="e2 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append-inner="e2 = !e2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card color="white" elevation="2" light class="mt-1" v-if="password">
                    <v-card-text>
                      <div class="mb-4 custom_paragraph_option_2">Your password must contain:</div>
                      <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="password.length < 8" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="password.length >= 8" size="small">mdi-check</v-icon> At least 8 characters</p>
                      <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="!(/^(?=.*[a-z]).+$/.test(password))" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="/^(?=.*[a-z]).+$/.test(password)" size="small">mdi-check</v-icon> Lower case letters (a-z)</p>
                      <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="!(/^(?=.*[A-Z]).+$/.test(password))" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="/^(?=.*[A-Z]).+$/.test(password)" size="small">mdi-check</v-icon> Upper case letters (A-Z)</p>
                      <p class="mt-2 custom_paragraph_option_2"> <v-icon color="red" v-if="!(/^(?=.*[0-9]).+$/.test(password))" size="small">mdi-alert-circle-outline</v-icon> <v-icon color="green" v-if="/^(?=.*[0-9]).+$/.test(password)" size="small">mdi-check</v-icon> Numbers (0-9)</p>
                    </v-card-text>
                  </v-card>
                </v-form>

                <v-row align="center" justify="center">
<!--                  <v-col cols="12" sm="12" md="12">-->
<!--                    <v-alert  v-model="snackAlert.snackbar" text-->
<!--                              class="mt-3"-->
<!--                              :color="snackAlert.snackColor"-->
<!--                              :right="snackAlert.snackBarRight"-->
<!--                              closable-->
<!--                              timeout="3000"-->
<!--                              variant="tonal"-->
<!--                              theme="dark"-->
<!--                              border>-->
<!--                      {{ snackAlert.snackMessage }}-->
<!--                    </v-alert>-->
<!--                  </v-col>-->

                  <v-col cols="12" sm="12" md="6">
                    <v-btn   @click="forgotPasswordVerifyModule" size="x-large" block class="custom_paragraph_option_btn text-white">
                      <v-icon left>mdi mdi-account-arrow-right-outline</v-icon>
                      &nbsp;&nbsp;Reset Password
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-btn  @click="backToLogIn"  size="x-large"  block class="custom_paragraph_option_btn_2">
                      <v-icon left >mdi mdi-arrow-left</v-icon>
                      &nbsp;Back To Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider class="mt-2 mb-6 mr-4 ml-4"></v-divider>

              <v-card-subtitle class="mt-2">
                <div>
                  <p class="custom_paragraph_option_2 text-center">
                    POWERED BY CELATATECH
                  </p>
                </div>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="2"></v-col>
        </v-row>
      </v-container>


      <v-dialog v-model="dialog_components" max-width="400px" >
        <v-card id="app_authentication" rounded="md">
          <div class="pa-2">
            <v-card color="white" flat class="mt-3">
              <v-card-item>
                <v-card-title class="text-center text-h6 font-weight-regular">
                  <v-icon color="success" size="small">mdi-check-circle</v-icon>
                  &nbsp;&nbsp; Password reset successfully !
                </v-card-title>
                <v-divider class="mt-4"></v-divider>
                <v-btn size="x-large" width="100%" class="mb-2 mt-4 custom_paragraph_option_btn" @click="backToLogIn">Back to Login</v-btn>
              </v-card-item>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :right="snackAlert.snackBarRight"
          :top="snackAlert.snackBarTop"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>

import axios from "axios";

export default {
  name: 'ResetPassword',

  mounted() {

      const params = new URLSearchParams(window.location.search);
      this.email = params.get('email');
  },

  data() {
    return {
      valid: false,
      email:"",
      dialog_components: false,
      centerLoading:false,
      e1: false,
      e2:false,
      password: '',
      confirmPassword: '',
      passwordRules: [
        value => !!value || 'Please enter your new password',
        value => value.length >= 6 || 'Password must be at least 6 characters',
      ],
      confirmPasswordRules: [
        value => !!value || 'Please confirm your password',
        value => value === this.password || 'Passwords do not match',
      ],
      drawer: null,
      snackAlert: {
        snackbar: false,
        snackColor: "",
        snackMessage: "",
        snackBarRight: false,
        snackBarTop: true,
      },
    };
  },
  methods: {
    backToLogIn() {
      this.$router.push('/');
    },

    forgotPasswordVerifyModule() {
      this.resetPassword();
    },

    showSnackbar(message, color) {
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
      this.snackAlert.snackbar = true;
    },

    validateForm() {
      // Validate form fields
      if (!this.password || this.password.length < 8) {
        this.showSnackbar("Password must be at least 8 characters long.", "error");
        return false;
      }
      if (!/[A-Za-z]/.test(this.password)) {
        this.showSnackbar("Password must contain at least one letter.", "error");
        return false;
      }
      if (!/\d/.test(this.password)) {
        this.showSnackbar("Password must contain at least one number.", "error");
        return false;
      }
      if (this.password !== this.confirmPassword) {
        this.showSnackbar("Passwords do not match.", "error");
        return false;
      }
      

      return true;
    },

    async resetPassword() {
      try {
        

        if (!this.validateForm()) {
          // console.log("Form validation failed. Fix the errors and try again.");
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Form validation failed. Fix the errors and try again.';
          return;
        }

        // Check if password and confirm password match
      if (this.password !== this.confirmPassword) {
       
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Password and Confirm Password do not match.';
        
        this.centerLoading = false;
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get("email");

      this.centerLoading = true;
        // Make the API call to reset the password
        const response = await axios.post('/api/passwords/reset', {
          email: email,
          password: this.password,
          password_confirmation: this.confirmPassword,
        });

        // Check if the request was successful
        if (response.status === 200) {
          console.log("Password reset successful:", response);
          this.centerLoading = false;
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'success';
          this.snackAlert.snackMessage = 'Password reset successfully';
          this.dialog_components=true;
        } else {
          console.error("Error resetting password:", response.statusText);
          this.centerLoading = false;
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Password reset Failed';
          this.dialog_components=true;
        }
      } catch (error) {
        // Catch and log any errors
        console.error("Error:", error);
        this.centerLoading = false;
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Password reset Failed';
        this.dialog_components=false;
      }
    },
  },
};
</script>

<style>
@import "../../styles/auth/authentication.scss";
</style>

<template>
  <v-app id="app_live_tracking">
    <v-container fluid>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <p class="custom_page_main_title">INACTIVE OFFICERS LIST</p>
          <p class="custom_page_main_sub_title" style="opacity: 0.6">Analyse inactive officers data.</p>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="custom_tool_bar_style">
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="back()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="custom_card_box mt-8" elevation="1" border="sm opacity-1" rounded="lg">
        <template v-slot:item>
          <v-text-field v-model="search" density="comfortable" class="custom_input_field_color w-50 mt-5 ml-2"
                        label="Search" persistent-placeholder placeholder="Search" variant="outlined"></v-text-field>
        </template>

        <template v-slot:append>
          <v-divider vertical thickness="2"></v-divider>
          <v-card-text class="custom_tool_bar_style">
            <v-btn class=" custom_pdf_btn" variant="outlined" @click="exportPDF">
              <v-icon size="large">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>
            <v-btn class="custom_excel_btn ml-4" variant="outlined" @click="exportExcel">
              <v-icon size="large">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </v-card-text>
        </template>

        <v-divider thickness="2"></v-divider>

        <v-card-text>
          <v-data-table :headers="headers" :items="filteredItems" :search="search" class="custom_table_normal">
            <!-- <template v-slot:[`item.action`]="{ item }" v-if="permissions.write">
              <v-switch v-model="item.active_status" :false-value="0" :true-value="1" hide-details color="#BB0000"
                        label="Enable" @change="toggleEnable(item)"></v-switch>
              <v-divider class="mb-2"></v-divider>
              <p class="text-caption font-weight-medium mb-3">Enable | Disable Tracking Permission</p>
            </template> -->

            <template v-slot:[`item.name`]="{ item }">
              <p class="text-caption custom_page_main_sub_title font-weight-bold">{{ item.name }}</p>
              <p class="custom_page_main_sub_title_very_small_red text-caption font-weight-medium mb-3">
                {{ item.registration_num }}</p>
            </template>


            <template v-slot:[`item.states`]="{ item }">
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-chip v-if="item.active_status === 0 || item.active_status === null " class="custom_green_chip" size="small" variant="outlined">
                    Available
                    <v-icon class="justify-end ml-9" icon="mdi-check"></v-icon>
                  </v-chip>

                  <v-chip v-if="item.active_status === 1" class="custom_red_chip" size="small" variant="outlined"
                  >
                    Not Available
                    <v-icon class="justify-end ml-3" icon="mdi-close"></v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>

    </v-container>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import axios from "axios";
// import {getPermissions} from "@/plugins/permissions";
import _ from "lodash"

export default {
  data() {
    return {
      search: '',
      permissions: {},
      headers: [
        {text: 'OFFICER NAME', value: 'name', title: 'OFFICER NAME', sortable: true},
        {text: 'CURRENT ADDRESS', value: 'address', title: 'CURRENT ADDRESS', sortable: true},
        {text: 'DISTRICT', value: 'district', title: 'DISTRICT', sortable: true},
        {text: 'DSD', value: 'dsd', title: 'DSD', sortable: true},
        {text: 'STATUS', value: 'states', title: 'STATUS', sortable: true},
        // {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      inActiveAgents: [],
    }
  },

  computed: {
    ...mapGetters({
      agentListDetails: 'getagentListDetails'
    }),


    filteredItems() {
        const searchLower = this.search ? this.search.toLowerCase() : '';

        return this.inActiveAgents.filter(item => {
          if (!this.search) return true; // If no search query, return all items.

          return Object.values(item).some(value =>
            value && value.toString().toLowerCase().includes(searchLower)
          );
        });
      }


  },

  mounted() {
    this.getAllAgents();
    // const dashboardId = 7;
    // this.permissions = getPermissions(dashboardId);
    // console.log("Permissions:", this.permissions);
    this.getDashboardPermissions();
  },

  methods: {

    back() {
      this.$router.push('/live-tracking');
    },

    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: {read: false, write: false, delete: false},
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },

    toggleEnable(item) {
      console.log('item', item);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('agentId', item.id);
      formData.append('activeStatus', item.active_status);

      axios.post('/api/updateTracking/activeStatus', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

          .then(response => {
            if (response.status === 200) {
              this.getAllAgents();
            } else {
              console.error("Error:", response.data.error);
            }
          })
          .catch(error => {
            console.error("Error fetching chart details:", error);
          });
    },

    getAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('userId', userId);

      axios.post('/api/getAgentList', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            if (response.status === 200) {

              const agentList = response.data.data;
              console.log('fk', agentList);
              this.inActiveAgents = _.filter(agentList, (agent) => agent.active_status === 0 || agent.active_status === null);
              console.log('agents', this.agentListDetails)

            } else {
              console.error("Error:", response.data.error);
            }
          })
          .catch(error => {
            console.error("Error fetching chart details:", error);
          });
    },

    exportPDF() {
          const doc = new jsPDF({
              orientation: 'landscape', // Landscape mode for better fitting
              unit: 'mm',
              format: 'a4'
          });

          // Set title
          doc.setFontSize(16);
          doc.text('Officer Report', 14, 15);

          // Subtitle
          doc.setFontSize(10);
          doc.text('Officers report with their details', 14, 22);

          // Define table columns
          const tableColumns = [
              'Officer Name', 'Reg Num', 'Type', 'Email', 'NIC', 'Gender',
              'Address', 'Province', 'District', 'DSD', 'GND'
          ];

          // Prepare data
          const tableData = this.filteredItems.map((item) => [
              item.name,
              item.registration_num,
              item.user_type,
              item.email,
              item.nic,
              item.gender,
              item.address,
              item.province,
              item.district,
              item.dsd,
              item.gnd
          ]);

          doc.autoTable({
              head: [tableColumns],
              body: tableData,
              theme: 'grid',
              startY: 30, // Ensure table starts below the title
              styles: {
                  fontSize: 8, // Increased for better readability
                  cellPadding: 2,
                  halign: 'center',
                  valign: 'middle',
              },
              headStyles: {
                  fillColor: [40, 40, 200],
                  textColor: [255, 255, 255],
                  fontSize: 9,
              },
              bodyStyles: {
                  textColor: [50, 50, 50],
              },
              alternateRowStyles: {
                  fillColor: [240, 240, 240],
              },
              columnStyles: {
                  0: { cellWidth: 25 }, // Officer Name
                  1: { cellWidth: 20 }, // Reg Num
                  2: { cellWidth: 15 }, // Type
                  3: { cellWidth: 40 }, // Email
                  4: { cellWidth: 25 }, // NIC
                  5: { cellWidth: 15 }, // Gender
                  6: { cellWidth: 40 }, // Address
                  7: { cellWidth: 20 }, // Province
                  8: { cellWidth: 20 }, // District
                  9: { cellWidth: 20 }, // DSD
                  10: { cellWidth: 20 }, // GND
              },
              margin: { top: 10 },
          });

          // Footer with date
          doc.setFontSize(10);
          doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

          // Save the PDF
          doc.save('Officer_Report.pdf');

          const fileName = 'Officer_Report.pdf';
          const file_type = 'pdf';
          this.logDocumentDownload(fileName, file_type);
      },

    exportExcel() {
      // Prepare the data for Excel export
      const tableData = this.filteredItems.map((item) => ({
        'OFFICER NAME': item.name,
        'REG NUM': item.registration_num,
        'TYPE': item.user_type,
        'EMAIL': item.email,
        'NIC': item.nic,
        'GENDER': item.gender,
        'ADDRESS': item.address,
        'PROVINCE': item.province,
        'DISTRICT': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
      }));

      // Create worksheet from JSON data
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set custom column widths
      worksheet['!cols'] = [
        {wch: 20},
        {wch: 15},
        {wch: 10},
        {wch: 25},
        {wch: 15},
        {wch: 10},
        {wch: 30},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 20},
      ];

      // Freeze the header row
      worksheet['!freeze'] = {ySplit: 1};

      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: {bold: true},
          alignment: {horizontal: 'center'},
        };
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All Inactive Officers Reports');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'All_Inactive_Report.xlsx');
    },

    async logDocumentDownload(fileName,file_type) {
        console.log(fileName);
        try {
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');

          if (!userId) {
            throw new Error('User ID not found');
          }

          // Asynchronous API call to log download
          const response = await axios.post(`/api/log-document-download`, {
            user_id: userId,
            file_type: file_type,
            file_name: fileName,
            entity_area: 'Tracking Management',

            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.status !== 200) {
            throw new Error('Failed to log download');
          }

          console.log('Download logged successfully');
        } catch (error) {
          console.error('Error logging download:', error);
        }
      },
  },
};
</script>

<template>
  <v-card style="height: 100%; width: 100%">
    <v-card class="pa-3">
      <v-icon class="ml-8" >mdi-view-dashboard
      </v-icon>
      <v-label class="ml-10">UPDATE ROUTE PLAN DETAILS</v-label>
    </v-card>

    <v-row class="first_row ma-0 pa-0 ">
      <v-col class="secondC textCenter" cols="12" md="8">
        <div class="image">
          <v-img
              :src="userImage"
              class="rounded-circle"
          ></v-img>
        </div>
        <v-card class="dataCard" flat style=" padding: 20px; width: 30vw"  >
          <v-row>
            <v-col cols="12">
              <v-row><h4><span >Outlet Name </span><span>  : </span><span style="color: red">{{ agentName }}</span></h4></v-row>
              <v-row><h5><span >Mobile </span><span style="margin-left: 39px"> : </span><span>{{ mobileNumber }}</span></h5></v-row>
              <v-row>
                <v-chip class="mt-3 pa-3" color="green" size="small" style="width: 200px" text-color="white" variant="outlined" >
                  <v-icon  color="green"  >mdi-check-circle-outline</v-icon>
                  OUTLET STATUS  - <span></span><span style="font-size: 14px; margin-left: 4px">{{routeStates}}</span> </v-chip>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

      </v-col>

      <v-col class="pdfC" cols="12" md="4">
        <div class="action">
          <div class="action">
            <v-btn class="editB bg-red-lighten-4 ml-5" color="red" icon rounded size="small" variant="outlined" width="200" @click="createRoute"> UPDATE ROUTE DATA
            </v-btn>
            <v-btn class="bg-grey-lighten-2 ml-3" color="grey" icon  rounded size="small" variant="outlined" width="150" @click="cancell">CANCEL
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row class="second_row ">
      <v-col cols="12" md="6">
        <v-row class="textfield">
          <v-text-field
              v-model="routeName"
              label="Route Name"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-select
              v-model="routeCategory"
              :items="routeCategories"
              class="text-field-margin text-black"
              item-title="category_name"
              label="Route Category"
              persistent-placeholder
              placeholder="Select category"
              variant="outlined"
          ></v-select>
          <v-text-field
              v-model="province"
              label="Province"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="district"
              label="District"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="dsd"
              label="DSD"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="gnd"
              label="GND"
              readonly
              variant="outlined"
          ></v-text-field>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-row class="textfield2">
          <v-date-input
              v-model="assignDate"
              :disabled="canEditSelectField"
              color="red"
              label="Assign Date"
              max-width="590"
              persistent-placeholder
              variant="outlined"
          ></v-date-input>
          <v-text-field
              v-model="visitedDate"
              label="Visited Date"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-card>

            <v-card v-if="showFirstCard">
              <v-row>
                <v-col cols="12" md="10">
                  <v-card-title>
                    <h4>OUTLET LIST</h4>
                    <v-card-subtitle>
                      <h6>The following table shows all the outlets related to the above-mentioned route </h6>
                    </v-card-subtitle>
                  </v-card-title>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn class="bg-red-lighten-4 mt-3" @click="confirmDelete()">
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-card class="tableCard" style=" overflow-y: auto;">
                <v-col cols="12" style="height: 100%;">
                  <v-data-table
                      :headers="headers"
                      :items="outletData"
                      style="height: 100%; overflow-y: auto; font-size: 10px"

                  >
                    <template v-slot:[`item.route_category.category_name`]="{ item }">
                      <div style="color: red">{{ item.route_category.category_name }}</div>
                    </template>

                    <template v-slot:[`item.outletName`]="{ item }">
                      <div>
                        <div>{{ item.outletName }}</div>
                        <div style="color: red">Outlet num: {{ item.id }}</div>
                      </div>
                    </template>

                    <template v-slot:[`item.visited_date`]="{ item }">
                      <v-btn v-if="item.status === 'Not Visited'" color="red" height="20px" icon rounded="lg"
                             style="font-size: x-small" width="80px" @click="reminderBox(item)">REMINDER
                      </v-btn>
                      <v-card-text v-if="item.states=== 'Visited'">{{ item.visited_date }}</v-card-text>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <div>
                        <div>
                          <v-chip v-if="item.status === 'Visited'" color="green"
                                  style="height: 20px; width:100px; font-size: x-small" variant="outlined">
                            VISITED
                            <v-icon class="justify-end ml-7">mdi-check</v-icon>
                          </v-chip>

                          <v-chip v-if="item.status === 'Not Visited'" color="red"
                                  style="height: 20px; width:100px; font-size: x-small" variant="outlined">
                            NOT VISITED
                            <v-icon class="justify-end ml-3">mdi-close</v-icon>
                          </v-chip>
                        </div>
                      </div>
                    </template>

                  </v-data-table>
                </v-col>
              </v-card>
            </v-card>
            <v-card v-else height="40vh">
              <v-row>
                <v-col cols="12" md="10">
                  <v-card-title>
                    <h4>OUTLET LIST</h4>
                    <v-card-subtitle>
                      <h6>The following table shows all the outlets related to the above-mentioned route </h6>
                    </v-card-subtitle>
                  </v-card-title>
                </v-col>
              </v-row>

              <v-card>
                <v-row style="width:auto; height: auto;  ">
                  <v-card style="width: 100%; height: 100%; padding-left: 20px">
                    <!-- search -->
                    <v-row style="width: auto; height: 100%; ">
                      <v-col cols="12" md="3">
                        <v-row>
                          <v-switch style="margin-left: 20px; margin-top: 20px"></v-switch>
                          <v-col style=" justify-content: center">
                            <v-card-text style="color: black;margin-top: 10px">ALL</v-card-text>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="9">
                        <v-text-field
                            class="text-black"
                            label="Search"
                            persistent-placeholder
                            placeholder="Search outlet"
                            style="margin-top: 18px;"
                            variant="outlined"
                            width="25vw"
                        ></v-text-field>

                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <!-- table -->
                    <v-row>
                      <v-card style="width: 40vw; height: 30vh; padding: 10px;">
                        <v-data-table
                            :headers="headers"
                            :items="routes"
                            class="DataTable"
                            style="font-size: 10px; overflow-y: auto; max-height: 100%;"
                        >
                          <template v-slot:[`item.category`]="{ item }">
                            <div>
                              <v-row>
                                <v-switch></v-switch>
                                <v-card-text style="color: red;font-size: 10px">{{ item.category }}</v-card-text>
                              </v-row>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-row>
                  </v-card>
                </v-row>
              </v-card>
            </v-card>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row>

    </v-row>

    <!--    reminder dialog-->
    <v-dialog v-model="reminderDialog" class="solid-dialog d-flex" height="80vh" width="40vw">
      <v-card class="pa-8">
        <v-row>
          <v-col>
            <v-card-title>SEND REMINDER</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-3" rounded size="x-small" @click="reminderDialog = false">
              <v-icon style="color: black">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                v-model="routeName"
                label="Route Name"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="outletName"
                label="Outlet Name"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="outletAddress"
                label="Outlet Address"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="admin.userName"
                label="Assignee"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="admin.mobileNum"
                label="Mobile"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="admin.email"
                label="Email"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="note"
                label="Note"
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>

          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-end " cols="12" md="12" @click="reminderDialog = false">
            <v-btn class="mt-3 bg-red-lighten-3" color="red" size="large" variant="outlined" @click="reminderSend">
              SEND
            </v-btn>
            <v-btn class="mt-3 ml-3 bg-grey-darken-3" color="gray" size="large" variant="outlined"
                   @click="reminderDialog = false">
              CANCEL
            </v-btn>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>


    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-1">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE OUTLET LIST</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this outlet list?</v-card-text>
        <v-card-text class="font-weight-medium">
          This outlet list record will be deleted immediately. Once deleted, it can no longer be used for any purpose. but you can add a new outlet list under the above title.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="toggleCard">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      UPDATE ROUTE PLAN SUCCESSFULLY.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-model="snackbarReminder"
        color="success"
        location="top-right"
        outlined
        timeout="3000"
    >
      REMINDER SEND.
      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="loading" >
      <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>
// import {EventBus} from "@/plugins/event-bus";
import axios from "axios";
import {mapGetters} from "vuex";
import {EventBus} from "@/plugins/event-bus";
import Swal from "sweetalert2";

export default {
  name: "Route_Plan.vue",
  data() {
    return {
      snackbarReminder : false,
      loading :false,
      snackbar: false,
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      reminderDialog: false,
      showFirstCard: true,
      showFirstIcon: true,
      fetchData: '',
      routeStates: '',
      agentName: '',
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      assignDate: '',
      visitedDate: '',
      userImage: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      email: '',
      agentId: '',
      note: 'You should visit this place as soon as possible and report the information',
      routeCategories: [],
      headers: [
        {text: 'Category', value: 'route_category.category_name', title: 'Category'},
        {text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name'},
        {text: 'Outlet Address', value: 'address', title: 'Outlet Address'},
        {text: 'States', value: 'status', title: 'States'},
        {text: 'Visit Date', value: 'visited_date', title: 'Visit Date'},
      ],
      routeProducts: [],
      outletData: [],
      admin: {
        userName: '',
        mobileNum: '',
        email: '',
      },


    };
  },

  methods: {
    reminderBox(item) {
      this.reminderDialog = true;

      const userName = localStorage.getItem('userName');
      const email = localStorage.getItem('email');
      const mobile = localStorage.getItem('mobileNumbers');


      this.outletName = item.outlet_name;
      this.outletAddress = item.address;
      this.admin.email = email;
      this.admin.mobileNum = mobile;
      this.admin.userName = userName;

    },
    snackbarM() {
      this.snackbar = false;
      EventBus.emit('routeRecodeManagementButtonClick2');
    },
    cancell() {
      EventBus.emit('routeRecodeManagementButtonClick2');
    },
    toggleCard() {
      this.showFirstCard = !this.showFirstCard;
      this.showFirstIcon = !this.showFirstIcon;
      this.deleteDialog = false;

      const ids = this.outletData.map(outlet => outlet.id);
      console.log('ids array', ids);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/route/outlets/delete`, {ids, userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },
    toggleIcon() {
      this.showFirstIcon = true;
      this.showFirstCard = true;
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;

    },
    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },
    reminderSend() {
      this.loading = true;

      const userId = localStorage.getItem('userId');

      const routeData = {
        route_name: this.routeName,
        outlet_name: this.outletName,
        outlet_address: this.outletAddress,
        assignee: userId,
        mobile_number: this.mobileNumber,
        email: this.email,
        note: this.note,
      };

      axios.post('/api/route/reminder', {routeData, userId})
          .then(response => {
            console.log('reminder send successfully:', response.data);
            // Handle successful addition (e.g., clear form, show success message)
          })
          .catch(error => {
            console.error('Error adding reminder:', error);
            // Handle error (e.g., show error message)
          })
          .finally (() => {
            this.snackbarReminder= true;
          this.loading = false;
          });
    },

    fetchOutletRecodeData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes;

      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data.route);
            if (response.data.route) {

              this.routeProducts = Array.isArray(response.data.route) ? response.data.route : [response.data.route];
              console.log("this.outletProducts:", this.routeProducts);

              if (this.routeProducts.length > 0) {
                const route = this.routeProducts[0];
                this.agentName = route.agent.name;
                this.agentId = route.agent.id;
                this.routeName = route?.route_name;
                this.routeCategory = route.route_category.category_name;
                this.province = route?.province;
                this.district = route?.district;
                this.dsd = route?.dsd;
                this.gnd = route?.gnd;
                this.assignDate = route?.assign_date;
                this.visitedDate = route?.visited_date;
                this.routeStates = route?.route_status;
                this.userImage = route?.agent?.user_image;
                this.mobileNumber = route.agent?.mobile_number?.length > 0 ? route.agent?.mobile_number[0]?.mobile_number : null;

                this.admin.userName = route?.assigner.userName;
                this.admin.email = route?.assigner.email;
                this.admin.mobileNum = route.assigner?.mobile_number?.length > 0 ? route.assigner?.mobile_number[0]?.mobile_number : null;

                this.ids = route.

                console.log("sahan", this.mobileNumber)

              }
              console.log('main', this.agentName)

            } else {
              this.routeProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.routeProducts = [];
          });
    },
    async createRoute() {
      this.loading = true;

      const userId = localStorage.getItem('userId');
      const category = this.routeCategories.find(cat => cat.category_name === this.routeCategory);

      const id = this.sharedDataRoutes;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      const routeData = {
        route_name: this.routeName,
        route_category_id: category ? category.id : null,
        assign_date: new Date(this.assignDate).toISOString().split('T')[0],
        visited_date: new Date(this.assignDate).toISOString().split('T')[0],
        assigner_id: userId,
        agent_id: this.agentId,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        userId: userId,
        id: id,


      };
      try {
        const token = localStorage.getItem('token');
        console.log("route is ", routeData);
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/save`, routeData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        console.log(response);
        this.snackbar = true;


      } catch (error) {
        // Show error message
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'Something went wrong!',
        });
      }finally {
        this.snackbar = true;
        this.loading = false;
        setTimeout(() => {
          EventBus.emit('routeRecodeManagementButtonClick2');
          this.editDialog = false;
        }, 3000);
      }
    },

    fetchOutletData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes;

      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            // Accessing the correct data structure
            const data = response.data;
            if (data && data.route && data.route.selected_outlets) {
              this.outletData = data.route.selected_outlets;
              console.log("API Response outlet list:", this.outletData);
            } else {
              console.log("Selected outlets data not found in the response");
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routeCategories = response.data;
            console.log("API Response category list:", response.data);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });

    },

  },


  computed: {
    ...mapGetters(['sharedDataRoutes']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}${this.userImage}`;
    },
    canEditSelectField() {
      // Check if all outlet statuses are "Not Visited"
      return this.outletData.every(outlet => outlet.status === "Not Visited");
    }
  },
  created() {
    this.fetchOutletRecodeData();

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchOutletRecodeData();
    this.fetchOutletData();
    this.fetchMainCategories();
  },

};
</script>


<style scoped>



.pdfC {
  display: flex;
  margin-top: 40px;
}

.editB {
  display: flex;
  margin-right: 10px;
}

.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}


.textCenter {
  text-align: center;
}


.textfield {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}

.textfield2 {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row {
  display: flex;
  justify-content: flex-end;

}

.second_row {
  margin-right: 50px;
}

.secondC {
  display: flex;

}


.tableCard {
  height: 31vh;
}

.red--text {
  color: red;
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

</style>
